// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {listen} from "ol/events";
import EventType from "ol/events/EventType";
import {KML, GeoJSON,GPX,GML} from 'ol/format';
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";
import ImageArcGISRest from 'ol/source/ImageArcGISRest.js';

import {extend} from 'ol/extent';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from "ol/style";
import Point from "ol/geom/Point";
import LayerGroup from "ol/layer/Group";
import {unByKey} from "ol/Observable";
import ImageWMS from "ol/source/ImageWMS";
import {SelectionEvent} from "./SelectionEvent";
import Overlay from 'ol/Overlay';
import {getCenter} from 'ol/extent';
import EsriJSON from 'ol/format/EsriJSON';
import XYZ from 'ol/source/XYZ.js';


var styles = {

    "default": [
        new Style({
            stroke: new Stroke({
                color: 'green',
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(0, 255, 0, 0.1)'
            }),
            text: new Text({
                font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                placement: 'point',
                offsetX :0,
                offsetY:15,
                overflow: true,
                fill: new Fill({
                    color: 'green'
                })
            })

        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'green'
                }),
                text: new Text({
                    font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                    placement: 'point',
                    offsetX :0,
                    offsetY:15,
                    fill: new Fill({
                        color: 'blue'
                    })
                })
            })
        })]

};

export class FeatureInfoControl extends Control {
    constructor(options) {

        super({
            element: document.createElement('div'),
            target: options.target
        });

        this.parentControl = options.parentControl;
        this.options = options;
        this.isActive = false;
        this.selectableLayers = [];

        const className = options.className !== undefined ? options.className : 'feature-info-control';
        const button = document.createElement('button');
        //const label = "I";
        const tipLabel = 'Info o prvku';
        button.setAttribute('type', 'button');
        button.title = tipLabel;

        listen(button, EventType.CLICK, this.toolBtnClicked, this);
        const cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
        const element = this.element;
        element.className = cssClasses;
        element.appendChild(button);


        var className2 = 'feature-info-popup';
        var self=this;

        ///const cssClasses = className + ' ' + CLASS_UNSELECTABLE ;
        const container = document.createElement('div');
        container.className = className2;
        container.id="popup";
        const btnDiv = document.createElement('div');
        btnDiv.className ="feature-info-popup-navigation";
        const popupPrev=document.createElement('a');
        popupPrev.setAttribute("href","#");
        popupPrev.title="Predchádzajúci";
        popupPrev.className="feature-info-popup-prev";

        popupPrev.onclick = function () {
            self.prevClicked();
            popupPrev.blur();
            return false;
        }

        btnDiv.appendChild(popupPrev);

        this.featureIdLabel=document.createElement('span');
        this.featureIdLabel.className="feature-info-popup-feature-label";
        this.featureIdLabel.innerHTML="1";
        btnDiv.appendChild(this.featureIdLabel);

        const popupNext=document.createElement('a');
        popupNext.setAttribute("href","#");
        popupNext.title="Nasledujúci";
        popupNext.className="feature-info-popup-next";

        popupNext.onclick = function () {
            self.nextClicked();
            popupNext.blur();
            return false;
        }

        btnDiv.appendChild(popupNext);



        this.featureCountLabel=document.createElement('span');
        this.featureCountLabel.className="feature-info-popup-feature-label feature-count";
        this.featureCountLabel.innerHTML="Počet: " +"0";
        btnDiv.appendChild(this.featureCountLabel);

        const popupCloser=document.createElement('a');
        popupCloser.setAttribute("href","#");
        popupCloser.title="Zatvoriť";
        popupCloser.className="feature-info-popup-closer";

        popupCloser.onclick = function () {
            self.popupClose();
            popupCloser.blur();
            return false;
        }

        container.appendChild(popupCloser);
        container.appendChild(btnDiv);

        this.layerNameLabel=document.createElement('div');
        this.layerNameLabel.className="feature-info-popup-layer-name-label";
        container.appendChild(this.layerNameLabel);

        this.popupContent= document.createElement('div');
        container.appendChild(this.popupContent);
        document.body.appendChild(container);


        this.overlay = new Overlay({
            element: container,
            autoPan: true,
            autoPanAnimation: {
                duration: 250,
            },
        });

    }


    toolBtnClicked(evt) {

        this.isActive = !this.isActive;

        if (this.isActive) {
            this.activate();
        }
        else {
            this.deactivate();
        }
    }

    initialize() {
        if (this.initialized) return;
        this.setInfoLayers();
        this.initialized = true;
    }
    setInfoLayers() {

        this.infoLayers = [];
        const map = this.getMap();

        const lyrs = map.getLayers();
        const self = this;
        let setLayers = function (layer) {
            const props = layer.getProperties();

            if (props && props.featureInfoCfg) {
                const cfg = props.featureInfoCfg;
                self.infoLayers.push({
                    layer: layer,
                    featureInfoCfg: cfg
                });
            }
        }
        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    setLayers(lyr);
                }
            });
        }
        getLayers(lyrs);

    }
    activate() {

        this.isActive = true;
        this.initialize();
        const map = this.getMap();
        const self = this;

        map.addOverlay(this.overlay);
        this.mapClickHandlerKey = map.on('singleclick', function (evt) {
            self.mapClickHandler(evt, self)
        });


        this.element.classList.add("active");
        if (this.parentControl) this.parentControl.setActiveControl(this);

    }

    deactivate() {
        this.isActive = false;
        this.element.classList.remove("active");
        if (this.parentControl) this.parentControl.unsetActiveControl();
        const map = this.getMap();
        map.removeOverlay(this.overlay);
        unByKey(this.mapClickHandlerKey);


    }

    mapClickHandler(evt, parent) {
        this.popupFeatures=[];
        const map = evt.target;
        const view = map.getView();
        //var viewResolution = view.getResolution();
      //  var prj = view.getProjection().getCode();
        let sLayers = this.infoLayers;
        let tasks = [];
        let self = this;



        sLayers.forEach(infoCfg => {

            const src = infoCfg.layer.getSource();
            const res = view.getResolution();
            const minR = infoCfg.layer.getProperties().minResolution;
            const maxR = infoCfg.layer.getProperties().maxResolution;

            if (infoCfg.layer.getProperties().visible && (res < maxR && res > minR)) {
                let isEsri=false;
                if (infoCfg.featureInfoCfg.layers && infoCfg.featureInfoCfg.layers.length )
                {
                    isEsri=infoCfg.featureInfoCfg.layers[0].serviceType==="esri"?true:false;
                }
                if(src instanceof ImageWMS) {
                    tasks.push(this.getInfoFromWMS(src,infoCfg,evt));
                }
                if(src instanceof VectorSource) {
                    tasks.push(this.getInfoFromWFS(src,infoCfg,evt));
                 }
                if(src instanceof ImageArcGISRest || (isEsri && src instanceof XYZ)) {
                         tasks.push(this.getInfoFromEsriRest(src,infoCfg,evt));
                }
            }
        });
        Promise.all(tasks).then(function (resp) {
            let features = [].concat.apply([], resp);
            self.initPopup(features,evt.coordinate)
        });
    }
    getInfoFromEsriRest(src,infoCfg,evt) {
        const self=this;
        let url;
        const map = evt.target;
        const view = map.getView();
        const viewResolution = view.getResolution();
        const prj = view.getProjection().getCode();
        let infoFeatures=[];
        const curCfg=infoCfg;
        //geometryType=esriGeometryPoint&geometry=-104,35.6

        return new Promise(function (resolve) {



            let layerDefs="";
            let srcUrl;
            if (src instanceof ImageArcGISRest) {
                srcUrl=src.url_;
                var params = src.getParams();
                if (params.hasOwnProperty("layerDefs")) {
                    layerDefs = params.layerDefs;
                }
            }
            if (src instanceof XYZ) {
                srcUrl=src.key_.replace("/tile/{z}/{y}/{x}","");
                //https://s25009.ymss.sk/arcgis/rest/services/BaseMapServices/Admin_hranice_SK/MapServer/tile/{z}/{y}/{x}
            }
            let subLayerIds;
            if (subLayerIds=infoCfg.featureInfoCfg) {
                subLayerIds=infoCfg.featureInfoCfg.layers.map(c=>{ return c.subLayerId});
            }
            else {
                subLayerIds=[infoCfg.featureInfoCfg.subLayerId];
            }

            const iLayers='visible:'+subLayerIds.join();
            const urlParams= {
                geometryType:'esriGeometryPoint',
                geometry:evt.coordinate.join(),
                outFields:'*',
                tolerance:5,
                mapExtent: view.calculateExtent().join(),
                imageDisplay:map.getSize().join()+',96',
                layers: iLayers,
                layerDefs:layerDefs,
                f:'json'
            }


            const iUrl = new URL(srcUrl+'/identify');
            iUrl.search = new URLSearchParams(urlParams);

            //url = src.getGetFeatureInfoUrl(evt.coordinate, viewResolution,
            //    prj, infoCfg.featureInfoCfg.wmsParms);

            fetch(iUrl).then(function (response) {
                return response.text();
            }).then(function (response) {
                let features = [];
                let results;
                try {
                    let json = JSON.parse(response);
                    results=json.results;
                    //features = new GeoJSON().readFeatures(json);

                }
                catch (e) {
                    console.error("FeatureInfoControl: error parse response.");
                    resolve(infoFeatures);
                }
                const idx = self.infoLayers.findIndex(cfg => {
                    return curCfg.layer.ol_uid == cfg.layer.ol_uid;
                });

               // if (results.length) {
              //      features = new EsriJSON().readFeatures(results);
             //   }
                results.forEach(r => {
                    //filter att?
                    let feats = new EsriJSON().readFeatures(r);

                    feats.forEach(f=> {
                        f.infoLayerId = idx;
                        f.subLayerId = r.layerId;
                        infoFeatures.push(f);
                    });
                });
                resolve(infoFeatures);
            });
        });
    }
    getInfoFromWFS(src, infoCfg, evt) {
        const self=this;
        let features;
        let infoFeatures=[];
        const curCfg=infoCfg;
        return new Promise(function (resolve) {
            //features = src.getFeaturesAtCoordinate(evt.coordinate);
            const buffer = 2*self.getMap().getView().getResolution();
            const ext = [evt.coordinate[0] - buffer, evt.coordinate[1] - buffer,
                evt.coordinate[0] + buffer, evt.coordinate[1] + buffer];
            features = src.getFeaturesInExtent(ext);
            const idx = self.infoLayers.findIndex(cfg => {
                return curCfg.layer.ol_uid == cfg.layer.ol_uid;
            });
            features.forEach(feature => {
                //filter att?
                feature.infoLayerId = idx;
                feature.subLayerId = curCfg.featureInfoCfg.layers.length?curCfg.featureInfoCfg.layers[0].subLayerId:0;
                infoFeatures.push(feature);
            });
            resolve(infoFeatures);
        });
    }
    getInfoFromWMS(src,infoCfg,evt) {
        const self=this;
        let url;
        const map = evt.target;
        const view = map.getView();
        const viewResolution = view.getResolution();
        const prj = view.getProjection().getCode();
        let infoFeatures=[];
        const curCfg=infoCfg;
        return new Promise(function (resolve) {
            const wmsParams=infoCfg.featureInfoCfg.wmsParms?infoCfg.featureInfoCfg.wmsParms:{"INFO_FORMAT": "application/geojson","STYLES": "default"};
            url = src.getFeatureInfoUrl(evt.coordinate, viewResolution,
                prj, wmsParams);
            fetch(url).then(function (response) {
                return response.text();
            }).then(function (response) {
                let features = [];
                try {
                    let json = JSON.parse(response);
                    let feature;
                    json.features.forEach(f=> {
                        if ('application/geojson' == wmsParams.INFO_FORMAT || 'application/json' == wmsParams.INFO_FORMAT) {
                            feature = new GeoJSON().readFeature(f);
                        }
                        //text/xml
                        else {
                            feature = new GML().readFeature(f);
                        }
                        if (feature) {
                            if (f.layerName !== void 0) {
                                feature.subLayerId=f.layerName;
                               // feature.layerName=f.layerName;
                            }
                            else {
                                if (feature.getId && feature.getId() !== void 0) {
                                    feature.subLayerId=feature.getId().split('.')[0];
                                }
                            }
                            features.push(feature);
                        }
                    });
                }
                catch (e) {
                    console.error("FeatureInfoControl: error parse response.");
                    resolve(infoFeatures);
                }
                const idx = self.infoLayers.findIndex(cfg => {
                    return curCfg.layer.ol_uid == cfg.layer.ol_uid;
                });
                features.forEach(feature => {
                    //filter att?

                    feature.infoLayerId = idx;
                    infoFeatures.push(feature);
                });
                resolve(infoFeatures);
            });
        });
    }
    initPopup(features,coord) {

        if(!features || features.length==0) return;
        this.popupFeatures=features;
        this.clickedCoordinates=coord;
        this.currentFeatureId=1;
        this.setLabel();
        this.showPopup(features[0]);

    }
    showPopup(feature) {
        const self = this;
        let coord=this.clickedCoordinates;
        let html = "";
        html += '<div class="feature-info-div"><table class="feature-info-table">';

        const geom=  feature.getGeometry();
        if (geom ){
            //TODO:fit extent
           // coord = this.getCentroid(geom);
        }
        var lyrCfg=this.infoLayers[feature.infoLayerId];
        const curLyr= lyrCfg.featureInfoCfg.layers.filter( c=> { return c.subLayerId==feature.subLayerId; });
        const displayPattern=curLyr.length?curLyr[0].displayPatterns:null;
        const curLyrName=curLyr.length?curLyr[0].layerName:null;
        const curLyrCfg=curLyr.length?curLyr[0]:null;
        const props=feature.getProperties();

        let lyrTitle="Vrstva: ";//+ lyrCfg.layer.getProperties()['title'];
        if(feature.subLayerId !== void 0)  {
            lyrTitle+= curLyrName?curLyrName:feature.subLayerId;
        }
        else {
            lyrTitle+=  lyrCfg.layer.getProperties()['title'];
        }
        this.layerNameLabel.innerHTML=lyrTitle;


        for (let key in props) {
            if(key==='geometry') continue;

            var pattern=this.getDisplayPatternFromField(key,displayPattern);
            var displayfldName;
            var displayValue;
            if(pattern){
                displayfldName=pattern.alias;
                displayValue=props[key];
                var pp=pattern.pattern;
                if(pp && pp.includes("{$}")) {
                    displayValue = pp.replace("{$}", props[key]);
                }
            }
            else {
                 displayfldName=key;
                 displayValue=props[key];
            }
            if(((curLyrCfg && curLyrCfg.showAllFields) || pattern )
                && !this.isExcludedField(key,curLyrCfg)
            ) {
                html += '<tr>';
                html += '<td class="table-header">' + displayfldName + '</td>';
                html += '<td>' + displayValue + '</td>';
                html += '</tr>';
            }
        }
        html += '</table>';
        html += '</div>';
        this.popupContent.innerHTML = html;
        this.overlay.setPosition(coord);
    }
    popupClose() {
        const self=this;
        self.overlay.setPosition(undefined);
        self.popupFeatures=[];
        self.currentFeatureId=-1;
    }
    prevClicked() {
        if (this.currentFeatureId==-1) return;
        const newId=this.currentFeatureId-1;
        if(newId<1) {
            this.currentFeatureId=this.popupFeatures.length;
        }
        else {
            this.currentFeatureId=newId;
        }
        this.setLabel();
        this.showPopup(this.popupFeatures[this.currentFeatureId-1]);
    }
    nextClicked() {
        if (this.currentFeatureId==-1) return;
        const newId=this.currentFeatureId+1;
        if(newId>this.popupFeatures.length) {
            this.currentFeatureId=1;
        }
        else {
            this.currentFeatureId=newId;
        }
        this.setLabel();
        this.showPopup(this.popupFeatures[this.currentFeatureId-1]);
    }
    setLabel() {
        const label= this.featureIdLabel;
        if(label) label.innerHTML=this.currentFeatureId;
        this.featureCountLabel.innerHTML="Počet: " +this.popupFeatures.length ;
    }
    getCentroid(geom) {
        if (geom instanceof Point ) {
            return geom.getCoordinates();
        }
        else {
            var extent=geom.getExtent();
            //this.getMap().getView().fit(extent);
            return getCenter(extent);
        }
    }
    getDisplayPatternFromField(fieldName,cfgPatterns){
        if(!cfgPatterns || cfgPatterns.length==0) return null;
        var result= cfgPatterns.find(p => {
            return p.fldName==fieldName;
        });
        return result;

    }

    isExcludedField(fieldName,layerInfoCfg) {
        //"excludedFields":["Shape_Leng","Shape_Area"],
        if(layerInfoCfg.excludedFields && layerInfoCfg.excludedFields.length>0) {
            return layerInfoCfg.excludedFields.indexOf(fieldName)!==-1;
        }
        else {
            return false;
        }
    }


}