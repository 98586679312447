// @ts-nocheck

import Event from 'ol/events/Event';

export class SelectionEvent extends Event {

    constructor(type, selected) {

        super(type);
        this.selected = selected;
    }

}