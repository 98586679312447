// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';

import {getCenter} from 'ol/extent';
import Point from "ol/geom/Point";
import {listen} from "ol/events";
import EventType from "ol/events/EventType";

import {contains} from 'ol/format/filter.js';
import {WFS, GeoJSON} from 'ol/format.js';
import {unByKey} from "ol/Observable";
require('es6-promise').polyfill();

export class NavigationBarControl extends Control {
    constructor(options) {

        super({
            element: document.createElement('div'),
            target: options.target
        });


        this.options = options;
        this.isActive = false;


        const className = options.className !== undefined ? options.className : 'navigation-bar-control';

        const cssClasses = className + ' ' + CLASS_UNSELECTABLE ;
        const element = this.element;
        element.className = cssClasses;



    }
    activate (map) {
        this.map=map;
        var self=this;

     this.mapMoveEndHandlerKey= map.on('moveend', function(evt){
          self.extentChanged(evt);
      });
    }
    deactivate() {
        unByKey(this.mapMoveEndHandlerKey);
    }
    extentChanged(evt) {

        var body=this.createQueryBody();
        var params={url:this.options.url,data:body};
        var text="";
        var self=this;
        this.getData(params,this.options.serviceType).then(function (results) {
            var features = new GeoJSON().readFeatures(results);
            if(features && features.length)  {
                self.updateControl(features[0]);

            }
        });

    }
    updateControl(feature) {
        var levels=this.options.levels;
        var text="";
        var props=feature.getProperties();
        var self=this;
        levels.forEach(l=> {
           var visible=self.isVisibleAtScale(l.maxScale?l.maxScale:10000000);
           if(visible) {
               if (text) text += ">";
               if(l.displayPattern) {
                   text +=l.displayPattern.replace('${'+l.fieldName+'}',props[l.fieldName]);
               }
               else {
                   text += props[l.fieldName];
               }

           }
        });

        this.element.innerHTML=text;
    }
    getData(obj,serviceType){
        if (this.options.serviceType==="esri") {
            var promise = new Promise(function (resolve, reject) {

                var config = {
                    method: 'GET',
                    mode: 'cors',
                };
                const iUrl = new URL(obj.url);
                iUrl.search = new URLSearchParams(obj.data);
                fetch(iUrl, config)
                    .then(function (r) {
                        return r.json();
                    })
                    .then(resolve)
                    .catch(reject);
            });
            return promise;
        }
        else {
            var promise = new Promise(function (resolve, reject) {

                var config = {
                    method: 'POST',
                    body: obj.data,
                    mode: 'cors',
                };

                fetch(obj.url, config)
                    .then(function (r) {
                        return r.json();
                    })
                    .then(resolve)
                    .catch(reject);

            });

            return promise;
        }

    }

    createQueryBody() {

        if (this.options.serviceType==="esri") {
            const urlParams= {
                geometryType:'esriGeometryPoint',
                geometry:this.getMap().getView().getCenter().join(),
                spatialRel:'esriSpatialRelIntersects',
                outFields:'*',
                f:'geojson'
            }
            return urlParams;

        }
        else {
            var wfsOpt = this.options.wfsOptions;
            var centerPoint = new Point(this.getMap().getView().getCenter());
            var qFilter = contains(wfsOpt.geometryField, centerPoint, wfsOpt.srsName);
            var featureRequest = new WFS().writeGetFeature({
                srsName: wfsOpt.srsName,
                featureNS: wfsOpt.featureNS,
                featurePrefix: wfsOpt.featurePrefix,
                featureTypes: wfsOpt.featureTypes,
                outputFormat: wfsOpt.outputFormat,
                filter: qFilter
            });

            var body = new XMLSerializer().serializeToString(featureRequest);

            return body;
        }

    }
    isVisibleAtScale(scale) {
        var resolution=this.calculateResolution(scale);
        var view = this.map.getView();
        var viewResolution = view.getResolution();
        var visible= resolution>viewResolution;
        return visible;
    }
    calculateResolution(scale){


        const ipm = 39.3700787;
        const dpi = 96;

        var mpu=this.map.getView().getProjection().getMetersPerUnit();
        return  scale / (mpu * ipm * dpi);
    }
}