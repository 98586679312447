// @ts-nocheck

import LayerGroup from "ol/layer/Group";
import JSZip from 'jszip';
import {projectionsDefs} from "../config/Projections.js";

require('es6-promise').polyfill();


export class ExportMap {
    constructor(map, options) {
        this.map = map;
        this.options = options;
    }

    exportMap(extent,params) {

        var self = this;
        this.setLayersVisibility();
        if (extent) {
            this.map.getView().fit(extent);
        }

        const size = self.map.getSize();
        const viewResolution = self.map.getView().getResolution();
        const origExtent = self.map.getView().calculateExtent(size);
        let width = size[0];
        let height = size[1];
        let resChanged = false;

        if (params && params.dpi) {
            const res=params.dpi/ 90.7;
            if (res<0.98 || res>1.02) {
                width = Math.round((size[0] * res));
                height = Math.round((size[1] * res));
                resChanged = true;
            }
        }
        let imgFormat="image/png";
        if (params && params.format && params.format==="jpeg") {
            imgFormat="image/jpeg";
        }

        var promise = new Promise(function (resolve) {

            self.map.once('rendercomplete', function (event) {
                     const mapCanvas=self.setMapCanvas(width, height);
                    if (navigator.msSaveBlob) {
                        resolve(mapCanvas.msToBlob());
                        self.setLayerVisibility();
                        if (resChanged) {
                            self.map.setSize([width, height]);
                            self.map.getView().setResolution(viewResolution);
                        }
                    } else {
                        mapCanvas.toBlob(function (blob) {
                            resolve(blob);
                            self.setLayerVisibility();
                            if (resChanged) {
                                self.map.setSize([width, height]);
                                self.map.getView().setResolution(viewResolution);
                            }
                        },imgFormat);
                    }

            });
            if (width && height) {
                self.map.setSize([width, height]);
                self.map.getView().fit(origExtent, {size: [width, height]});
            }
        });
        return promise;
    }
     setMapCanvas(width, height) {
        const map=this.map;
        const mapCanvas = document.createElement('canvas');
        const size = map.getSize();
        mapCanvas.width = width;
        mapCanvas.height = height;
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
            map.getViewport().querySelectorAll('.ol-layer canvas, canvas.ol-layer'),
            function (canvas) {
                if (canvas.width > 0) {
                    const opacity =
                        canvas.parentNode.style.opacity || canvas.style.opacity;
                    mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                    let matrix;
                    const transform = canvas.style.transform;
                    if (transform) {
                        // Get the transform parameters from the style's transform matrix
                        matrix = transform
                            .match(/^matrix\(([^\(]*)\)$/)[1]
                            .split(',')
                            .map(Number);
                    } else {
                        matrix = [
                            parseFloat(canvas.style.width) / canvas.width,
                            0,
                            0,
                            parseFloat(canvas.style.height) / canvas.height,
                            0,
                            0,
                        ];
                    }
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        matrix
                    );
                    const backgroundColor = 'white';//canvas.parentNode.style.backgroundColor;
                    if (backgroundColor) {
                        mapContext.fillStyle = backgroundColor;
                        mapContext.fillRect(0, 0, canvas.width, canvas.height);
                    }
                    mapContext.drawImage(canvas, 0, 0);
                }
            }
        );
        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);
        return mapCanvas;
    }
    exportMapAsFile(extent, dpi, format, georeferenced){
        const params = {
            dpi: dpi || this.options.print.defaultDPI,
            format: format ||  this.options.print.defaultFormat,
        }
        let promise;
        if(georeferenced === true){
            promise = this.exportMapAsZip(extent,params);
        } else {
           promise = this.exportMap(extent, params);
        }
        return promise;
    }

    exportMapAsZip(extent,params) {

        var self = this;
        this.setLayersVisibility();
        if (extent) {
            this.map.getView().fit(extent);
        }
        const size = self.map.getSize();
        const viewResolution = self.map.getView().getResolution();
        const origExtent = self.map.getView().calculateExtent(size);
        let width = size[0];
        let height = size[1];
        if (params && params.dpi) {
            const res=params.dpi/ 90.7;
            if (res<0.98 || res>1.02) {
                width = Math.round((size[0] * res));
                height = Math.round((size[1] * res));
            }
        }

        let imgFormat="image/png";
        let imgExt='png';
        if (params && params.format && params.format==="jpeg" && (!navigator.msSaveBlob)) {
            imgFormat="image/jpeg";
            imgExt='jpg';
        }
        var promise = new Promise(function (resolve) {
            self.map.once('rendercomplete', function (event) {
                const mapCanvas=self.setMapCanvas(width, height);
                var zip = new JSZip();
                var res = self.map.getView().getResolution();
                var ext = self.map.getView().calculateExtent();
                var projDef=projectionsDefs[self.map.getView().getProjection().getCode()];
                var projStr;
                if(projDef) {
                    projStr=projDef.wkt;
                }
                var pngw = [res, 0, 0, -res, ext[0] + res / 0.5, ext[3] - res / 0.5].join('\n');
                zip.file("map."+imgExt+"w", pngw);
                if(projStr){
                    zip.file("map.prj",projStr);
                    zip.file("map."+imgExt+".aux.xml",'<PAMDataset>\n<SRS>'+projStr+'</SRS>\n</PAMDataset>');
                }
                if (navigator.msSaveBlob) {
                    zip.file("map."+imgExt, mapCanvas.msToBlob(), {base64: true});
                    resolve(zip.generateAsync({type:"blob"}));
                    self.setLayerVisibility();
                    if (width && height) {
                        self.map.setSize([width, height]);
                        self.map.getView().setResolution(viewResolution);
                    }

                } else {
                    mapCanvas.toBlob(function (blob) {
                        zip.file("map."+imgExt, blob, {base64: true});
                        resolve(zip.generateAsync({type:"blob"}));
                        self.setLayerVisibility();
                        if (width && height) {
                            self.map.setSize([width, height]);
                            self.map.getView().setResolution(viewResolution);
                        }
                    },imgFormat);
                }


            });
            if (width && height) {
                self.map.setSize([width, height]);
                self.map.getView().fit(origExtent, {size: [width, height]});
            }
            self.map.renderSync();
        });
        return promise;
    }

    setLayersVisibility() {
        if (this.options && this.options.printOnlyActiveLayers) {
          return;
        }
        var lyrs = this.map.getLayers();
        var self = this;
        var setPrintLayers = function (layer) {
            var props = layer.getProperties();
            if (props) {
                var visible = layer.getVisible();
            }
            if (props.print) {
                if (!visible) {
                    layer.setVisible(true);
                    self.notVisibleLayers.push(layer);
                }
            }
            if (!props.print) {
                if (visible) {
                    layer.setVisible(false);
                    self.visibleLayers.push(layer);
                }
            }
        }

        this.notVisibleLayers = [];
        this.visibleLayers = [];
        lyrs.forEach(lyr => {

            if (lyr instanceof LayerGroup) {
                var grp = lyr.getLayers();
                grp.forEach(lyr2 => {
                    setPrintLayers(lyr2);
                });
            }
            else {
                setPrintLayers(lyr);
            }

        });


    }

    setLayerVisibility() {

        if(this.visibleLayers) {
            this.visibleLayers.forEach(lyr => {
                lyr.setVisible(true);
            });
        }
        if(this.notVisibleLayers) {
            this.notVisibleLayers.forEach(lyr => {
                lyr.setVisible(false);
            });
        }
        this.notVisibleLayers = [];
        this.visibleLayers = [];
    }
}
