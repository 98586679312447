// @ts-nocheck

import Geocoder from './ol-geocoder/ol-geocoder-yms';
import {
    like as likeFilter,
    or as orFilter
} from 'ol/format/filter.js';
import {WFS, GeoJSON} from 'ol/format.js';
import {getCenter} from 'ol/extent';

export class WfsSearchProvider {

    constructor(options) {
        this.url = options.url;
        this.wfsOptions=options.wfsOptions;
        this.maxFeatures = options.limit;
    }

    createFilter(phrase) {
        var conditions=[];
        this.wfsOptions.queryFields.forEach(fld=>{
            conditions.push(likeFilter(fld, '*'+phrase+'*','*','.','!',false));
        });
        if (conditions.length==0) {
            return null;
        }
        if (conditions.length==1) {
            return conditions[0];
        }
        else {
            return orFilter.apply( null, conditions );
        }


    }
    getQueryRequest(phrase) {

        var qFilter=this.createFilter(phrase);
        var featureRequest = new WFS().writeGetFeature({
            srsName: this.wfsOptions.srsName,
            featureNS:this.wfsOptions.featureNS,
            featurePrefix:this.wfsOptions.featurePrefix,
            featureTypes: this.wfsOptions.featureTypes,
            outputFormat: this.wfsOptions.outputFormat,
            maxFeatures: this.maxFeatures,
            filter: qFilter
        });

        var body= new XMLSerializer().serializeToString(featureRequest);
        return body;

    }
    getParameters(opt) {
        return {
            url: this.url,
            method:'POST',
            callbackName: 'callback',
            requestBody:this.getQueryRequest(opt.query)

        };
    }



    handleResponse(results) {

        var displayPattern= this.wfsOptions.displayPattern;
        var srs=this.wfsOptions.srsName;
        var features = new GeoJSON().readFeatures(results);
        var self=this;
        if (results && features && features.length) {
            var newFeatures=[];
            features.forEach(function(feature){
                var displayStr=displayPattern;
                var props=feature.getProperties();
                for (var p in props) {
                    var val=props[p];
                    displayStr=displayStr.replace('${'+p+'}',val);
                }
                var ext=feature.getGeometry().getExtent();
                var p=getCenter(ext);
                var findedStr=props[self.wfsOptions.queryFields[0]];
                newFeatures.push({
                    lat:p[1],
                    lon:p[0],
                    srsName: srs,
                    bbox: ext,
                    address: {
                        name: displayStr,
                        findedStr:findedStr

                    }
                });

            });
            return newFeatures;
        } else {
            return;
        }
    }


}