// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {defaults as defaultInteractions, Draw, Modify, Snap, Select} from 'ol/interaction';

import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {click} from 'ol/events/condition';
import {Circle as CircleStyle,Icon, Fill, Stroke, Style, Text} from 'ol/style';
import Feature from 'ol/Feature';
import {extend, getCenter} from 'ol/extent';
import Point from "ol/geom/Point";
import {unByKey} from "ol/Observable";
import Geolocation from 'ol/Geolocation';
import Intersects from 'ol/format/filter/Intersects';
import {WFS, GeoJSON} from 'ol/format.js';
import {transform} from 'ol/proj';
var iconData="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgaWQ9IkxheWVyXzFfMV8iCiAgIHZlcnNpb249IjEuMSIKICAgaGVpZ2h0PSIyNC4wMDAwMDBwdCIgdmlld0JveD0iMCAwIDE2LjAwMDAwMCAxNi4wMDAwMDAiIHdpZHRoPSIyNC4wMDAwMDBwdCIKICAgcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCIKICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcwogICAgIGlkPSJkZWZzOSIgLz48cGF0aAogICAgIGQ9Ik04LjUsMEM0LjM1OCwwLDEsMy4zNTgsMSw3LjVTNC4zNTgsMTUsOC41LDE1UzE2LDExLjY0MiwxNiw3LjVTMTIuNjQyLDAsOC41LDB6IE04LjUsMTNDNS40NjgsMTMsMywxMC41MzMsMyw3LjUgIFM1LjQ2OCwyLDguNSwyUzE0LDQuNDY3LDE0LDcuNVMxMS41MzIsMTMsOC41LDEzeiIKICAgICBpZD0icGF0aDIiCiAgICAgc3R5bGU9ImZpbGw6I2Y0OWMxMjtmaWxsLW9wYWNpdHk6MTtzdHJva2U6bm9uZSIgLz48Y2lyY2xlCiAgICAgY3g9IjguNSIKICAgICBjeT0iNy41IgogICAgIHI9IjQuNSIKICAgICBpZD0iY2lyY2xlNCIKICAgICBzdHlsZT0iZmlsbDojZjQ5YzEyO2ZpbGwtb3BhY2l0eToxO3N0cm9rZTpub25lIiAvPjwvc3ZnPg==";
var styles = {

    "default": [

        new Style({
            image: new Icon({
                crossOrigin: 'anonymous',
                src: iconData
            })

        })]

};

export class GeoLocationControl extends Control {

    constructor(options,map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });
        this.parentControl = options.parentControl;
        this.map=map;
        this.isActive=false;
        this.isFitted=false;

        var view=map.getView();
        this.geolocation = new Geolocation({
            trackingOptions: {
                enableHighAccuracy: true,
            },
            projection: view.getProjection(),
        });
        this.positionFeature = new Feature();
        this.drawLayer = new VectorLayer({
            name:"drawGeoLocationLayer",
            //always print
            print:true,
            source: new VectorSource({wrapX: false, features: [this.positionFeature],crossOrigin: 'Anonymous'}),
            style: styles["default"]

        });
        map.addLayer(this.drawLayer);
        //this.element.className = 'geolocation-control';
        this.toolDiv = document.createElement('div');

        // var toolClass = 'draw-poi-control-tool';
        // var className = toolClass + ' ' + 'draw-poi-control-' + toolType;
        var button = document.createElement('button');

        var tipLabel = 'Zobrazenie aktuálnej polohy';
        button.setAttribute('type', 'button');
        button.title = tipLabel;


        listen(button, EventType.CLICK, function (evt) {
                this.btnToolClicked(evt);
            }, this
        );
         var cssClasses = 'geolocation-control' + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;

        this.toolDiv.className = cssClasses;
        this.toolDiv.appendChild(button);
        this.element.appendChild(this.toolDiv);
    }




    btnToolClicked(evt) {

        if( this.isActive) {
            this.deactivate();
            return;
        }
        this.activate();
        this.isFitted = false;
        this.geolocation.setTracking(this.isActive);
        const self=this;
        this.changePositionHndKey=this.geolocation.on('change:position', function () {
            const coordinates = self.geolocation.getPosition();
            self.positionFeature.setGeometry(coordinates ? new Point(coordinates) : null);
            if (!self.isFitted && self.positionFeature.getGeometry()) {
                self.isFitted = true;
                self.map.getView().fit(self.positionFeature.getGeometry(), {"maxZoom": 8});
            }
        });
        this.errorPositionHndKey=this.geolocation.on('error', function (error) {
            console.log(error.message);
        });

    }


    activate() {
        this.isActive=true;
        if (this.parentControl) {
            if (!(this.parentControl.activeControl instanceof GeoLocationControl)) {
                this.parentControl.setActiveControl(this);
            }
        }
        if (this.toolDiv) {
             this.toolDiv.classList.add("active");
         }



    }

    deactivate() {

        this.isActive=false;
        this.positionFeature.setGeometry(null);
        this.geolocation.setTracking(this.isActive);

        if (this.changePositionHndKey) {
            unByKey(this.changePositionHndKey);
            this.changePositionHndKey=null;
        }
        if (this.errorPositionHndKey) {
            unByKey(this.errorPositionHndKey);
            this.errorPositionHndKey=null;
        }

        this.deactivateTool();
        if (this.parentControl)
            if ((this.parentControl.activeControl instanceof GeoLocationControl)) {
                this.parentControl.unsetActiveControl();
            }

    }
    deactivateTool() {
            if (this.toolDiv) {
                this.toolDiv.classList.remove("active");
            }

    }

}