// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {SelectionEvent} from './SelectionEvent';
import EsriJSON from 'ol/format/EsriJSON';

import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import ImageWMS from 'ol/source/ImageWMS';

import GeoJSON from 'ol/format/GeoJSON';
import LayerGroup from 'ol/layer/Group';
import {Image as ImageLayer} from "ol/layer";
import {extend as extendExtent} from 'ol/extent';
import {unByKey} from "ol/Observable";
require('es6-promise').polyfill();

export class SelectionControl extends Control {


    constructor(options) {

        super({
            element: document.createElement('div'),
            target: options.target
        });

        this.specificSelectionCfg = options.config.specificSelection;
        this.parentControl = options.parentControl;
        this.isButtonVisible = true;
        if (options.config.selectionControl) {
            this.isButtonVisible = options.config.selectionControl.isButtonVisible ? true : false;
        }
        this.maxFeaturesCount = 10;
        this.maxSelectionLayerCount = 20;

        this.options = options;
        this.isActive = false;
        this.selectableLayers = [];
        this.fitExtent = null;

        const className = options.className !== undefined ? options.className : 'selection-control';
        const button = document.createElement('button');
        const label = "S";
        const tipLabel = 'Vybrať objekt';
        button.setAttribute('type', 'button');
        button.title = tipLabel;
        /* button.appendChild(
             typeof label === 'string' ? document.createTextNode(label) : label
         );
 */
        listen(button, EventType.CLICK, this.handleClick_, this);
        const cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
        const element = this.element;
        element.className = cssClasses;

        element.appendChild(button);
        if (this.isButtonVisible == false) {
            this.setVisibility(false);
        }
    }

    setSelectableLayers() {

        this.selectableLayers = [];
        var map = this.getMap();

        var lyrs = map.getLayers();
        var self = this;
        var setSelectLayers = function (layer) {
            var props = layer.getProperties();
            var exists = false;

            if (props && props.selectable) {
                var cfg = props.selectionCfg ? props.selectionCfg : this.defaultSelectionCfg;
                self.selectableLayers.push({
                    layer: layer,
                    selectsetLayer: self.createSelectsetLayer(layer),
                    selectionCfg: cfg,
                    data: []
                });
            }
        }

        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    setSelectLayers(lyr);
                }
            });
        }
        getLayers(lyrs);

    }

    initialize() {
        if (this.initialized) return;
        var map = this.getMap();
        this.setSelectableLayers();
        this.initialized = true;
    }


    handleClick_(event) {

        event.preventDefault();
        this.isActive = !this.isActive;

        if (this.isActive) {
            this.activate();
        }
        else {
            this.deactivate();
        }

    }

    tryActivate() {

        if (!this.isActive) {
            this.activate();
        }

    }

    setVisibility(visible) {
        if (this.isButtonVisible) return;
        if (visible) {
            if (!this.isActive) {
                this.activate();
            }
            this.element.style.display = "";
        }
        else {
            if (this.isActive) {
                this.deactivate();
            }
            this.element.style.display = "none";
        }
    }

    activate() {
        this.isActive = true;
        this.initialize();
        var map = this.getMap();
        var self = this;
        if (this.options.selectionMethod === 'click') {
            this.mapClickHandlerKey = map.on('singleclick', function (evt) {
                self.mapClickHandler(evt, self, null)
            });
        }

        this.element.classList.add("active");
        if (this.parentControl) this.parentControl.setActiveControl(this);

    }

    deactivate() {
        this.isActive = false;
        this.element.classList.remove("active");
        if (this.parentControl) this.parentControl.unsetActiveControl();

        unByKey(this.mapClickHandlerKey);


    }

    specificSelectionQuery(data, cfg, errCallback) {
        var view = this.getMap().getView();
        var minR = 0;
        var allQueryUrl = [];
        this.selectableLayers.forEach(c => {
            var r = c.layer.getProperties().minResolution;
            if (minR < r) minR = r;
        });

        var outSR = {wkid: view.getProjection().getCode().replace("EPSG:", "")};

        var where = "";
        var fCnt = 0;
        data.forEach(d => {
            var andClause = "";
            for (var j = 0; j < d.srcPK.length; j++) {
                var val = d.srcPK[j];
                if (val === String(val)) {
                    val = "'" + val + "'";
                }
                var fld = cfg.srcPK[j];
                andClause += !andClause ? "(" + fld + "=" + val : "+and+" + fld + "=" + val;
            }
            andClause += ") ";
            where += !where ? "where=" + andClause : "+or+" + andClause;
            fCnt++;
            if (fCnt == this.maxFeaturesCount) {
                allQueryUrl.push(cfg.url + "&" + where + "&outSR=" + JSON.stringify(outSR));
                where = "";
                fCnt = 0;
            }
        });

        if (where) {
            allQueryUrl.push(cfg.url + "&" + where + "&outSR=" + JSON.stringify(outSR));
        }
        var self = this;

        try {
            Promise.all(allQueryUrl.map(url => fetch(url)))
                .then(responses => {
                        var hasError = false;
                        responses.forEach(r => {
                            if (!r.ok) hasError = true
                        });
                        if (hasError) {
                            if (errCallback) errCallback({status: "OtherError"});
                        }
                        return Promise.all(responses.map(res => {
                                return res.json();
                            }
                        ))
                    }
                ).then(jsons => {
                if (!jsons) return;
                jsons.forEach(json => {
                    var esriFormat = new EsriJSON()
                    var features = esriFormat.readFeatures(json);
                    if (features.length == 0) {
                        if (errCallback) errCallback({status: "NotFoundError"});
                    }
                    if (features.length > 0) {
                        data.forEach(d => {
                            features.forEach(f => {
                                var valid = true;
                                for (var j = 0; j < cfg.srcPK.length; j++) {
                                    var srcPK = cfg.srcPK[j];
                                    //var destPK = cfg.destPK[j];
                                    if (d.srcPK[j] != f.getProperties()[srcPK]) {
                                        valid = false;
                                    }
                                }
                                if (valid) {
                                    f.cfg = cfg;
                                    f.destPK = d.destPK;
                                    f.selectionLayerId = d.selectionLayerId;
                                    f.errCallback = errCallback;
                                    self.selectByPolygon(f);
                                    var ext = f.getGeometry().getExtent();
                                    self.fitExtent = self.fitExtent ? extendExtent(self.fitExtent, ext) : ext;
                                }
                            });
                        });
                        if (self.fitExtent) view.fit(self.fitExtent);
                    }
                });
            });
        }
        catch (err) {
            if (errCallback) errCallback({status: "OtherError"});
        }

    }

    setSpecificSelection(data, errCallback) {

        this.fitExtent = null;
        //{selectionLayerId,pkSrc,pkDest}
        this.initialize();
        var dataArr = [];
        if (Array.isArray(data)) {
            dataArr = data;
        }
        else {
            dataArr.push(data);
        }

        var dataByLayer = {};
        dataArr.forEach(d => {
            if (!dataByLayer.hasOwnProperty(d.selectionLayerId)) {
                dataByLayer[d.selectionLayerId] = [d];
            }
            else {
                dataByLayer[d.selectionLayerId].push(d);
            }
        });
        for (var layerId in dataByLayer) {
            //var selectionLayerId = dataArr[0].selectionLayerId;
            var cfg = this.specificSelectionCfg[layerId];
            var selCfg;
            this.selectableLayers.forEach(c => {
                if (c.selectionCfg.layerId == layerId) {
                    selCfg = c;
                }
            });
            if (!selCfg || !cfg) continue;
            //always clear
            this.clearSelection(selCfg);
            this.specificSelectionQuery(dataByLayer[layerId], cfg, errCallback);
        }
    }

    selectByPolygon(feature) {
        var map = this.getMap();

        var clickPoint = feature.getGeometry().getInteriorPoint().getCoordinates();
        this.mapClickHandler(
            {target: map, coordinate: [clickPoint[0], clickPoint[1]]},
            this, feature
        );


    }

    mapClickHandler(evt, parent, srcFeature) {

        var map = evt.target;
        var view = map.getView();
        var viewResolution = view.getResolution();
        var prj = view.getProjection().getCode();
        var sLayers = this.selectableLayers;
        var tasks = [];
        var self = this;
        sLayers.forEach(selectionCfg => {
            if (srcFeature && srcFeature.selectionLayerId !== selectionCfg.selectionCfg.layerId)
                return;

            var src = selectionCfg.layer.getSource();
            var url;
            var res = view.getResolution();
            var minR = selectionCfg.layer.getProperties().minResolution;
            var maxR = selectionCfg.layer.getProperties().maxResolution;


            if (srcFeature || (selectionCfg.layer.getProperties().visible
                && (res < maxR && res > minR))
                && src instanceof ImageWMS) {
                if (srcFeature) viewResolution = minR;
                var promise = new Promise(function (resolve) {
                    url = src.getFeatureInfoUrl(evt.coordinate, viewResolution,
                        prj, {
                            'INFO_FORMAT': 'application/geojson',
                            "STYLES": "default", "SLD_BODY": null
                        });
                    fetch(url).then(function (response) {
                        return response.text();
                    }).then(function (response) {
                        var json = JSON.parse(response);
                        var features = new GeoJSON().readFeatures(json);

                        features.forEach(feature => {
                            var valid = true;

                            if (srcFeature) {
                                for (var j = 0; j < srcFeature.cfg.srcPK.length; j++) {
                                    //var srcPK=srcFeature.cfg.srcPK[j];
                                    var destPK = srcFeature.cfg.destPK[j];
                                    if (srcFeature.destPK[j] != feature.getProperties()[destPK]) {
                                        valid = false;
                                    }
                                }
                            }
                            if (valid) {

                                parent.checkSelection(
                                    parent.createFeature(feature, selectionCfg)
                                    , selectionCfg);
                            }
                            else {
                                if (srcFeature.errCallback) srcFeature.errCallback({status: "WmsNotFoundError"});
                            }
                        }, parent);

                        //  var selection = parent.getSelectedFeatures();
                        //   parent.dispatchEvent(new SelectionEvent('select', selection));
                        resolve(true);
                    });
                });
                tasks.push(promise);
            }
        });
        Promise.all(tasks).then(function (resp) {
            var selection = self.getSelectedFeatures();
            self.dispatchEvent(new SelectionEvent('select', selection));

        });
    }

    clearAll() {
        if (!this.selectableLayers) return;
        this.selectableLayers.forEach(lyr => {
            this.clearSelection(lyr);
        });
    }

    clearSelection(selectionConfig) {
        var deletes = [];
        if (!selectionConfig || !selectionConfig.data) return;
        selectionConfig.data.forEach(function (oldFeature) {
            deletes.push(oldFeature);
        });

        this.updateSelection([], deletes, selectionConfig);
    }

    checkSelection(newFeature, selectionConfig, isDelete) {
        var deletes = [];
        var adds = [];
        var self = this;

        selectionConfig.data.forEach(function (oldFeature) {
            if (newFeature.equals(oldFeature)) {
                deletes.push(oldFeature);
            }
        });
        if (deletes.length == 0 && !isDelete) {
            adds.push(newFeature);
        }
        this.updateSelection(adds, deletes, selectionConfig);

    }

    createFeature(feature, selectionConfig) {

        var feat = {};
        var featProps = feature.getProperties();
        var fldDefs = selectionConfig.selectionCfg.fieldsDef;
        fldDefs.fields.forEach(fld => {
            var val = featProps[fld.alias];
            if (featProps[fld.alias] == "null" || featProps[fld.alias] == "Null") {
                val = null;
            }
            feat[fld.displayName] = val;
        });
        this.extendFeature(feat, fldDefs);
        return feat;
    }

    extendFeature(feature, fieldsDef) {
        feature.equals = function (obj) {
            const PK = fieldsDef.primaryKeyAlias;
            if (!obj.hasOwnProperty(PK)) return false;
            if (!this.hasOwnProperty(PK)) return false;
            if (obj[PK] != this[PK]) return false;

            return true;
        }
    }

    updateSelection(adds, deletes, selectionConfig) {
        if (adds.length != 0) {
            selectionConfig.data = selectionConfig.data.concat(adds);
        }
        if (deletes.length != 0) {
            deletes.forEach(function (del) {
                selectionConfig.data = selectionConfig.data.filter(function (item) {
                    var r = !del.equals(item);
                    return r;
                });
            });
        }
        this.highlightFeatures(selectionConfig);

    }


    highlightFeatures(selectionConfig) {

        if (!selectionConfig.selectsetLayer) return;
        if (selectionConfig.selectionCfg.serviceType === "esriWMS") {
            this.highlightFeaturesForEsriWMS(selectionConfig);
            return;
        }

        //${selectionColor}
        //${layerName}
        //${filter}
        var sColor = selectionConfig.selectionColor;
        var layerName = "0";
        var sldStyleTemplate = '<sld:StyledLayerDescriptor version="1.0.0" xmlns="http://www.opengis.net/ogc" xmlns:sld="http://www.opengis.net/sld" xmlns:ogc="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd"><sld:NamedLayer><sld:Name>${layerName}</sld:Name><sld:UserStyle><sld:Name>highlightedFeatures</sld:Name><sld:Title>highlightedFeatures</sld:Title><sld:FeatureTypeStyle><sld:Rule><ogc:Filter>${filter}</ogc:Filter><sld:PolygonSymbolizer><sld:Stroke><sld:CssParameter name="stroke">${selectionColor}</sld:CssParameter><sld:CssParameter name="stroke-opacity">1</sld:CssParameter><sld:CssParameter name="stroke-width">3</sld:CssParameter></sld:Stroke></sld:PolygonSymbolizer></sld:Rule></sld:FeatureTypeStyle></sld:UserStyle></sld:NamedLayer></sld:StyledLayerDescriptor>';
        var sld = sldStyleTemplate.replace('${layerName}', layerName).replace('${selectionColor}', sColor);
        var sldFilter = '';
        var propFilter = '<ogc:PropertyIsEqualTo><ogc:PropertyName>${fieldName}</ogc:PropertyName><ogc:Literal>${filteredValue}</ogc:Literal></ogc:PropertyIsEqualTo>';

        selectionConfig.data.forEach(item => {
            //or
            if (selectionConfig.primaryKey.length > 0) sldFilter += '<ogc:And>';
            selectionConfig.primaryKey.forEach(pk => {
                var fldValue = item[pk.alias];
                var fldName = pk.name;
                var filt = propFilter.replace('${fieldName}', fldName).replace('${filteredValue}', fldValue);
                sldFilter += filt;
            });
            for (var pk in item) {
                if (typeof item[pk] != 'function') {

                }

            }
            if (selectionConfig.primaryKey.length > 0) sldFilter += '</ogc:And>';
            //or
        });

        sld = sld.replace('${filter}', sldFilter);

        var src = selectionConfig.selectsetLayer.getSource();
        var params = src.getParams();
        params["STYLES"] = "highlightedFeatures";
        params["SLD_BODY"] = sld;
        src.updateParams(params);
        selectionConfig.selectsetLayer.setVisible(true);

    }

    highlightFeaturesForEsriWMS(selectionConfig) {
        //${selectionColor}
        //${layerName}
        var selCfg = selectionConfig.selectionCfg;

        var sColor = selCfg.selectionColor;
        var layerName = selCfg.layerName;
        var sldStyleTemplate = '<sld:StyledLayerDescriptor version="1.0.0" xmlns="http://www.opengis.net/ogc" xmlns:sld="http://www.opengis.net/sld" xmlns:ogc="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd"><sld:NamedLayer><sld:Name>${layerName}</sld:Name><sld:UserStyle><sld:Name>sel</sld:Name><sld:FeatureTypeStyle><sld:Rule><sld:PolygonSymbolizer><sld:Stroke><sld:CssParameter name="stroke">${selectionColor}</sld:CssParameter><sld:CssParameter name="stroke-opacity">1</sld:CssParameter><sld:CssParameter name="stroke-width">2</sld:CssParameter></sld:Stroke></sld:PolygonSymbolizer></sld:Rule></sld:FeatureTypeStyle></sld:UserStyle></sld:NamedLayer></sld:StyledLayerDescriptor>';
        var sld = sldStyleTemplate.replace('${layerName}', layerName).replace('${selectionColor}', sColor);

        var idField = selCfg.fieldsDef.primaryKeyAlias;
        var idFieldName = "ID";

        selCfg.fieldsDef.fields.forEach(fld => {
            if (fld.alias === idField) idFieldName = fld.name;
        });
        var layerDefs = '{"' + layerName + '":"' + idFieldName + ' in (';
        var inData = [];
        var layerDefsGroups = [];
        var cnt = 0;
        selectionConfig.data.forEach(item => {
            inData.push(item[idField]);
            cnt++;
            if (cnt == this.maxFeaturesCount) {
                cnt = 0;
                layerDefsGroups.push(layerDefs + inData.join(',') + ')"}');
                inData = [];
            }
        });
        if (inData.length) {
            layerDefsGroups.push(layerDefs + inData.join(',') + ')"}');
        }
        //clear selection
        selectionConfig.selectsetLayer.forEach(l => l.setVisible(false));
        for (var i = 0; i < layerDefsGroups.length; i++) {
            var src = selectionConfig.selectsetLayer[i].getSource();

            var params = src.getParams();
            var newParams = {};
            //style name in sld body
            newParams["STYLES"] = "sel";
            newParams["SLD_BODY"] = sld;
            newParams["layerDefs"] = layerDefsGroups[i];
            src.updateParams(newParams);
            selectionConfig.selectsetLayer[i].setVisible(true);
        }

    }

    createSelectsetLayer(layer) {


        var sLayers = [];
        var map = this.getMap();
        var lyrs = map.getLayers();

        for (var i = 0; i < this.maxSelectionLayerCount; i++) {
            var sLayer = null;
            var name = "selectset_" + layer.ol_uid + "_" + i;
            var lyrExists = false;
            lyrs.forEach(lyr => {
                var lyrProps = lyr.getProperties();
                if (lyrProps.title == name) lyrExists = true;
            });
            if (lyrExists) continue;

            var wmsSrc = layer.getSource();
            if (wmsSrc instanceof ImageWMS) {
                var params = wmsSrc.getParams();
                var newParams = {};
                for (var p in params) {
                    newParams[p] = params[p];
                }

                sLayer = new ImageLayer({
                    type: "",
                    visible: false,
                    print: layer.getProperties().print,

                    selectable: false,
                    source: new ImageWMS({
                        url: wmsSrc.getUrl(),
                        params: newParams,
                        crossOrigin: 'Anonymous',
                        ratio: 1
                    })
                });
            }
            var hnd = layer.on('change:visible', function (evt) {
                sLayer.setVisible(evt.target.get(evt.key));
            });
            map.addLayer(sLayer);
            sLayers.push(sLayer);
        }
        return sLayers;
    }

    removeFromSelection(features) {
        //{id: 545741547, layerId: 1}
        var self = this;
        features.forEach(f => {
            self.selectableLayers.forEach(cfg => {
                var lyrId = cfg.selectionCfg.layerId;
                if (lyrId == f.layerId) {
                    self.extendFeature(f, cfg.selectionCfg.fieldsDef);
                    self.checkSelection(f, cfg, true);
                }
            });
        });
        if (features.length) {
            var selection = this.getSelectedFeatures();
            this.dispatchEvent(new SelectionEvent('select', selection));

        }
    }

    displayFeatures(data) {

        this.tryActivate();
        var extents = [];
        var self = this;
        for (var layerId in data) {
            this.selectableLayers.forEach(cfg => {
                var lyrId = cfg.selectionCfg.layerId;
                if (lyrId == layerId) {
                    var features = data[lyrId].features;
                    if (features && features.length) {

                        features.forEach(f => {
                            self.extendFeature(f, cfg.selectionCfg.fieldsDef);
                            self.checkSelection(f, cfg);
                        })
                        // self.updateSelection(data[lyrId].features, [], cfg);
                    }
                    if (data[lyrId].extents) {
                        extents = extents.concat(data[lyrId].extents);
                    }
                }
            });
        }

        var zoomExt;
        if (extents.length) {
            zoomExt = extents[0];
            extents.forEach(ext => {
                zoomExt = extendExtent(zoomExt, ext);
            });
        }
        if (zoomExt && zoomExt.length) this.getMap().getView().fit(zoomExt);
    }


    getSelectedFeatures() {

        var selected = {};
        this.selectableLayers.forEach(cfg => {
            selected[cfg.selectionCfg.layerId] = cfg.data;
        });
        return selected;
    }
}

/*<sld:StyledLayerDescriptor xmlns:sld="http://www.opengis.net/sld" version="1.0.0" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:ogc="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><sld:NamedLayer><sld:Name>5</sld:Name><sld:NamedStyle><sld:Name/></sld:NamedStyle><sld:UserStyle><sld:Name>style_sld_body</sld:Name><sld:FeatureTypeStyle><sld:Rule><ogc:Filter xmlns:ogc="http://www.opengis.net/ogc"><ogc:PropertyIsEqualTo><ogc:PropertyName>ID</ogc:PropertyName><ogc:Literal>39614817</ogc:Literal></ogc:PropertyIsEqualTo></ogc:Filter><sld:PolygonSymbolizer><sld:Stroke><sld:CssParameter name="stroke">#00FFFF</sld:CssParameter><sld:CssParameter name="stroke-opacity">1</sld:CssParameter><sld:CssParameter name="stroke-width">3</sld:CssParameter></sld:Stroke></sld:PolygonSymbolizer></sld:Rule></sld:FeatureTypeStyle></sld:UserStyle></sld:NamedLayer></sld:StyledLayerDescriptor>*/
//sld