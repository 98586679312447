// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {SelectionControl} from "./SelectionControl.js";
import {NotesControl} from "./NotesControl.js";
import {FileImportControl} from "./FileImportControl";
import {DrawPOIControl} from "./DrawPOIControl.js"
import {MeasureControl} from "./MeasureControl";
import {FeatureInfoControl} from "./FeatureInfoControl";
import {PrintControl} from "./PrintControl";
import {EditorControl} from "./EditorControl";
import {CadastralInfoControl} from "./CadastralInfoControl";
import {CadastralInfoWFSControl} from "./CadastralInfoWFSControl";
import {SelectionFromLayerControl} from "./SelectionFromLayerControl";
require('es6-object-assign').polyfill();

export class Toolbar  extends Control {

    constructor() {

        super({
            element: document.createElement('div'),
            target: null
        });

        const className = 'toolbar';
        this.element.className = className;
        this.controls=[];
        this.activeControl=null;
    }
    addControl(controlName,options) {
        var ctrl;
        var map=this.getMap();

        var opt=Object.assign({target:this.element,parentControl:this},options);

        switch(controlName) {
            case 'SelectionControl':
                ctrl=new SelectionControl(opt);
                break;
            case 'SelectionFromLayerControl':
                ctrl=new SelectionFromLayerControl(opt,map);
                break;
            case 'NotesControl':
                ctrl=new NotesControl(opt,map);
                break;
            case 'EditorControl':
                ctrl=new EditorControl(opt,map);
                break;
            case 'FileImportControl':
                ctrl=new FileImportControl(opt);
                break;
            case 'CadastralInfoControl':
                ctrl=new CadastralInfoControl(opt,map);
                break;
            case 'CadastralInfoWFSControl':
                ctrl=new CadastralInfoWFSControl(opt,map);
                break;
            case 'DrawPOIControl':
                ctrl=new DrawPOIControl(opt,map);
                break;
            case 'MeasureControl':
                ctrl=new MeasureControl(opt,map);
                break;
            case 'FeatureInfoControl':
                ctrl=new FeatureInfoControl(opt,map);
                break;
            case 'PrintControl':
                ctrl=new PrintControl(opt);
                break;
        }
        map.addControl(ctrl);
        this.controls.push(ctrl);
        return ctrl;
    }
    setActiveControl(ctrl) {

        if (this.activeControl) {
            this.activeControl.deactivate();
            this.unfocus();
        }
        this.activeControl=ctrl;
    }
    unsetActiveControl() {
       this.activeControl=undefined;
        this.unfocus();

    }
    unfocus() {

        if ("activeElement" in document) {
            document.activeElement.blur();
        }


    }
}