// @ts-nocheck

import {listen} from 'ol/events';
import Event from 'ol/events/Event';
import EventType from 'ol/events/EventType';
import {click} from 'ol/events/condition';
import {LayerUtils} from "./LayerUtils";
import ImageWMS from "ol/source/ImageWMS";
import ImageArcGISRest from 'ol/source/ImageArcGISRest.js';
import XYZ from 'ol/source/XYZ.js';

export class LegendControl {
    constructor(map, options) {
        this.map=map;
        const el=options.element.getElementsByClassName("panel");
        this.layerSwitcherPanel=el.length?el[0]:options.element;
        this.parentElement=options.element;
        this.layerUtils=new LayerUtils(this.map,{});
    }
    render() {
        if (this.parentElement.querySelector('.layers-legend-panel')) {
            return;
        }

        const layersButton = this.parentElement.querySelector('button');

        const actions = document.createElement('div');
        actions.className = 'legend-actions';

        const actionButtonsWrapper = document.createElement('div');
        actionButtonsWrapper.className = 'buttons-wrapper';

        this.legendButton = document.createElement('button');
        this.legendButton.className = 'layers-legend-toggle-button';

        this.tipLabel_open = 'Zobrazit legendu';
        this.tipLabel_close = 'Zobraziť mapové vrstvy';

        this.legendButton.title = this.tipLabel_open;

        this.legendButton.setAttribute('type', 'button');

        listen(this.legendButton, EventType.CLICK, this.btnDisplayClicked, this);

        actionButtonsWrapper.insertAdjacentElement('beforeend',  layersButton);
        actionButtonsWrapper.insertAdjacentElement('beforeend', this.legendButton);

        this.legendTitle=document.createElement('span');
        this.legendTitle.innerText="Legenda";
        this.legendTitle.className="legend-title hidden";

        this.layersSwitcherTitle=document.createElement('span');
        this.layersSwitcherTitle.innerText="Mapové vrstvy";
        this.layersSwitcherTitle.className="layers-switcher-title";

        actions.insertAdjacentElement('afterbegin', this.layersSwitcherTitle);
        actions.insertAdjacentElement('afterbegin', this.legendTitle);
        actions.insertAdjacentElement('beforeend',  actionButtonsWrapper);

        this.legendDiv=document.createElement('div');
        this.legendDiv.className="layers-legend-panel hidden";

        this.legendDiVisible=false;

        this.parentElement.insertBefore(this.legendDiv,this.parentElement.firstChild);
        this.parentElement.insertBefore(actions,this.parentElement.firstChild);
    }
    toggleLegendButton(s) {
        this.legendButton.className = s ? 'layers-legend-toggle-button' : 'layers-legend-toggle-button hidden';
    }
    toggleLegendPanel(s) {
        this.legendDiv.className = s ? 'layers-legend-panel' : 'layers-legend-panel hidden';
    }
    togglePanelClass(s, all = false) {
        this.parentElement.classList[s ? 'add' : 'remove']('legend-shown');
        this.legendTitle.classList[s ? 'remove' : 'add']('hidden');
        this.layersSwitcherTitle.classList[s ? 'add' : 'remove']('hidden');
    }
    hideTitles() {
        this.legendTitle.classList.add('hidden');
        this.layersSwitcherTitle.classList.add('hidden');
    }
    showSwitchLayersTitle() {
        this.layersSwitcherTitle.classList.remove('hidden');
    }
    btnDisplayClicked() {
        this.legendDiVisible=!this.legendDiVisible;

        if (this.legendDiVisible) {
            this.parentElement.classList.remove('shown');
            this.togglePanelClass(true);
            this.legendButton.classList.add('shown');

            this.legendButton.title = this.tipLabel_close;

            this.toggleLegendPanel(true);

            const layers=this.layerUtils.getAllLayers();
            const self=this;
            const legendUrls=[];
            const legendNames=[];

            layers.forEach(lyr=>{
                const a=lyr;
                const src=lyr.getSource();
                if(lyr.getVisible() && src) {
                    let url;
                    if (src instanceof ImageWMS) {
                        legendUrls.push(self.getWMSLegend(src.getLegendUrl(null,src.getParams())));
                        legendNames.push(lyr.getProperties().title);
                    }
                    if (src instanceof ImageArcGISRest) {
                         url=src.getUrl();
                        if (url) {
                            url=url+'/legend?size=10,10';
                            legendUrls.push(self.getEsriLegend(url));
                            legendNames.push(lyr.getProperties().title);
                        }
                    }
                    if (src instanceof XYZ) {

                        url=src.key_.replace("/tile/{z}/{y}/{x}","");

                        if (url && url.indexOf('arcgis')!== -1) {
                            url=url+'/legend?size=10,10';
                            legendUrls.push(self.getEsriLegend(url));
                            legendNames.push(lyr.getProperties().title);
                        }
                    }
                }

            });
            Promise.all(legendUrls).then(function (resp) {

               resp.forEach( (legend,i)=>{
                   if(legend) {
                       var parser = new DOMParser();
                       var doc = parser.parseFromString(legend.replace(/\<\/?b\>/gm,''), 'text/html');
                       //const htmlBody= doc.body;
                       const formTables = doc.getElementsByClassName('formTable');
                       let legendEl= formTables.length ? formTables[0] : "";
                       if (!legendEl) {
                           const legendEls=doc.getElementsByClassName('wms-legend');
                           legendEl= legendEls.length ? legendEls[0] : "";
                       }
                       const legendRowDiv = document.createElement('div');

                       const legendlayerName = document.createElement('span');
                       legendlayerName.className = "legend-layer-title";
                       legendlayerName.innerText = legendNames[i];
                       legendRowDiv.appendChild(legendlayerName);

                       legendRowDiv.className = "legend-layer";
                       legendRowDiv.appendChild(legendEl);
                       self.legendDiv.appendChild(legendRowDiv);
                   }
            });

            });
        }
        else {
            this.toggleLegendPanel(false);
            this.clearLegend();
            this.legendButton.title = this.tipLabel_open;
            this.togglePanelClass(false);
            this.parentElement.classList.add('shown');

            this.legendButton.classList.remove('shown');
        }
    }
    clearLegend() {
        while (this.legendDiv.firstChild) {
            this.legendDiv.removeChild(this.legendDiv.firstChild);
        }
    }
    getEsriLegend(url) {
        const promise=new Promise(function (resolve) {
            fetch(url).then( (response)=> {
                if (!response.ok) {
                    throw new Error('Legend esri error');
                }
                return response.text();
            }).then( (response)=> {
                resolve(response);
            }).catch((error) => {
                console.error( 'Legend esri error:' + error.message);
                resolve(null);
            });
        });

        return promise;
    }

    getWMSLegend(url) {
        const promise=new Promise(function (resolve) {
            let imgEl='<html><body>';
            imgEl+='<img class="wms-legend" src="'+url+'"/>';
            imgEl+='</body></html>';
            resolve(imgEl);
        });

        return promise;
    }
}
