// @ts-nocheck
import {Image as ImageLayer, Tile as TileLayer} from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';
import OSM from 'ol/source/OSM';
import Static from 'ol/source/ImageStatic';
import ImageArcGISRest from 'ol/source/ImageArcGISRest.js';
import TileArcGISRest from 'ol/source/TileArcGISRest.js';
import EsriJSON from 'ol/format/EsriJSON.js';
import { transform } from 'ol/proj';
import LayerGroup from 'ol/layer/Group';
import LayerImage from 'ol/layer/Image';
import TileGrid from 'ol/tilegrid/TileGrid.js';
//import SourceImageArcGISRest from 'ol/source/ImageArcGISRest';

//import SourceStamen from 'ol/source/Stamen';
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';

import WMTS, {optionsFromCapabilities} from 'ol/source/WMTS.js';
import VectorSource from "ol/source/Vector";
import XYZ from 'ol/source/XYZ.js';

import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";

//import Promise from 'promise-polyfill';
require('es6-promise').polyfill();
require('isomorphic-fetch');

export class LayerConfig {

    constructor(options) {
        this.basemapGroupName = "Podkladová mapa";
        this.overlayGroupName = "Mapové vrstvy";
        this.setDefaultLayers();
        this.useDefaultMapTree = false;
        this.defaultMapTree = [{
            "title": "Podkladová mapa",
            "type": "base",
            "fold": "close",
            "id": 0
        },
            {
                "title": "Mapové vrstvy",
                "id": 1,
                "fold": "close",

            }
        ];
        this.mapTree = {};
        if (options.mapTree) {
            this.mapTree = options.mapTree ;
        }
        else {
            this.useDefaultMapTree = true;
            this.mapTree = this.defaultMapTree;
        }
        if (options.snappingOptions) {
            options.snappingOptions.layers=[];
            options.snappingOptions.layersMapping={};
            this.snappingOptions=options.snappingOptions;

        }

        this.defaultExtent = options.extent;
        this.layerConfig = options.layers;
        this.mapProjection = options.mapProjection;
        this.hasSelectableLayers = false;
        this.grpLayerIds = [];
        this.layerGroups = [];
        this.dynamicLayers= [];
    }
    setDefaultLayers() {
        //  OSM map only
       this.basemaps=new LayerGroup( {
                title:this.basemapGroupName ,
                combine: false,
                layers:[new TileLayer({
                    type: "base",
                    title: 'OSM',
                    visible: true,
                    source: new OSM()
                })]});
       //empty overlays
        this.overlays=new LayerGroup( {title: this.overlayGroupName });
        this.layerGroups=[this.basemaps,this.overlays];

    }
    parseMapTree(treeNodes,parent){
        const self=this;
        for (let i = treeNodes.length - 1; i >= 0; i--) {
            const node=treeNodes[i];
            const grp=new LayerGroup({
                title:node.title,
                id:node.id,
                fold:node.fold
            })
            if(!parent) {
                self.layerGroups.push(grp);
            }
            else {
                parent.getLayers().push(grp);
            }
            if (node.treeNodes) {
                self.parseMapTree(node.treeNodes,grp);
            }
        }
    }

    getLayers() {


        this.parseMapTree(this.mapTree);

        var self=this;

        if(!this.layerConfig ) {
            //return default layers
           return  new Promise(function(resolve){
                resolve(self);
            });
        }
        this.layerConfig.sort((a,b)=>{
            var x=a.orderNo;
            var y=b.orderNo;
            return y-x;
        });
        var tasks=new Array();
        this.layerConfig.forEach(cfg=>{
            var lyr=this.parseSingleLayer(cfg);
            if(lyr) tasks.push(lyr);
        });

        var promise=new Promise(function(resolve){
            Promise.all(tasks).then(function(values) {

                values.forEach(r=> {
                    if (r && r.values_){
                        if(!self.useDefaultMapTree) {
                            if (r.values_.hasOwnProperty('layerGroupId') ) {
                                if(r.values_.isDynamicLayer) {
                                    self.dynamicLayers.push(layer);
                                }
                                else {
                                    self.addLayerToGroup(r.values_.layerGroupId, r);
                                }

                            }
                        }
                        else {
                            const grpId=r.values_.type === 'base'?0:1;
                            r.values_.layerGroupId=grpId;
                            if(r.values_.isDynamicLayer) {
                                self.dynamicLayers.push(layer);
                            }
                            else {
                                self.addLayerToGroup(r.values_.layerGroupId, r);
                            }
                        }


                    }
                });

               // self.sortLayers(self.layerGroups);

                //sort by orderNo
               /* basemaps.sort((a,b)=>{
                    var x=a.getProperties().orderNo;
                    var y=b.getProperties().orderNo;
                    return y-x;
                });
                overlays.sort((a,b)=>{
                    var x=a.getProperties().orderNo;
                    var y=b.getProperties().orderNo;
                    return y-x;
                });
              */
              /*  basemaps.forEach(b=>{
                   if (b.getProperties().combine){
                       b.value_.type="";
                   } ;
                });
                */


                resolve(self);
            });
        });
       return promise;
    }
    calculateResolution(scale){


        const ipm = 39.3700787;
        const dpi = 96;

        var mpu=this.mapProjection.getMetersPerUnit();
        return  scale / (mpu * ipm * dpi);
    }
    parseSingleLayer(layerConfig) {
        var layer=null;

        if(layerConfig.selectable) {
            this.hasSelectableLayers=true;
        }
        if (this.snappingOptions && layerConfig.snapping) {
            if(layerConfig.snappingLayerId !== void 0) {
                this.snappingOptions.layers.push(layerConfig.snappingLayerId);
                this.snappingOptions.layersMapping[layerConfig.layerId]=layerConfig.snappingLayerId;
            }
            else {
                this.snappingOptions.layers.push(layerConfig.layerId);
            }
        }
        var orderNo=layerConfig.orderNo;
        var isPrint=layerConfig.print;
        var lyrType=layerConfig.isBaselayer?"base":"";
        var minScale=layerConfig.minScale?layerConfig.minScale:1;
        var maxScale=layerConfig.maxScale?layerConfig.maxScale:5000000;

        var minResolution=this.calculateResolution(minScale);
        var maxResolution=this.calculateResolution(maxScale);

        switch (layerConfig.type) {
            case 'imageBase':
                layer= new ImageLayer({
                    type: "base",
                    isDynamicLayer:layerConfig.isDynamicLayer,
                    layerGroupId: layerConfig.layerGroupId,
                    orderNo:orderNo,
                    print:isPrint,
                    title:  layerConfig.name,
                    visible: layerConfig.visible,
                    source: new Static({
                        url: '',
                        projection:this.mapProjection,
                        imageExtent: this.defaultExtent,
                        crossOrigin: 'anonymous'
                    })
                });
                break;
            case 'osm':
                layer = new TileLayer({
                    type: "base",
                    isDynamicLayer:layerConfig.isDynamicLayer,
                    layerGroupId: layerConfig.layerGroupId,
                    orderNo:orderNo,
                    print:isPrint,
                    title:  layerConfig.name,
                    visible: layerConfig.visible,
                    minResolution:minResolution,
                    maxResolution:maxResolution,
                    source: new OSM({ crossOrigin: 'anonymous'})
                });
                break;
            case 'wmts':
                var parser = new WMTSCapabilities();
                var promise=new Promise(function(resolve) {
                    fetch(layerConfig.url).then(function (response) {
                        return response.text();
                    }).then(function (text) {
                       try {
                           var result = parser.read(text);

                           var options = optionsFromCapabilities(result, {
                               layer: layerConfig.layer,
                               matrixSet: layerConfig.matrixSet
                           });

                           options.attributions = [layerConfig.attribution];
                           options.crossOrigin = 'anonymous';
                           var lyr = new TileLayer({
                               isDynamicLayer: layerConfig.isDynamicLayer,
                               layerGroupId: layerConfig.layerGroupId,
                               type: lyrType,
                               baseLayer: layerConfig.isBaselayer,
                               orderNo: orderNo,
                               print: isPrint,
                               title: layerConfig.name,
                               visible: layerConfig.visible,
                               opacity: layerConfig.opacity ? layerConfig.opacity : 1,
                               minResolution: minResolution,
                               maxResolution: maxResolution,
                               source: new WMTS((options))
                           });
                       }
                       catch (error) {
                           console.info("WMTS error:  " + error);
                           resolve (null);
                       }
                         resolve(lyr);

                    },function(reject){
                        resolve(null);
                    });
                });
                layer=promise;
                break;
            case 'wms':
                var params=layerConfig.params;
                layer =  new ImageLayer({
                    type:lyrType,
                    isDynamicLayer:layerConfig.isDynamicLayer,
                    baseLayer:layerConfig.isBaselayer,
                    layerGroupId:layerConfig.layerGroupId,
                    orderNo:orderNo,
                    print:isPrint,
                    opacity:layerConfig.opacity?layerConfig.opacity:1,
                    visible: layerConfig.visible,
                    title:layerConfig.name,
                    selectable:layerConfig.selectable,
                    selectionCfg:layerConfig.selectionCfg,
                    featureInfoCfg:layerConfig.featureInfoCfg,
                    minResolution:minResolution,
                    maxResolution:maxResolution,
                    layerId:layerConfig.layerId,
                    refreshInterval:layerConfig.refreshInterval,
                   source:new ImageWMS({
                       crossOrigin: 'anonymous',
                       attributions:[layerConfig.attribution],
                       url:layerConfig.url,
                       params:params,
                       ratio:1
                   })
                });
                break;
            case 'wfs':
                layer= new VectorLayer({
                    print:isPrint,
                    orderNo:orderNo,
                    isDynamicLayer:layerConfig.isDynamicLayer,
                    opacity:layerConfig.opacity?layerConfig.opacity:1,
                    visible: layerConfig.visible,
                    title:layerConfig.name,
                    selectable:layerConfig.selectable,
                    selectionCfg:layerConfig.selectionCfg,
                    featureInfoCfg:layerConfig.featureInfoCfg,
                    minResolution:minResolution,
                    maxResolution:maxResolution,
                    layerId:layerConfig.layerId,
                    refreshInterval:layerConfig.refreshInterval,
                    layerGroupId: layerConfig.layerGroupId,
                    baseUrl:layerConfig.url,
                    source: new VectorSource ({
                        crossOrigin: 'anonymous',
                        attributions:[layerConfig.attribution],
                        format: new GeoJSON(),
                        url: function (extent) {
                            return (
                                layerConfig.url+ extent.join(',')+","+layerConfig.srsname
                            );
                        },

                        strategy: bboxStrategy
                    })/*,
                    style: new Style({
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 255, 1.0)',
                            width: 2,
                        }),
                    }),*/
                });
                break;
            case 'esriDynamic':
                var params=layerConfig.params;
                layer =  new  ImageLayer ({
                    type:lyrType,
                    isDynamicLayer:layerConfig.isDynamicLayer,
                    baseLayer:layerConfig.isBaselayer,
                    layerGroupId:layerConfig.layerGroupId,
                    orderNo:orderNo,
                    print:isPrint,
                    opacity:layerConfig.opacity?layerConfig.opacity:1,
                    visible: layerConfig.visible,
                    title:layerConfig.name,
                    selectable:layerConfig.selectable,
                    selectionCfg:layerConfig.selectionCfg,
                    featureInfoCfg:layerConfig.featureInfoCfg,
                    minResolution:minResolution,
                    maxResolution:maxResolution,
                    layerId:layerConfig.layerId,
                    refreshInterval:layerConfig.refreshInterval,
                    source:new ImageArcGISRest({
                        crossOrigin: 'anonymous',
                        attributions:[layerConfig.attribution],
                        url:layerConfig.url,
                        params:params,
                        ratio:1
                    })
                });
                break;
            case 'esriTile':
                var params=layerConfig.params;
               /* layer =  new TileLayer({
                    type:lyrType,
                    isDynamicLayer:layerConfig.isDynamicLayer,
                    baseLayer:layerConfig.isBaselayer,
                    layerGroupId:layerConfig.layerGroupId,
                    orderNo:orderNo,
                    print:isPrint,
                    opacity:layerConfig.opacity?layerConfig.opacity:1,
                    visible: layerConfig.visible,
                    title:layerConfig.name,
                    selectable:layerConfig.selectable,
                    selectionCfg:layerConfig.selectionCfg,
                    featureInfoCfg:layerConfig.featureInfoCfg,
                    minResolution:minResolution,
                    maxResolution:maxResolution,
                    layerId:layerConfig.layerId,
                    refreshInterval:layerConfig.refreshInterval,
                    source:new TileArcGISRest({
                        crossOrigin: 'anonymous',
                        attributions:[layerConfig.attribution],
                        url:layerConfig.url,
                        params:params,
                        ratio:1
                    })
                });*/
                var promise=new Promise(function(resolve) {
                    fetch(layerConfig.url+'/?f=json').then(function (response) {
                        return response.json();
                    }).then(function (serviceJson) {
                        try {
                            const extent = [
                                serviceJson.fullExtent.xmin,
                                serviceJson.fullExtent.ymin,
                                serviceJson.fullExtent.xmax,
                                serviceJson.fullExtent.ymax,
                            ];
                            const origin = [
                                serviceJson.tileInfo.origin.x,
                                serviceJson.tileInfo.origin.y,
                            ];
                            const resolutions = serviceJson.tileInfo.lods.map(function (l) {
                                return l.resolution;
                            });
                            const tileSize = [serviceJson.tileInfo.cols, serviceJson.tileInfo.rows];
                            const wkid = serviceJson.tileInfo.spatialReference.latestWkid;
                            const tileGrid = new TileGrid({
                                extent: extent,
                                origin: origin,
                                resolutions: resolutions,
                                tileSize: tileSize,
                            });

                            var lyr = new TileLayer({
                                type: lyrType,
                                isDynamicLayer: layerConfig.isDynamicLayer,
                                baseLayer: layerConfig.isBaselayer,
                                layerGroupId: layerConfig.layerGroupId,
                                orderNo: orderNo,
                                print: isPrint,
                                opacity: layerConfig.opacity ? layerConfig.opacity : 1,
                                visible: layerConfig.visible,
                                title: layerConfig.name,
                                selectable: layerConfig.selectable,
                                selectionCfg: layerConfig.selectionCfg,
                                featureInfoCfg: layerConfig.featureInfoCfg,
                                minResolution: minResolution,
                                maxResolution: maxResolution,
                                layerId: layerConfig.layerId,
                                refreshInterval: layerConfig.refreshInterval,
                                source: new XYZ({
                                    crossOrigin: 'anonymous',
                                    attributions: [layerConfig.attribution],
                                    url: layerConfig.url + '/tile/{z}/{y}/{x}',
                                    params: params,
                                    tileGrid: tileGrid,
                                    projection: 'EPSG:' + wkid
                                })
                            });
                        }
                        catch (error) {
                            console.info("esriTile error:  " + error);
                            resolve (null);
                        }
                        resolve(lyr);

                    },function(reject){
                        resolve(null);
                    });
                });
                layer=promise;

                break;
            case 'esriFeature':
                const vectorSrc=new VectorSource ({
                    crossOrigin: 'anonymous',
                    attributions:[layerConfig.attribution],
                    format: new EsriJSON(),
                    url: function (extent) {
                         const url = layerConfig.url ;
                        return url;
                    },
                    strategy: bboxStrategy
                });
                vectorSrc.srid=layerConfig.srsname.split(':').pop();
                vectorSrc.urlTemplate= layerConfig.url;
                vectorSrc.where="";
                const urlFc=function (extent) {
                let url =
                    vectorSrc.urlTemplate +
                    '/query/?f=json&returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
                    encodeURIComponent(
                        '{"xmin":' + extent[0] + ',"ymin":' + extent[1] + ',"xmax":' + extent[2] + ',"ymax":' + extent[3] + ',"spatialReference":{"wkid":' + vectorSrc.srid +
                        '}}'
                    ) + '&geometryType=esriGeometryEnvelope&inSR=' +   vectorSrc.srid + '&outFields=*' + '&outSR=' +   vectorSrc.srid;
                   if( vectorSrc.where) {
                       url+="&where="+vectorSrc.where;
                   }
                return url;
            }
            vectorSrc.setUrl(urlFc);
                layer= new VectorLayer({
                    print:isPrint,
                    orderNo:orderNo,
                    isDynamicLayer:layerConfig.isDynamicLayer,
                    opacity:layerConfig.opacity?layerConfig.opacity:1,
                    visible: layerConfig.visible,
                    title:layerConfig.name,
                    selectable:layerConfig.selectable,
                    selectionCfg:layerConfig.selectionCfg,
                    featureInfoCfg:layerConfig.featureInfoCfg,
                    minResolution:minResolution,
                    maxResolution:maxResolution,
                    layerId:layerConfig.layerId,
                    refreshInterval:layerConfig.refreshInterval,
                    layerGroupId: layerConfig.layerGroupId,
                    baseUrl:layerConfig.url,
                    source: vectorSrc,
                   /* style: new Style({
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 255, 1.0)',
                            width: 2,
                        }),
                    }),*/
                });
                break;

        }

        return layer;
    }
    createLayerGroup(groupId,layers) {
        const grpLayers=[];
        if (this.grpLayerIds.indexOf(groupId)!=-1) return;
        layers.forEach(l=>{
            if(l.values_.layerGroupId===groupId){
                grpLayers.push(l);
                this.grpLayerIds.push(groupId);
            }
        });
        return new LayerGroup( {
            title:grpLayers[0].getProperties().title ,
            type:"base",
            combine: true,
            layers:grpLayers});
    }

    addLayerToGroup(layerGroupId, layer) {
        this.addLayerByGroupById( this.layerGroups, layer);
    }
    addLayerByGroupById(groups, layer) {
        const self=this;
        for (var i=0;i<groups.length;i++){
            const grp=groups[i];
            if (grp instanceof LayerGroup) {
                if (grp.values_.id === layer.values_.layerGroupId) {
                    grp.getLayers().push(layer);
                }
                const layers=grp.getLayers();
                if(layers.getLength()){
                    self.addLayerByGroupById(layers.getArray(), layer);
                }
            }
        }

    }

}
