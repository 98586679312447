// @ts-nocheck

import { defaults as defaultControls, Control } from 'ol/control';
import { defaults as defaultInteractions, Draw, Modify, Snap, Select } from 'ol/interaction';

import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { listen } from 'ol/events';
import Event from 'ol/events/Event';
import EventType from 'ol/events/EventType';
import { CLASS_CONTROL, CLASS_UNSELECTABLE } from 'ol/css';
import { click } from 'ol/events/condition';
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from 'ol/style';
import MultiPoint from 'ol/geom/MultiPoint';
import WKT from 'ol/format/WKT';
//using old version with geometry.isValid() method
//@ts-ignore
import jsts from "jsts/dist/jsts.min.js";
import Popup from 'ol-popup';
import { extend, getCenter } from 'ol/extent';
import Point from "ol/geom/Point";
import { unByKey } from "ol/Observable";
import { SelectionEvent } from "./SelectionEvent";

var styles = {

    "default": [
        new Style({
            stroke: new Stroke({
                color: 'green',
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)'
            }),
            text: new Text({
                font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                placement: 'point',
                offsetX: 0,
                offsetY: 15,
                overflow: true,
                fill: new Fill({
                    color: 'green'
                })
            })

        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'green'
                }),
                text: new Text({
                    font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                    placement: 'point',
                    offsetX: 0,
                    offsetY: 15,
                    fill: new Fill({
                        color: 'green'
                    })
                })
            })
        })],
    "selected": [
        new Style({
            stroke: new Stroke({
                color: 'orange',
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)'
            })
        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'orange'
                })
            }),
            geometry: function (feature) {

                var g = feature.getGeometry();

                if (g.getType() == 'Point') {
                    return g;
                }

                var coordinates;
                var c = g.getCoordinates();
                if (g.getType() == 'LineString') {
                    coordinates = c;
                }
                else {
                    coordinates = c[0];
                }
                return new MultiPoint(coordinates);
            }
        })]
};

export class EditorControl extends Control {

    constructor(options, map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });
        this.parentControl = options.parentControl;

        this.drawTypeMappings = { 'point': 'Point', 'line': 'LineString', 'polygon': 'Polygon' };

        this.geometryToolTypes = ["point", "line", "polygon"]
        this.featureRemovedHnd = undefined;
        this.featureAddedHnd = undefined;
        this.editSingleGeometry = false;
        this.singleGeomType = undefined;


        this.drawLayer = new VectorLayer({
            name: "editDrawLayer",
            //always print
            print: true,
            source: new VectorSource({ wrapX: false, crossOrigin: 'Anonymous' }),
            style: function (feature) {
                var st = styles["default"];
                st.forEach(s => {
                    var txtStyle = s.getText();
                    if (txtStyle) {
                        var txt = feature.getProperties()['description'];
                        txtStyle.setText(txt);

                        if (feature.getGeometry().getType() == 'LineString') {
                            txtStyle.setPlacement('line');
                        }
                        else {
                            txtStyle.setPlacement('point');
                        }
                    }
                });
                return st;
            }

        });
        map.addLayer(this.drawLayer);

        this.element.className = 'editor-control';
        this.toolDivs = {};
        var self = this;

        if (options.editor.editTypes && options.editor.editTypes.length) {
            var editTypes = options.editor.editTypes.concat(['modify', 'delete']);
            editTypes.forEach(nt => {
                switch (nt) {
                    case 'point':
                        this.createTool(nt, 'Nakresliť bod');
                        break;
                    case 'line':
                        this.createTool(nt, 'Nakresliť líniu');
                        break;
                    case 'polygon':
                        this.createTool(nt, 'Nakresliť polygón');
                        break;
                    case 'modify':
                        this.createTool(nt, 'Upraviť nákres');
                        break;
                    case 'delete':
                        this.createTool(nt, 'Vymazať', 'D');
                        break;
                }
            }, this);
        }

    }

    stopEditing(callback) {

        const wkt = new WKT();
        const features = this.drawLayer.getSource().getFeatures().map((f) => wkt.writeFeature(f))

        let result;
        if (this.editSingleGeometry) {
            result = features && features.length ? features[0] : null
        } else {
            result = result;
        }

        this.hideToolbar();
        this.clearDrawLayer();
        this.clearSelection();
        callback(result);
    }

    getFeaturesCount() {
        var feats = this.drawLayer.getSource().getFeatures();
        return feats && feats.length ? feats.length : 0;
    }

    resetEditor() {
        this.removeMapInterections();
        if (this.editorEndHnd) unByKey(this.editorEndHnd);
        if (this.editorErrHnd) unByKey(this.editorErrHnd);
        if (this.featureRemovedHnd) {
            unByKey(this.featureRemovedHnd)
        }
        if (this.featureAddedHnd) {
            unByKey(this.featureAddedHnd)
        }
        this.editSingleGeometry = false;
        this.singleGeomType = undefined;
        this.showToolbar();
        this.clearDrawLayer();
        this.clearSelection();
    }

    startEditing(data, callback) {
        this.editorStarted = true;
        const strWKT = data.wktGeometry;
        const geomType = data.geometryType;
        const wkt = new WKT();
        const self = this;
        this.resetEditor();

        if (strWKT || geomType) {
            if (callback) {
                this.editorEndHnd = this.once('editorend', function (evt) {
                    const feats = this.drawLayer.getSource().getFeatures();
                    let feat;
                    if (feats && feats.length) feat = wkt.writeFeature(feats[0]);
                    callback({ success: true, feature: feat });
                    this.editorStarted = false;
                    self.deactivate();
                });
                this.editorErrHnd = this.once('editorerr', function (evt) {
                    callback({ success: false, feature: null });
                    this.editorStarted = false;
                    self.deactivate();
                });
            }
        }
        if (strWKT) {
            try {
                const wktFeature = wkt.readFeature(strWKT);
                this.drawLayer.getSource().clear();
                this.drawLayer.getSource().addFeature(wktFeature);
                var view = this.getMap().getView();
                view.fit(wktFeature.getGeometry().getExtent());
                this.btnToolClicked({}, 'modify');
                if (this.select) {
                    this.select.getFeatures().push(wktFeature);
                    this.select.dispatchEvent({
                        type: 'select',
                        selected: [wktFeature],
                        deselected: []
                    });
                }

            }
            catch (ex) {
                self.dispatchEvent(new Event('editorerr'));
                console.log("EditorControl: error parse wkt geometry.");
            }
        }
        else {
            if (geomType && !data.editSingleGeometry) {
                this.drawLayer.getSource().clear();
                this.btnToolClicked({}, geomType);
                this.drawLayer.getSource().once('addfeature', function (evt) {
                    self.dispatchEvent(new Event('editorend'));
                });

            }
        }

        if (data.editSingleGeometry) {
            this.initSingleGeometryEdit(data.geometryType)
        }
    }

    initSingleGeometryEdit(geometryType) {
        this.editSingleGeometry = true;
        this.showSingleEditButton(geometryType)

        this.setSingleGeometryButtonState(geometryType)

        if (this.getFeaturesCount() === 0) {
            this.btnToolClicked({}, geometryType)
        }

        this.featureRemovedHnd = this.drawLayer.getSource().on('removefeature', (e) => {
            this.setSingleGeometryButtonState(geometryType)
        })

        this.featureAddedHnd = this.drawLayer.getSource().on('addfeature', (e) => {
            this.setSingleGeometryButtonState(geometryType);
            setTimeout(() => this.btnToolClicked({}, geometryType), 0);
        })
    }

    setSingleGeometryButtonState(geometryType) {
        if (this.getFeaturesCount() === 0) {
            this.enableButton(geometryType);
        } else {
            this.disableButton(geometryType);
        }
    }

    showSingleEditButton(toolType) {
        this.geometryToolTypes.forEach((t) => {
            if (t === toolType) {
                this.showButton(t)
            } else {
                this.hideButton(t)
            }
        })

    }

    hideToolbar() {
        this.element.classList.add("hidden")
    }

    showToolbar() {
        this.element.classList.remove("hidden")
    }

    hideButton(toolType) {
        this.addButtonClass(toolType, "hidden")
    }

    showButton(toolType) {
        this.removeButtonClass(toolType, "hidden")
    }

    enableButton(toolType) {
        this.removeButtonClass(toolType, "disabled")
    }

    disableButton(toolType) {
        const btn = this.addButtonClass(toolType, "disabled")
        if (btn && btn.firstChild) {
            btn.firstChild.blur()
        }
    }

    removeButtonClass(toolType, cls) {
        const btn = this.toolDivs[toolType]
        if (btn) {
            btn.classList.remove(cls);
        }
    }

    addButtonClass(toolType, cls) {
        const btn = this.toolDivs[toolType]
        if (btn) {
            btn.classList.add(cls);
        }
        return btn;
    }

    getToolButtonClassList(toolType) {
        var className = 'editor-control-tool' + ' ' + 'editor-control-' + toolType;
        return className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
    }

    createTool(toolType, tooltip) {

        var div = document.createElement('div');
        this.toolDivs[toolType] = div;
        var button = document.createElement('button');

        var tipLabel = tooltip;
        button.setAttribute('type', 'button');
        button.title = tipLabel;
        /*button.appendChild(
            typeof label === 'string' ? document.createTextNode(label) : label
        );*/

        listen(button, EventType.CLICK, function (evt) {
            this.btnToolClicked(evt, toolType);
        }, this
        );

        div.className = this.getToolButtonClassList(toolType);
        div.appendChild(button);
        this.element.appendChild(div);
    }

    btnToolClicked(evt, toolType) {


        if (this.activeTool == toolType) {
            this.deactivate();
            return;
        }
        this.deactivateTool();
        var map = this.getMap();
        var self = this;
        if (self.popup) self.popup.hide();
        if (!this.drawLayerAdded) {
            // map.addLayer(this.drawLayer);
            //add popup
            //this.popup = new Popup();
            // map.addOverlay(this.popup);


            this.drawLayerAdded = true;
        }
        /* if(!this.addFeatureEvtHndKey) {
 
             this.addFeatureEvtHndKey = this.drawLayer.getSource().on('addfeature', function (evt) {
                 self.featureCreated(evt);
             });
 
         }*/
        var src = this.drawLayer.getSource();


        this.removeMapInterections();

        this.activeTool = toolType;
        this.activate();
        this.addMapInteraction(toolType, src);
        if (window.snapControl) {
            window.snapControl.activate();
        }

    }

    clearDrawLayer() {
        if (this.drawLayer) {
            this.drawLayer.getSource().clear();
        }
    }

    clearSelection() {
        if (this.select) {
            this.select.getFeatures().clear();
        }
    }

    addMapInteraction(toolType, source) {

        var map = this.getMap();

        var self = this;
        var drawType = this.drawTypeMappings[toolType];
        if (drawType) {
            this.draw = new Draw({
                source: source,
                style: styles["selected"],
                type: drawType
            });
            this.draw.on('drawstart', function (evt) {
                // if (self.popup) self.popup.hide();
            });

            map.addInteraction(this.draw);
        }
        if (toolType == 'delete') {
            this.select = new Select({
                condition: click,
                hitTolerance: 2,
                layers: [this.drawLayer],
                style: styles["selected"],
                wrapX: false
            });

            this.select.on('select', function (evt) {
                evt.selected.forEach(f => {
                    source.removeFeature(f);
                    evt.target.getFeatures().remove(f);
                });

            });
            map.addInteraction(this.select);
        }
        if (toolType == 'modify') {

            this.select = new Select({
                condition: click,
                hitTolerance: 2,
                layers: [this.drawLayer],
                style: styles["selected"],
                wrapX: false
            });

            this.select.on('select', function (evt) {

                if (evt.selected.length == 0) {
                    if (self.modify) {
                        map.removeInteraction(self.modify);
                        self.modify = null;
                        // if (self.popup) self.popup.hide();
                        self.dispatchEvent(new Event('editorend'));
                        return;
                    }

                }
                //self.select.setActive(false);

                self.modify = new Modify({
                    //source: source,
                    style: styles["selected"],
                    features: self.select.getFeatures()
                });
                self.modify.on('modifystart', function (evt) {
                    var f = evt.features.getArray()[0];
                    if (f.getGeometry().getType() == 'Polygon') {
                        self.modifiedFeature = f.clone();
                    }

                });
                self.modify.on('modifyend', function (evt) {
                    var f = evt.features.getArray()[0];
                    if (f.getGeometry().getType() == 'Polygon') {

                        if (!self.isGeomValid(f)) {
                            f.setGeometry(self.modifiedFeature.getGeometry());
                            //evt.stopPropagation();
                        }
                    }
                    // self.select.setActive(true);
                });
                /*evt.selected.forEach(f => {
                    self.createPopup(f);
                });*/
                // this.snap = new Snap({source: source});
                map.addInteraction(self.modify);
                // map.addInteraction( this.snap);
            });
            map.addInteraction(this.select);

        }


    }

    removeMapInterections() {

        var map = this.getMap();
        if (this.draw) {
            map.removeInteraction(this.draw);
            this.draw = null;
        }
        if (this.select) {
            map.removeInteraction(this.select);
            this.select = null;
        }
        if (this.modify) {
            map.removeInteraction(this.modify);
            this.modify = null;
        }
        if (this.snap) {
            map.removeInteraction(this.snap);
            this.snap = null;
        }
    }

    isGeomValid(feature) {

        if (feature.getGeometry().getType() != 'Polygon') return true;
        var wkt = new WKT();
        var jstsReader = new jsts.io.WKTReader();
        var wktFeature = wkt.writeFeature(feature);
        var geom = jstsReader.read(wktFeature);

        if (!geom.isValid()) {
            console.log("EditorControl:geometry is not valid!");
            alert('Nakreslená geometria nie je validná!');
            return false;
        }
        return true;

    }

    featureCreated(evt) {
        var self = this;
        if (!this.isGeomValid(evt.feature)) {
            self.drawLayer.getSource().removeFeature(evt.feature);
            return;
        }
        //editor bez popupu
        //this.createPopup(evt.feature);
    }

    createPopup(feature) {
        var ext = feature.getGeometry().getExtent();
        var p = getCenter(ext);
        if (feature.getGeometry().getType() == 'Point') {
            p = [p[0] + 2, p[1] + 2];
        }
        var desc = feature.getProperties()['description'];
        desc = desc ? desc : "";
        var self = this;
        var el = document.createElement("div");
        el.title = 'Zadajte poznámku. Pre ukončenie stlačte Enter.';
        var txtDesc = document.createElement("input");
        txtDesc.type = "text";
        txtDesc.id = feature.ol_uid;
        txtDesc.maxLength = "500";
        txtDesc.placeholder = "Zadajte poznámku...";
        //txtDesc.oninput=self.writeDescription;
        listen(txtDesc, 'input', self.writeDescription, self);
        //txtDesc.onkeypress=self.popupKeyPress;
        listen(txtDesc, EventType.KEYPRESS, self.popupKeyPress, self);
        txtDesc.value = desc;

        el.appendChild(txtDesc);

        this.popup.show(p, el);
        txtDesc.focus();
    }

    writeDescription(evt, hidePopup) {

        var self = this;
        var elem = evt.target;
        var features = self.drawLayer.getSource().getFeatures();
        features.forEach(f => {
            if (f.ol_uid == elem.id) {
                f.setProperties({ 'description': elem.value });
            }
        });
        if (hidePopup) self.popup.hide();
        self.drawLayer.getSource().refresh();
    };

    popupKeyPress(evt) {
        var self = this;
        if (evt.keyCode == 13) {
            evt.preventDefault();
            self.writeDescription(evt, true);
            return false;
        }
    };

    featureChanged(evt) {

        return

    }

    activate() {
        if (this.parentControl) {
            if (!(this.parentControl.activeControl instanceof EditorControl)) {
                this.parentControl.setActiveControl(this);
            }
        }

        var div = this.toolDivs[this.activeTool];

        if (div) {
            div.classList.add("active");
        }


    }

    deactivate() {

        this.removeMapInterections();
        if (this.addFeatureEvtHndKey) {
            unByKey(this.addFeatureEvtHndKey);
            this.addFeatureEvtHndKey = null;
        }

        //this.drawLayer.getSource().clear();

        if (this.popup) this.popup.hide();
        this.deactivateTool();
        if (this.editorStarted) {
            this.dispatchEvent(new Event('editorerr'));
        }

        if (window.snapControl) {
            window.snapControl.deactivate();
        }

        if (this.parentControl)
            if ((this.parentControl.activeControl instanceof EditorControl)) {
                this.parentControl.unsetActiveControl();
            }

    }
    deactivateTool() {
        var div = this.toolDivs[this.activeTool];

        if (div) {
            div.classList.remove("active");
        }
        this.activeTool = null;
    }
    getWKTFeatures() {

        var wkt = new WKT();
        var wktFeatures = {
            points: [],
            polylines: [],
            polygons: [],
            extent: null
        };
        var ext;



        if (ext) {
            this.getMap().getView().fit(ext);
        }
        var ext;
        var features = this.drawLayer.getSource().getFeatures();
        features.forEach(f => {
            //extent
            if (!ext) {
                ext = f.getGeometry().getExtent();
            }
            else {
                ext = extend(ext, f.getGeometry().getExtent());
            }

            var gType = f.getGeometry().getType();
            var desc = f.get("description");
            desc = desc ? desc : "";
            var wktF = { description: desc, wkt: wkt.writeFeature(f) };

            switch (gType) {

                case "Point":
                    wktFeatures.points.push(wktF);
                    break;
                case "LineString":
                    wktFeatures.polylines.push(wktF);
                    break;
                case "Polygon":
                    wktFeatures.polygons.push(wktF);
                    break;
            }
        });
        wktFeatures.extent = ext;

        return wktFeatures;
    }
}