// @ts-nocheck
import {Control} from 'ol/control';

import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';


export class PrintControl extends Control {

    constructor(options) {

        super({
            element: document.createElement('div'),
            target: options.target
        });

        this.options = options;
        this.isActive = false;

        const className = options.className !== undefined ? options.className : 'print-control';
        const button = document.createElement('button');

        const tipLabel = 'Export mapy';
        button.setAttribute('type', 'button');
        button.title = tipLabel;

        listen(button, EventType.CLICK, this.toolBtnClicked, this);
        const cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
        const element = this.element;
        element.className = cssClasses;

        element.appendChild(button);

        this.inputDiv = document.createElement('div');
        this.inputDiv.className = 'printcontrol-inputdiv hidden';

        var closeBtn = document.createElement('span');
        closeBtn.title = 'Zatvoriť';
        listen(closeBtn, EventType.CLICK, this.closeDiv, this);

        closeBtn.appendChild(document.createTextNode(''));
        closeBtn.className = 'printcontrol-inputdiv-close';
        this.inputDiv.appendChild(closeBtn);
        var desc = document.createElement('div');
        desc.className = 'printcontrol-inputdiv-desc';
        desc.innerHTML = 'Typ exportu';
        this.inputDiv.appendChild(desc);

        var exportTypeInput = document.createElement('input');
        exportTypeInput.setAttribute('type', 'checkbox');
        exportTypeInput.setAttribute('id', 'printExportTypeInput');
        exportTypeInput.className = 'printcontrol-input';
        listen(exportTypeInput, EventType.CHANGE, this.setExportType, this);
        this.inputDiv.appendChild(exportTypeInput);

        var exportTypeInputLbl = document.createElement('label');
        exportTypeInputLbl.className = 'printcontrol-label';
        exportTypeInputLbl.for = 'printExportTypeInput';
        exportTypeInputLbl.innerHTML = 'Exportovať referencovaný obrázok';
        this.inputDiv.appendChild(exportTypeInputLbl);
        var exportBtn = document.createElement('input');
        var self = this;

        var lineBreak = document.createElement('br');
        this.inputDiv.appendChild(lineBreak);

        if (this.options.print.dpi && this.options.print.dpi.length > 1) {
            var dpiLabel = document.createElement('label');
            dpiLabel.className = 'printcontrol-label';
            dpiLabel.for = 'printDpiSelect';
            dpiLabel.innerHTML = 'DPI';
            this.inputDiv.appendChild(dpiLabel);

            var dpiSelect = document.createElement('select');
            dpiSelect.setAttribute('id', 'printDpiSelect');
            dpiSelect.className = 'printcontrol-select';
            listen(dpiSelect, EventType.CHANGE, self.setDpi, self);
            var defaultDpi = this.options.print.defaultDPI
            this.options.print.dpi.forEach(dpi => {
                var opt = document.createElement('option');
                opt.text = dpi;
                opt.value = dpi;
                if (defaultDpi == dpi) opt.selected = true;
                dpiSelect.appendChild(opt);
            });
            self.inputDiv.appendChild(dpiSelect);
            this.currentDPI = this.options.print.defaultDPI;
        }

        var dpiLabel = document.createElement('label');
        dpiLabel.className = 'printcontrol-label';
        dpiLabel.for = 'printFormatSelect';
        dpiLabel.innerHTML = 'Format';
        this.inputDiv.appendChild(dpiLabel);

        var formatSelect = document.createElement('select');
        formatSelect.setAttribute('id', 'printFormatSelect');
        formatSelect.className = 'printcontrol-select';
        listen(formatSelect, EventType.CHANGE, self.setFormat, self);
        var formats = ["png", "jpeg"]
        formats.forEach(format => {
            var opt = document.createElement('option');
            opt.text = format;
            opt.value = format;
            formatSelect.appendChild(opt);
        });
        self.inputDiv.appendChild(formatSelect);

        exportBtn.setAttribute('type', 'button');
        exportBtn.className = 'printcontrol-export-button';
        exportBtn.title = 'Exportovať mapu';
        exportBtn.value = 'Exportovať mapu';
        this.inputDiv.appendChild(exportBtn);
        listen(exportBtn, EventType.CLICK, this.doExport, this);

        element.appendChild(this.inputDiv);
        this.exportReferencedImage = false;

        this.imageFormat = "png";
    }

    toolBtnClicked(evt) {

        this.isActive = !this.isActive;

        if (this.isActive) {
            this.activate();
        } else {
            this.deactivate();
        }
    }

    activate() {
        this.inputDiv.className = 'printcontrol-inputdiv';
        if (this.parentControl) this.parentControl.setActiveControl(this);
    }

    deactivate() {
        this.isActive = false;
        this.inputDiv.className = 'fileimportcontrol-inputdiv hidden';

        if (this.parentControl) this.parentControl.unsetActiveControl();

    }

    closeDiv() {
        this.deactivate();
    }

    setExportType(evt) {
        this.exportReferencedImage = evt.target.checked;
    }

    setDpi(evt) {
        this.currentDPI = evt.target.value;
    }

    setFormat(evt) {
        this.imageFormat = evt.target.value;
    }

    doExport(evt) {
        if (!this.mapExportControl) {
            console.error("PrintControl: export control not set!");
            return;
        }
        const self = this;
        const size = this.getMap().getSize();
        const origExtent = this.getMap().getView().calculateExtent(size);
        if (this.exportReferencedImage) {
            this.mapExportControl.exportMapAsZip(null, {
                dpi: this.currentDPI,
                format: this.imageFormat
            }).then(function (image) {

                var url = URL.createObjectURL(image);
                var a = document.createElement('a');
                a.download = "map.zip";
                a.href = url;
                a.click();
                self.getMap().setSize(size);
                self.getMap().getView().fit(origExtent, {size: size});
                self.getMap().renderSync();
            });
        } else {
            this.mapExportControl.exportMap(null, {
                dpi: this.currentDPI,
                format: this.imageFormat
            }).then(function (image) {

                let imgExt = 'png';
                if (self.imageFormat === "jpeg" && (!navigator.msSaveBlob)) {
                    imgExt = 'jpg';
                }
                var url = URL.createObjectURL(image);
                var a = document.createElement('a');
                a.download = "map." + imgExt;
                a.href = url;
                a.click();
                self.getMap().setSize(size);
                self.getMap().getView().fit(origExtent, {size: size});
                self.getMap().renderSync();
            });
        }
    }

    setExportControl(mapExport) {
        this.mapExportControl = mapExport;
    }
}
