// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {listen} from "ol/events";
import EventType from "ol/events/EventType";
import {KML, GeoJSON, GPX, WFS} from 'ol/format';
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";
import ImageArcGISRest from 'ol/source/ImageArcGISRest.js';

import {extend} from 'ol/extent';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from "ol/style";
import Point from "ol/geom/Point";
import LayerGroup from "ol/layer/Group";
import {unByKey} from "ol/Observable";
import ImageWMS from "ol/source/ImageWMS";
import {SelectionEvent} from "./SelectionEvent";
import Overlay from 'ol/Overlay';
import {getCenter} from 'ol/extent';
import EsriJSON from 'ol/format/EsriJSON';
import {FeatureInfoEvent} from "./FeatureInfoEvent";
import {fromExtent} from "ol/geom/Polygon";
import {intersects} from "ol/format/filter";



export class FeatureInfoFromCoordinateControl extends Control {
    constructor(map,options) {

        super({
            element: null,
            target: options.target
        });

        this.parentControl = options.parentControl;
        this.options = options;
        this.featureInfoFromCoordinateControlOptions=null;
        if (this.options.featureInfoFromCoordinateControlOptions && this.options.featureInfoFromCoordinateControlOptions.infoConfig) {
            this.featureInfoFromCoordinateControlOptions=this.options.featureInfoFromCoordinateControlOptions.infoConfig;
        }
        this.map=map;
        this.selectableLayers = [];
    }
    setInfoLayers() {

        this.infoLayers = [];
        const map = this.map;

        const lyrs = map.getLayers();
        const self = this;
        let setLayers = function (layer) {
            const props = layer.getProperties();

                let layerIds= self.getInfoFromCoordinateCfg.map(x => x.layerId);

                if (props && props.layerId) {
                    if ( layerIds.indexOf(props.layerId)!=-1) {
                        let infoFromCoordinateCfg={};
                        const fCfg=self.getInfoFromCoordinateCfg.filter(x=>{return x.layerId==props.layerId});
                        if(fCfg.length) infoFromCoordinateCfg=fCfg[0];
                        const cfg = props.featureInfoCfg;
                        self.infoLayers.push({
                            layer: layer,
                            featureInfoCfg: cfg,
                            getInfoFromCoordinateCfg:infoFromCoordinateCfg
                        });
                    }
                }

        }
        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    setLayers(lyr);
                }
            });
        }
        getLayers(lyrs);

    }


    createRequests(coordinate) {

        const map = this.map;
        const view = map.getView();
        //var viewResolution = view.getResolution();
        //  var prj = view.getProjection().getCode();
        let sLayers = this.infoLayers;
        let tasks = [];
        let self = this;

        sLayers.forEach(infoCfg => {

            const src = infoCfg.layer.getSource();
            const res = view.getResolution();
            var filter;
                if(src instanceof ImageWMS) {
                    filter=src.getParams().cql_filter;
                    tasks.push(this.getInfoFromWMS(src,infoCfg,coordinate,filter));
                }
                if(src instanceof VectorSource) {
                    if (src.getFormat()  instanceof EsriJSON) {
                        tasks.push(this.getInfoFromEsriFS(src, infoCfg, coordinate));
                    }
                    else
                    {
                        tasks.push(this.getInfoFromWFS(src, infoCfg, coordinate));
                    }
                }
                if(src instanceof ImageArcGISRest) {
                    filter=src.getParams().layerDefs;
                    tasks.push(this.getInfoFromEsriRest(src,infoCfg,coordinate,filter));
                }

        });

        Promise.all(tasks).then(function (resp) {
                let features = [].concat.apply([], resp);
                self.parseFeatures(features);
            });

    }
    getInfoFromEsriRest(src,infoCfg,coordinate,filter) {
        const self=this;
        const map =this.map;
        let infoFeatures=[];
        const curCfg=infoCfg;
        const ext = [coordinate[0] - 500, coordinate[1] - 500, coordinate[0] + 500, coordinate[1] + 500];
        const layerDefs=filter||"";
        return new Promise(function (resolve) {
            const urlParams= {
                geometryType:'esriGeometryPoint',
                geometry:coordinate.join(),
                outFields:'*',
                tolerance:5,
                layers:'all',
                returnGeometry:false,
                mapExtent: ext.join(),
                imageDisplay:map.getSize().join()+',96',
                layerDefs:layerDefs,
                f:'json'
            }


            const iUrl = new URL(src.url_+'/identify');
            iUrl.search = new URLSearchParams(urlParams);

            //url = src.getGetFeatureInfoUrl(evt.coordinate, viewResolution,
            //    prj, infoCfg.featureInfoCfg.wmsParms);

            fetch(iUrl).then(function (response) {
                return response.text();
            }).then(function (response) {
                let features = [];
                let results;
                try {
                    let json = JSON.parse(response);
                    results=json.results;
                    //features = new GeoJSON().readFeatures(json);

                }
                catch (e) {
                    console.error("FeatureInfoControl: error parse response.");
                    resolve(infoFeatures);
                }
                const idx = self.infoLayers.findIndex(cfg => {
                    return curCfg.layer.ol_uid == cfg.layer.ol_uid;
                });

                if (results.length) {
                    features = new EsriJSON().readFeatures(results);
                }
                results.forEach(r => {
                    //filter att?
                    let feat = new EsriJSON().readFeatures(r)[0];
                    feat.infoLayerId = idx;
                    feat.subLayerId=r.layerId;
                    infoFeatures.push(feat);
                });
                resolve(infoFeatures);
            });
        });
    }

    createClickFilterBody(coord,wfsOpt) {

        let extInt;
        const buffer = 5;
        const ext = [coord[0] - buffer, coord[1] - buffer, coord[0] + buffer, coord[1] + buffer];
        extInt = fromExtent(ext);

        var qFilter=intersects(wfsOpt.geometryField,extInt,wfsOpt.srsName);
     ;


        var featureRequest = new WFS().writeGetFeature({
            srsName: wfsOpt.srsName,
            featureNS:wfsOpt.featureNS,
            featurePrefix:wfsOpt.featurePrefix,
            featureTypes: wfsOpt.featureTypes,
            outputFormat: wfsOpt.outputFormat,
            filter: qFilter
        });

        var body= new XMLSerializer().serializeToString(featureRequest);

        return body;

    }
    sendWFSRequest(params){

        const self=this;
        var promise= new Promise(function (resolve, reject) {

            var config = {
                method: 'POST',
                body: params.data,
                mode: 'cors',
            };

            fetch(params.url, config)
                .then(function (r) {
                    return r.json(); })
                .then(resolve)
                .catch(reject);

        });

        return promise;

    }
    getInfoFromWFS(src, infoCfg, coordinate) {
        const self=this;
        let infoFeatures=[];
        const curCfg=infoCfg;
        const body=this.createClickFilterBody(coordinate, infoCfg.getInfoFromCoordinateCfg.wfsOptions);
        let url=infoCfg.layer.getProperties().baseUrl;
        url=url.replace(/&request=.*/,"");
        var params={url:url,data:body};

        return new Promise(function (resolve) {

            self.sendWFSRequest(params).then(function (results) {
                var features = new GeoJSON().readFeatures(results);
                const idx = self.infoLayers.findIndex(cfg => {
                    return curCfg.layer.ol_uid == cfg.layer.ol_uid;
                });
                features.forEach(feature => {
                    //filter att?
                    feature.infoLayerId = idx;
                    infoFeatures.push(feature);
                });
                resolve(infoFeatures);
            });
        });


    }
    getInfoFromWMS(src,infoCfg,coordinate,filter) {
        const self=this;
        let url;
        const map = this.map;
        const view = map.getView();
        const viewResolution = view.getResolution();
        const prj = view.getProjection().getCode();
        let infoFeatures=[];
        const curCfg=infoCfg;
        const cql_filter=filter||"";
        return new Promise(function (resolve) {
            url = src.getFeatureInfoUrl(coordinate, viewResolution,
                prj, infoCfg.featureInfoCfg.wmsParms);
            if (cql_filter) { url=url+ "&cql_filter="+cql_filter }
            fetch(url).then(function (response) {
                return response.text();
            }).then(function (response) {
                let features = [];
                try {
                    let json = JSON.parse(response);
                    features = json.features; //new GeoJSON().readFeatures(json);
                }
                catch (e) {
                    console.error("FeatureInfoControl: error parse response.");
                    resolve(infoFeatures);
                }
                const idx = self.infoLayers.findIndex(cfg => {
                    return curCfg.layer.ol_uid == cfg.layer.ol_uid;
                });
                features.forEach(f => {
                    const feature = new GeoJSON().readFeature(f);
                    //filter att?
                    feature.infoLayerId = idx;
                    if (f.layerName !== void 0) {
                        feature.subLayerId=feature.f;
                    }
                    else {
                        if (feature.getId &&  feature.getId() !== void 0) {
                            feature.subLayerId=feature.getId().split('.')[0];
                        }
                    }
                    infoFeatures.push(feature);
                });
                resolve(infoFeatures);
            });
        });
    }
    sendEsriRequest(obj){
        const self=this;
        var promise= new Promise(function (resolve, reject) {

            var config = {
                method: 'GET',
                mode: 'cors',
            };

            fetch(obj, config)
                .then(function (r) {
                    return r.json(); })
                .then(resolve)
                .catch(reject);

        });

        return promise;

    }
    getInfoFromEsriFS(src, infoCfg, coordinate) {

        const self=this;
        const url=infoCfg.layer.getProperties().baseUrl;
        return new Promise(function (resolve) {
            const urlParams= {
                geometryType:'esriGeometryPoint',
                geometry:coordinate.join(),
                spatialRel:'esriSpatialRelIntersects',
                outFields:'*',
                returnGeometry:false,
                f:'json'
            }

            const fUrl = new URL(url+'/query');
            fUrl.search = new URLSearchParams(urlParams);


            self.sendEsriRequest(fUrl).then(function (results) {
                const idx = self.infoLayers.findIndex(cfg => {
                    return infoCfg.layer.ol_uid == cfg.layer.ol_uid;
                });
                var features = new EsriJSON ().readFeatures(results);
                const respFeatures=[];
                features.forEach(feature => {
                    feature.infoLayerId = idx;
                    respFeatures.push(feature);
                });
                resolve(respFeatures);
            });
        });
    }

    mergeConfig(infoConfig) {
        if (this.featureInfoFromCoordinateControlOptions) {
            infoConfig.forEach(cfg=> {
                this.featureInfoFromCoordinateControlOptions.forEach(mainCfg=> {
                    if(cfg.layerId===mainCfg.layerId) {
                        cfg.wfsOptions=mainCfg.wfsOptions;
                    }

                });
            });
            return infoConfig;
        }
        else {
            return infoConfig;
        }
    }

    getFeatureInfo (data,callback){

        this.callback=null;
        this.callback=callback;
        this.getInfoFromCoordinateCfg= this.mergeConfig(data.infoConfig);
        this.setInfoLayers();
        this.createRequests (data.coordinate);

    }
    parseFeatures(features) {

        const self=this;
        const outputFeatures={};
        features.forEach (f=> {

            const lyrCfg=self.infoLayers[f.infoLayerId];
            const layerId=lyrCfg.layer.getProperties().layerId;
            const cfgs=self.getInfoFromCoordinateCfg.filter(x=> { return x.layerId==layerId});
            if (cfgs.length) {
                const outputFeature={};
                const cfg=cfgs[0];
                const props=f.getProperties();

                if (cfg.subLayers) {
                    cfg.subLayers.forEach(sub=> {
                        const subLayerId=sub.id;
                        if ( subLayerId===f.subLayerId) {
                            if (sub.attributes.indexOf('*')==-1) {
                            sub.attributes.forEach(atr => {
                                outputFeature[atr] = props[atr];
                                outputFeature["subLayerId"] = subLayerId;

                            });
                        }
                        else {
                                Object.assign(outputFeature, props);
                                outputFeature["subLayerId"] = subLayerId;
                                delete outputFeature.geometry;
                            }
                        }
                    });
                }
                else {
                    if (cfg.attributes.indexOf('*')==-1) {
                        cfg.attributes.forEach(atr => {
                            outputFeature[atr] = props[atr];

                        });
                    }
                    else {
                        Object.assign(outputFeature, props);
                        delete outputFeature.geometry;
                    }
                }
                if(!outputFeatures[layerId]) outputFeatures[layerId]=[];

               if(Object.keys(outputFeature).length != 0) outputFeatures[layerId].push(outputFeature);
            }

        });
        //this.dispatchEvent(new FeatureInfoEvent('getinfofromcoordinate',outputFeatures));
        if(this.callback) this.callback(outputFeatures);

    }

}