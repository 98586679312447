// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {defaults as defaultInteractions, Draw, Modify, Snap, Select} from 'ol/interaction';

import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {click} from 'ol/events/condition';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';
import MultiPoint from 'ol/geom/MultiPoint';
import WKT from 'ol/format/WKT';
//using old version with geometry.isValid() method
//@ts-ignore
import jsts from "jsts/dist/jsts.min.js";
import Popup from 'ol-popup';
import {extend, getCenter} from 'ol/extent';
import Point from "ol/geom/Point";
import {unByKey} from "ol/Observable";

var styles = {

    "default": [
        new Style({
            stroke: new Stroke({
                color: 'blue',
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)'
            }),
            text: new Text({
                font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                placement: 'point',
                offsetX :0,
                offsetY:15,
                overflow: true,
                fill: new Fill({
                    color: 'blue'
                })
            })

        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'blue'
                }),
                text: new Text({
                    font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                    placement: 'point',
                    offsetX :0,
                    offsetY:15,
                    fill: new Fill({
                        color: 'blue'
                    })
                })
            })
        })],
    "selected": [
        new Style({
            stroke: new Stroke({
                color: 'orange',
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0.1)'
            })
        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'orange'
                })
            }),
            geometry: function (feature) {

                var g = feature.getGeometry();

                if (g.getType() == 'Point') {
                    return g;
                }

                var coordinates;
                var c = g.getCoordinates();
                if (g.getType() == 'LineString') {
                    coordinates = c;
                }
                else {
                    coordinates = c[0];
                }
                return new MultiPoint(coordinates);
            }
        })]
};

export class NotesControl extends Control {

    constructor(options,map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });
        this.parentControl = options.parentControl;

        this.drawTypeMappings = {'point': 'Point', 'line': 'LineString', 'polygon': 'Polygon'};


        this.drawLayer = new VectorLayer({
            name:"notesDrawLayer",
            //always print
            print:true,
            source: new VectorSource({wrapX: false,crossOrigin: 'Anonymous'}),
            style: function (feature) {
                var st = styles["default"];
                st.forEach(s => {
                    var txtStyle = s.getText();
                    if (txtStyle) {
                        var txt = feature.getProperties()['description'];
                        txtStyle.setText(txt);

                        if(feature.getGeometry().getType()=='LineString') {
                            txtStyle.setPlacement('line');
                        }
                        else {
                            txtStyle.setPlacement('point');
                        }
                    }
                });
                return st;
            }

        });
        map.addLayer(this.drawLayer);
        this.element.className = 'notes-control';
        this.toolDivs={};
        var self = this;

        if (options.noteTypes && options.noteTypes.length) {
            var noteTypes = options.noteTypes.concat(['modify', 'delete']);
            noteTypes.forEach(nt => {
                switch (nt) {
                    case 'point':
                        this.createTool(nt, 'Nakresliť bod');
                        break;
                    case 'line':
                        this.createTool(nt, 'Nakresliť líniu');
                        break;
                    case 'polygon':
                        this.createTool(nt, 'Nakresliť polygón');
                        break;
                    case 'modify':
                        this.createTool(nt, 'Upraviť nákres');
                        break;
                    case 'delete':
                        this.createTool(nt, 'Vymazať', 'D');
                        break;
                }
            }, this);
        }
    }

    createTool(toolType, tooltip) {

        var div = document.createElement('div');
        this.toolDivs[toolType]=div;
        var toolClass = 'notes-control-tool';
        var className = toolClass + ' ' + 'notes-control-' + toolType;
        var button = document.createElement('button');

        var tipLabel = tooltip;
        button.setAttribute('type', 'button');
        button.title = tipLabel;
        /*button.appendChild(
            typeof label === 'string' ? document.createTextNode(label) : label
        );*/

        listen(button, EventType.CLICK, function (evt) {
                this.btnToolClicked(evt, toolType);
            }, this
        );
        var cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;

        div.className = cssClasses;
        div.appendChild(button);
        this.element.appendChild(div);
    }

    btnToolClicked(evt, toolType) {


        if(this.activeTool==toolType){
            this.deactivate();
            return;
        }
        this.deactivateTool();
        var map = this.getMap();
        var self = this;
        if (self.popup) self.popup.hide();
        if (!this.drawLayerAdded) {
           // map.addLayer(this.drawLayer);
            //add popup
            this.popup = new Popup();
            map.addOverlay(this.popup);


            this.drawLayerAdded = true;
        }
       if(!this.addFeatureEvtHndKey) {

           this.addFeatureEvtHndKey = this.drawLayer.getSource().on('addfeature', function (evt) {
               self.featureCreated(evt);
           });
           /*  this.drawLayer.getSource().on('changefeature',function(evt) {
                // self.featureChanged(evt);
             });*/
       }
        var src = this.drawLayer.getSource();


        this.removeMapInterections();

        this.activeTool=toolType;
        this.activate();
        this.addMapInteraction(toolType, src);


    }

    addMapInteraction(toolType, source) {

        var map = this.getMap();

        var self = this;
        var drawType = this.drawTypeMappings[toolType];
        if (drawType) {
            this.draw = new Draw({
                source: source,
                type: drawType
            });
            this.draw.on('drawstart', function (evt) {
                if (self.popup) self.popup.hide();
            });

            map.addInteraction(this.draw);
        }
        if (toolType == 'delete') {
            this.select = new Select({
                condition: click,
                layers:[this.drawLayer],
                hitTolerance: 2,
                style: styles["selected"],
                wrapX: false
            });

            this.select.on('select', function (evt) {
                evt.selected.forEach(f => {
                    source.removeFeature(f);
                    evt.target.getFeatures().remove(f);
                });

            });
            map.addInteraction(this.select);
        }
        if (toolType == 'modify') {
            this.select = new Select({
                condition: click,
                hitTolerance: 2,
                layers:[this.drawLayer],
                style: styles["selected"],
                wrapX: false
            });

            this.select.on('select', function (evt) {

                if (evt.selected.length == 0) {
                    if (self.modify) {
                        map.removeInteraction(self.modify);
                        self.modify = null;
                        if (self.popup) self.popup.hide();
                        return;
                    }
                }
                //self.select.setActive(false);

                self.modify = new Modify({
                    //source: source,
                    features: self.select.getFeatures()
                });
                self.modify.on('modifystart', function (evt) {
                    var f = evt.features.getArray()[0];
                    if (f.getGeometry().getType() == 'Polygon') {
                        self.modifiedFeature = f.clone();
                    }

                });
                self.modify.on('modifyend', function (evt) {
                    var f = evt.features.getArray()[0];
                    if (f.getGeometry().getType() == 'Polygon') {

                        if (!self.isGeomValid(f)) {
                            f.setGeometry(self.modifiedFeature.getGeometry());
                            //evt.stopPropagation();
                        }
                    }
                   // self.select.setActive(true);
                });
                evt.selected.forEach(f => {
                    self.createPopup(f);
                });
                // this.snap = new Snap({source: source});
                map.addInteraction(self.modify);
                // map.addInteraction( this.snap);
            });
            map.addInteraction(this.select);

        }


    }

    removeMapInterections() {

        var map = this.getMap();
        if (this.draw) {
            map.removeInteraction(this.draw);
            this.draw = null;
        }
        if (this.select) {
            map.removeInteraction(this.select);
            this.select = null;
        }
        if (this.modify) {
            map.removeInteraction(this.modify);
            this.modify = null;
        }
        if (this.snap) {
            map.removeInteraction(this.snap);
            this.snap = null;
        }
    }

    isGeomValid(feature) {

        if (feature.getGeometry().getType() != 'Polygon') return true;
        var wkt = new WKT();
        var jstsReader = new jsts.io.WKTReader();
        var wktFeature = wkt.writeFeature(feature);
        var geom = jstsReader.read(wktFeature);

        if (!geom.isValid()) {
            alert('Nakreslená geometria nie je validná!');

            return false;
        }
        return true;

    }

    featureCreated(evt) {
        //debugger

        var self = this;
        if (!this.isGeomValid(evt.feature)) {
            self.drawLayer.getSource().removeFeature(evt.feature);
            return;
        }
        this.createPopup(evt.feature);
    }

    createPopup(feature) {
        var ext = feature.getGeometry().getExtent();
        var p = getCenter(ext);
        if (feature.getGeometry().getType() == 'Point') {
            p = [p[0] + 2, p[1] + 2];
        }
        var desc = feature.getProperties()['description'];
        desc = desc ? desc : "";
        var self = this;
        var el = document.createElement("div");
        el.title = 'Zadajte poznámku. Pre ukončenie stlačte Enter.';
        var txtDesc = document.createElement("input");
        txtDesc.type = "text";
        txtDesc.id = feature.ol_uid;
        txtDesc.maxLength = "500";
        txtDesc.placeholder = "Zadajte poznámku...";
        //txtDesc.oninput=self.writeDescription;
        listen(txtDesc, 'input', self.writeDescription, self);
        //txtDesc.onkeypress=self.popupKeyPress;
        listen(txtDesc, EventType.KEYPRESS, self.popupKeyPress, self);
        txtDesc.value = desc;

        el.appendChild(txtDesc);

        this.popup.show(p, el);
        txtDesc.focus();
    }

    writeDescription(evt, hidePopup) {

        var self = this;
        var elem = evt.target;
        var features = self.drawLayer.getSource().getFeatures();
        features.forEach(f => {
            if (f.ol_uid == elem.id) {
                f.setProperties({'description': elem.value});
            }
        });
        if (hidePopup) self.popup.hide();
        self.drawLayer.getSource().refresh();
    };

    popupKeyPress(evt) {
        var self = this;
        if (evt.keyCode == 13) {
            evt.preventDefault();
            self.writeDescription(evt, true);
            return false;
        }
    };

    featureChanged(evt) {

        return
        if (!this.isGeomValid(evt.feature)) {

            return
        }

    }

    activate() {
        if (this.parentControl) {
            if (!(this.parentControl.activeControl instanceof NotesControl)) {
                this.parentControl.setActiveControl(this);
            }
        }

        var div=this.toolDivs[this.activeTool];

        if (div) {
            div.classList.add("active");
        }


    }

    deactivate() {

        this.removeMapInterections();
        if (this.addFeatureEvtHndKey) {
            unByKey(this.addFeatureEvtHndKey);
            this.addFeatureEvtHndKey=null;
       }
        if (this.popup) this.popup.hide();
        this.deactivateTool();
        if (this.parentControl)
            if ((this.parentControl.activeControl instanceof NotesControl)) {
                this.parentControl.unsetActiveControl();
            }

    }
    deactivateTool() {
        var div=this.toolDivs[this.activeTool];

        if (div) {
            div.classList.remove("active");
        }
        this.activeTool=null;
    }
    getWKTFeatures() {

        var wkt = new WKT();
        var wktFeatures = {
            points: [],
            polylines: [],
            polygons: [],
            extent:null
        };
        var ext;



        if (ext) {
            this.getMap().getView().fit(ext);
        }
        var ext;
        var features = this.drawLayer.getSource().getFeatures();
        features.forEach(f => {
            //extent
            if (!ext) {
                ext = f.getGeometry().getExtent();
            }
            else {
                ext = extend(ext, f.getGeometry().getExtent());
            }

            var gType = f.getGeometry().getType();
            var desc = f.get("description");
            desc = desc ? desc : "";
            var wktF = {description: desc, wkt: wkt.writeFeature(f)};

            switch (gType) {

                case "Point":
                    wktFeatures.points.push(wktF);
                    break;
                case "LineString":
                    wktFeatures.polylines.push(wktF);
                    break;
                case "Polygon":
                    wktFeatures.polygons.push(wktF);
                    break;
            }
        });
        wktFeatures.extent=ext;

        return wktFeatures;
    }
}