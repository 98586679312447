// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {SelectionEvent} from './SelectionEvent';
import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import ImageWMS from 'ol/source/ImageWMS';

import GeoJSON from 'ol/format/GeoJSON';
import EsriJSON from 'ol/format/EsriJSON.js';
import LayerGroup from 'ol/layer/Group';
import {Image as ImageLayer, Vector as VectorLayer} from "ol/layer";
import {extend as extendExtent} from 'ol/extent';
import {unByKey} from "ol/Observable";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import {Vector as VectorSource} from "ol/source";
import {or as orFilter, and as andFilter, intersects, equalTo as equalToFilter} from "ol/format/filter";
import {bbox as bboxStrategy} from "ol/loadingstrategy";
import {WFS} from "ol/format";
import Point from "ol/geom/Point";
import {fromExtent} from 'ol/geom/Polygon';
require('es6-promise').polyfill();
import {DragBox} from 'ol/interaction.js';
import {NotesControl} from "./NotesControl";
import ImageArcGISRest from 'ol/source/ImageArcGISRest.js';



export class SelectionFromLayerControl extends Control {


    constructor(options,map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });
        this.map=map;
        this.layerTypes=["WFS","geoserverGroupLayer","esri"];
        this.parentControl = options.parentControl;
        this.isButtonVisible = true;
        if (options.config.selectionFromLayerControl) {
            this.isButtonVisible = options.config.selectionFromLayerControl.isButtonVisible ? true : false;
        }
        this.tools =["click","rectangle","clear"];
        if (options.config.selectionFromLayerControl.tools) {
            this.tools = options.config.selectionFromLayerControl.tools;
        }
        this.pixelTollerance=1;
        if (options.config.selectionFromLayerControl.pixelTollerance) {
            this.pixelTollerance = options.config.selectionFromLayerControl.pixelTollerance;
        }
        this.selectionMethod="new";
        if (options.config.selectionFromLayerControl.selectionMethod) {
            this.selectionMethod = options.config.selectionFromLayerControl.selectionMethod;
        }

        this.options = options;
        this.isActive = false;
        this.activeTool="";
        this.selectableLayers = [];
        this.selectedFeatures=[];


        this.eventKey;
        this.toolDivs={};
        var div;
        var button;
        let label;
        let tipLabel;
        let cssClasses;
        let  element = this.element;
        element.className = 'selection-control';

        if(this.tools.indexOf("click")!=-1) {
            div = document.createElement('div');
            this.toolDivs['click'] = div;
            button = document.createElement('button');
            label = "S";
            tipLabel = 'Vybrať objekt';
            button.setAttribute('type', 'button');
            button.title = tipLabel;
            listen(button, EventType.CLICK, function (evt) {
                this.btnToolClicked(evt, 'click')
            }, this);
             cssClasses = 'selection-control-tool' + ' ' + 'selection-control-click' + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;

            div.className = cssClasses;
            div.appendChild(button);
            element.appendChild(div);
            element.appendChild(div);
        }

        if(this.tools.indexOf("rectangle")!=-1) {
            div = document.createElement('div');
            this.toolDivs['rectangle'] = div;

            button = document.createElement('button');
            label = "R";
            tipLabel = 'Vybrať objekt obdĺžnikom';
            button.setAttribute('type', 'button');
            button.title = tipLabel;


            listen(button, EventType.CLICK, function (evt) {
                this.btnToolClicked(evt, 'rectangle');
            }, this);
            cssClasses = 'selection-control-tool' + ' ' + 'selection-control-rectangle' + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
            div.className = cssClasses;
            div.appendChild(button);
            element.appendChild(div);
            element.appendChild(div);
        }

        if(this.tools.indexOf("clear")!=-1) {
            div = document.createElement('div');
            this.toolDivs['clear'] = div;

            button = document.createElement('button');
            label = "C";
            tipLabel = 'Zrušiť výber';
            button.setAttribute('type', 'button');
            button.title = tipLabel;


            listen(button, EventType.CLICK, function (evt) {
                this.btnToolClicked(evt, 'clear');
            }, this);
            cssClasses = 'selection-control-tool' + ' ' + 'selection-control-clear' + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
            div.className = cssClasses;
            div.appendChild(button);
            element.appendChild(div);
            element.appendChild(div);
        }

        if (this.isButtonVisible == false) {
            this.setVisibility(false);
        }
        this.selectionLayer = new VectorLayer({
            name:"SelectionFromLayerControlLayer",
            //always print
            print:true,
            source: new VectorSource({wrapX: false,crossOrigin: 'Anonymous'}),
            style:  new Style({
                stroke: new Stroke({
                    color: 'cyan',
                    width: 3
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 255, 0)'
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'cyan',
                    }),
                })
            })
        });

        map.addLayer(this.selectionLayer);
        this.dragBox = null;
        this.initialize();
    }
  /*  handleDrawRectangle() {
        this.activeTool='rectangle';
        this.isActive = !this.isActive;
        if (this.isActive) {
            this.activate();
        }
        else {
            this.deactivate();
        }


    }*/

    getLayerFilter(lyr) {
        var src = lyr.getSource();
        var layerFilter;
        if (src instanceof ImageWMS) {
            layerFilter=src.getParams().cql_filter;
        }
        if (src instanceof ImageArcGISRest) {
            layerFilter=src.getParams().layerDefs;
        }
        return layerFilter;
    }
    setSelectableLayers() {

        this.selectableLayers = [];
        var map = this.map;

        var lyrs = map.getLayers();
        var self = this;
        var isSupported= function (cfg) {
            let val=true;
            if (cfg.layers ) {
                cfg.layers.forEach (selectionCfg=>{
                    if (self.layerTypes.indexOf(selectionCfg.serviceType)==-1) val=false
                });
            }
            else {
                return false;
            }
            return val;
        }
        var setSelectLayers = function (layer) {
            var props = layer.getProperties();


            if (props && props.selectable &&
                props.selectionCfg && isSupported(props.selectionCfg)) {
                var cfg = props.selectionCfg ;
                self.selectableLayers.push({
                    layer: layer,
                    selectionCfg: cfg,
                    layerId:props.layerId,
                    data: []
                });
            }
        }


        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    setSelectLayers(lyr);
                }
            });
        }
        getLayers(lyrs);
    }

    initialize() {
        if (this.initialized) return;
        this.setSelectableLayers();
        this.initialized = true;
    }

    btnToolClicked(evt, toolType) {
        if(this.activeTool==toolType){
            this.deactivate();
            return;
        }
        this.removeHnd();
        this.deactivateTool();
        this.activeTool=toolType;

        var map = this.getMap();
        var self = this;
        if (this.options.selectionMethod === 'click') {
            if(self.activeTool === 'click') {
                this.mapClickHandlerKey = map.on('singleclick', function (evt) {
                    self.mapClickHandler(evt, self, null)
                });
            }
            if (self.activeTool === 'rectangle'){
                this.dragBox = new DragBox({});
                this.map.addInteraction(this.dragBox);
                const self=this;
                this.mapBboxEndHandlerKey = this.dragBox.on('boxend', function () {
                    const boxExtent = self.dragBox.getGeometry().getExtent();
                    self.mapClickHandler({target:self.map}, self, boxExtent)

                });

                this.mapBboxStartHandlerKey = this.dragBox.on('boxstart', function () {
                    // selectedFeatures.clear();
                });
            }
        }
        if (self.activeTool === 'clear') {
           this.clearSelection(true);
            this.deactivateTool();
            this.activeTool="";
        }
        this.activate();

    }
    deactivateTool() {
        var div = this.toolDivs[this.activeTool];

        if (this.dragBox) {
            this.map.removeInteraction(this.dragBox);
            this.dragBox = null;
         }
        if (div) {
            div.classList.remove("active");
        }
        this.activeTool=null;
    }
   /* handleClick_(event) {

        event.preventDefault();
        this.isActive = !this.isActive;
        this.activeTool='click';
        if (this.isActive) {
            this.activate();
        }
        else {
            this.deactivate();
        }

    }*/

   /* tryActivate() {

        if (!this.isActive) {
            this.activate();
        }

    }
*/
    setVisibility(visible) {
        if (this.isButtonVisible) return;
        if (visible) {
            if (!this.isActive) {
                this.activate();
            }
            this.element.style.display = "";
        }
        else {
            if (this.isActive) {
                this.deactivate();
            }
            this.element.style.display = "none";
        }
    }

    activate() {
        this.isActive = true;
        if (this.parentControl) {
            if (!(this.parentControl.activeControl instanceof SelectionFromLayerControl)) {
                this.parentControl.setActiveControl(this);
            }
        }
        var div=this.toolDivs[this.activeTool];
        if (div) {
            div.classList.add("active");
        }




    }
    removeHnd(){
        if (this.mapClickHandlerKey) {
            unByKey(this.mapClickHandlerKey);
            this.mapClickHandlerKey=null;
        }
        if (this.mapBboxEndHandlerKey) {
            unByKey(this.mapBboxEndHandlerKey);
            this.mapBboxEndHandlerKey=null;
        }
        if (this.mapBboxStartHandlerKey) {
            unByKey(this.mapBboxStartHandlerKey);
            this.mapBboxStartHandlerKey=null;
        }
    }
    deactivate() {
        this.isActive = false;
        this.removeHnd();
        this.deactivateTool();
        if (this.parentControl)
            if ((this.parentControl.activeControl instanceof SelectionFromLayerControl)) {
                this.parentControl.unsetActiveControl();
            }

    }
    createFilterFromCqlFilter(qFilter,cqlFilter) {
        var finalFilter;
        var eq;
        var andFilters=[qFilter];
        var orFilters=[];
        cqlFilter=cqlFilter.replace(/\s\s+/g, ' ');
        var flt=cqlFilter.split(' ');
        if (flt.length==3) {
        if (flt[1]==='eq') {
                eq = equalToFilter(flt[0], flt[2]);
                andFilters.push(eq);
         }
         if (flt[1]==='in') {
            var inArr=flt[2].replace('(','').replace(')','').replace(' ','').split(',');
            if (inArr.length===1) {
                eq = equalToFilter(flt[0], inArr[0]);
                andFilters.push(eq);
            }
            else {
                inArr.forEach(id => {
                    eq = equalToFilter(flt[0], id);
                    orFilters.push(eq);
                });

                var orFil = orFilter.apply(null, orFilters);
                andFilters.push(orFil);
            }
         }
         }
        if(andFilters.length>1) {
            finalFilter=andFilter.apply(null,andFilters);
        }

        return finalFilter;
    }

    createClickFilterBody(evtGeom,wfsOpt,resolution,cqlFilter) {

        let extInt;
        if(evtGeom.type==="point") {
            const coord=evtGeom.geom;
            const buffer = this.pixelTollerance * resolution;
            const ext = [coord[0] - buffer, coord[1] - buffer, coord[0] + buffer, coord[1] + buffer];
            extInt = fromExtent(ext);
        }
        if(evtGeom.type==="bbox") {
            extInt=evtGeom.geom;
        }
        var qFilter=intersects(wfsOpt.geometryField,extInt,wfsOpt.srsName);
        var  rFil=qFilter;
        if (cqlFilter) {

            rFil=this.createFilterFromCqlFilter(qFilter,cqlFilter);
        }

        var featureRequest = new WFS().writeGetFeature({
            srsName: wfsOpt.srsName,
            featureNS:wfsOpt.featureNS,
            featurePrefix:wfsOpt.featurePrefix,
            featureTypes: wfsOpt.featureTypes,
            outputFormat: wfsOpt.outputFormat,
            filter: rFil
        });

        var body= new XMLSerializer().serializeToString(featureRequest);

        return body;

    }
    sendWFSRequest(obj){
        const self=this;
        var promise= new Promise(function (resolve, reject) {

            var config = {
                method: 'POST',
                body: obj.data,
                mode: 'cors',
            };
            config=self.enableSecurity(config);
//"enableSecurity":true,
            fetch(obj.url, config)
                .then(function (r) {
                    return r.json(); })
                .then(resolve)
                .catch(reject);

        });

        return promise;

    }
    sendEsriRequest(obj){
        const self=this;
        var promise= new Promise(function (resolve, reject) {

            var config = {
                method: 'GET',
                mode: 'cors',
            };
            config=self.enableSecurity(config);
//"enableSecurity":true,
            fetch(obj, config)
                .then(function (r) {
                    return r.json(); })
                .then(resolve)
                .catch(reject);

        });

        return promise;

    }
    enableSecurity(opt) {

        if(this.options.config.selectionFromLayerControl.enableSecurity) {
            //token
            let token = null;
            const map=this.getMap();
            if (map.securityTokenCallback) {
                token = map.securityTokenCallback();
            }
            const options=  {
                ...opt,
                mode: 'cors',
                headers: {
                    'Authorization':'Bearer '+token
                }
            }
            return options
        }
        else {
            return opt;
        }
    }
    getStyle(selectionColor){
        return new Style({
            stroke: new Stroke({
                color: selectionColor,
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(0, 0, 255, 0)'
            }),
            image: new CircleStyle({
                radius: 7,
                fill: new Fill({
                    color: selectionColor,
                }),
            })
        });
    }
    createClickFilter(vectorSrc,url,evtGeom,layerId,subLayerId,wfsOptions,selectionColor,fieldsDef,resolution,layerFilter) {
        if(!vectorSrc &&  !url) return;
        let promise;
        const respFeatures=[];
        if(!selectionColor) selectionColor="cyan";
        const self=this;

        if(vectorSrc) {

             promise=new Promise(function (resolve) {
                 let features;
                 if(vectorSrc instanceof ImageArcGISRest) {

                     let ext;
                     if (evtGeom.type === 'point') {
                         const buffer = self.pixelTollerance * resolution;
                         ext = [evtGeom.geom[0] - buffer, evtGeom.geom[1] - buffer,
                             evtGeom.geom[0] + buffer, evtGeom.geom[1] + buffer];
                     }
                     if (evtGeom.type === 'bbox') {
                         ext=evtGeom.geom;
                     }


                     const urlParams = {
                         geometryType: 'esriGeometryEnvelope',
                         geometry: ext.join(),
                         outFields: '*',
                         returnGeometry: true,
                         f: 'json'
                     }
                     if(layerFilter) {
                        // subLayerId
                         //{"layerId":22,"filter":"{\"1\":\"OBJECTID=2041\",\"2\":\"BPEJ='0001001' and OBJECTID>500\"}"}
                         const filter=JSON.parse(layerFilter);
                         const where=filter[subLayerId];
                         urlParams.where=where;
                     }

                     const iUrl = new URL(url + '/query');
                     iUrl.search = new URLSearchParams(urlParams);

                     fetch(iUrl).then(function (response) {
                         return response.json();
                     }).then(function (response) {
                         features = new EsriJSON().readFeatures(response);
                         features.forEach(feature => {
                             const newFeat = feature.clone();
                             newFeat.layerId = layerId;
                             newFeat.subLayerId = subLayerId;
                             newFeat.fieldsDef = fieldsDef;
                             newFeat.setStyle(self.getStyle(selectionColor));
                             respFeatures.push(newFeat);
                         });
                         resolve(respFeatures);
                     });

                 }
                 else {

                     if (evtGeom.type === 'point') {
                         const buffer = self.pixelTollerance * resolution;
                         const ext = [evtGeom.geom[0] - buffer, evtGeom.geom[1] - buffer,
                             evtGeom.geom[0] + buffer, evtGeom.geom[1] + buffer];
                         features = vectorSrc.getFeaturesInExtent(ext);
                     }
                     if (evtGeom.type === 'bbox') {
                         features = vectorSrc.getFeaturesInExtent(evtGeom.geom);
                     }
                     features.forEach(feature => {
                         const newFeat = feature.clone();
                         newFeat.layerId = layerId;
                         newFeat.subLayerId = subLayerId;
                         newFeat.fieldsDef = fieldsDef;
                         newFeat.setStyle(self.getStyle(selectionColor));
                         respFeatures.push(newFeat);
                     });
                     resolve(respFeatures);
                 }

            });
        }
        else {

            var body=this.createClickFilterBody(evtGeom,wfsOptions,resolution,layerFilter);
            var params={url:url,data:body};

            promise=new Promise(function (resolve) {
                self.sendWFSRequest(params).then(function (results) {
                    var features = new GeoJSON().readFeatures(results);
                    features.forEach(feature => {
                        feature.layerId = layerId;
                        feature.subLayerId=subLayerId;
                        feature.fieldsDef=fieldsDef;
                        feature.setStyle(self.getStyle(selectionColor));
                        respFeatures.push(feature);
                    });
                    resolve(respFeatures);
                });
            });
        }
        return promise;
    }
    createAttributeFilterBody(features,wfsOpt,fieldsDef) {
        const orFilters=[];
        if(features.length===0) return;
        features.forEach(feat=>{

            const andFilters=[];
            for (var key in feat) {
                let fldAlias=key;
                if(fieldsDef && fieldsDef.fields) {
                    const fld = fieldsDef.fields.filter(f => f.name === key).shift();
                    if (fld) fldAlias = fld.alias;
                }
                const eq=equalToFilter(fldAlias, feat[key]);
                andFilters.push(eq);
            }
            if(andFilters.length>1) {
                orFilters.push(andFilter.apply(null,andFilters));
            }
            else {
                orFilters.push(andFilters[0]);
            }
        });
        let fil;
        if(orFilters.length>1) {
            orFilters.forEach(orF=>{

            });
            fil=orFilter.apply(null,orFilters);
        }
        else {
            fil=orFilters[0];
        }

        var featureRequest = new WFS().writeGetFeature({
            srsName: wfsOpt.srsName,
            featureNS:wfsOpt.featureNS,
            featurePrefix:wfsOpt.featurePrefix,
            featureTypes: wfsOpt.featureTypes,
            outputFormat: wfsOpt.outputFormat,
            filter: fil
        });

        var body= new XMLSerializer().serializeToString(featureRequest);
        return body;
    }
    createAttributeFilter(features, lyrCfg) {

        const url = lyrCfg.url;
        const layerId=lyrCfg.layerId;
        const subLayerId=lyrCfg.subLayerId;
        const wfsOptions= lyrCfg.options;
        const selectionColor=lyrCfg.selectionColor;
        const fieldsDef=lyrCfg.fieldsDef;

        const body=this.createAttributeFilterBody(features,wfsOptions,fieldsDef);
        const params={url:url,data:body};
        const self=this;

        return new Promise(function (resolve) {
            self.sendWFSRequest(params).then(function (results) {
                var features = new GeoJSON().readFeatures(results);
                const respFeatures=[];
                features.forEach(feature => {
                    feature.layerId = layerId;
                    feature.subLayerId=subLayerId;
                    feature.fieldsDef=fieldsDef;
                    feature.setStyle(self.getStyle(selectionColor));
                    respFeatures.push(feature);
                });
                resolve(respFeatures);
            });
        });
    }
    createEsriAttributeFilterUrl(features,fieldsDef) {


        const orFilters=[];


        if(features.length===0) return;
        features.forEach(feat=>{

            const andFilters=[];
            for (var key in feat) {
                let fldAlias=key;
                if(fieldsDef && fieldsDef.fields) {
                    const fld = fieldsDef.fields.filter(f => f.name === key).shift();
                    if (fld) fldAlias = fld.alias;
                }
                const eq=fldAlias+ "=" + feat[key];
                andFilters.push(eq);
            }

            orFilters.push(andFilters);

        });
        let where="";

        orFilters.forEach(andF=>{
            let andWhere="(";
            andF.forEach(eqF=>{
                if (andWhere.length===1) {
                    andWhere += eqF;
                }
                else {
                    andWhere += ' and ' +eqF;
                }
            });
            andWhere+=") ";
            if (where.length===0) {
                where+=andWhere
            }
            else {
                where+=' or ' +andWhere;
            }
        });

        const urlParams= {
            where:where,
            returnGeometry:true,
            outFields:'*',
            f:'json'
        }

        return urlParams;
        //let obj = { param1: 'something', param2: 'somethingelse', param3: 'another' }
        //obj['param4'] = 'yetanother';
        //const url = new URL(`your_url.com`);
       // url.search = new URLSearchParams(obj);
       // const response = await fetch(url);
    }
    createEsriAttributeFilter(features, lyrCfg,sLyr) {
        const url = lyrCfg.url;
        const layerId=lyrCfg.layerId;
        const subLayerId=lyrCfg.subLayerId;
        const options= lyrCfg.options;
        const selectionColor=lyrCfg.selectionColor;
        const fieldsDef=lyrCfg.fieldsDef;


///[{"layerId":2,"features":[ {"OBJECTID":1} ] }]
        const urlParams=this.createEsriAttributeFilterUrl(features,fieldsDef);
        const fUrl = new URL(url+'/query');
        const layerFilter = this.getLayerFilter(sLyr);
        if (layerFilter) {
            const filter=JSON.parse(layerFilter);
            const andWhere=filter[subLayerId];
            urlParams.where = "("+urlParams.where+") and ("+andWhere +")";

        }

        fUrl.search = new URLSearchParams(urlParams);;


        const self=this;

        return new Promise(function (resolve) {
            self.sendEsriRequest(fUrl).then(function (results) {
                var features = new EsriJSON ().readFeatures(results);
                const respFeatures=[];
                features.forEach(feature => {
                    feature.layerId = layerId;
                    feature.subLayerId=subLayerId;
                    feature.fieldsDef=fieldsDef;
                    feature.setStyle(self.getStyle(selectionColor));
                    respFeatures.push(feature);
                });
                resolve(respFeatures);
            });
        });
    }
    mapClickHandler(evt, parent,boxExtent) {

        var map = evt.target;
        var view = map.getView();
        this.setSelectableLayers();
        var sLayers = this.selectableLayers;
        var tasks = [];
        var self = this;
       // console.info('SelectionFromLayer: map clicked');
        if (this.selectionMethod==="new") {
            this.clearSelection();
        }
        sLayers.forEach(cfg => {
            var res = view.getResolution();
            var minR = cfg.layer.getProperties().minResolution;
            var maxR = cfg.layer.getProperties().maxResolution;
            if ((cfg.layer.getProperties().visible && (res < maxR && res > minR))) {
                let src;
                cfg.selectionCfg.layers.forEach(selectionCfgLayer=> {
                    const evtGeom = boxExtent ? {geom: boxExtent, type: 'bbox'} : {geom: evt.coordinate, type: 'point'};
                    if (selectionCfgLayer.serviceType == "WFS") {
                        if (cfg.layer.type && cfg.layer.type === "VECTOR") {

                            src = cfg.layer.getSource();

                            tasks.push(self.createClickFilter(src, "", evtGeom,
                                cfg.layerId, selectionCfgLayer.subLayerId,
                                "", selectionCfgLayer.selectionColor, selectionCfgLayer.fieldsDef, res));
                        }
                        else {
                            var cqlFilter = self.getLayerFilter(cfg.layer);
                            var lUrl = selectionCfgLayer.url;

                            tasks.push(self.createClickFilter("", lUrl,
                                evtGeom,
                                cfg.layerId, selectionCfgLayer.subLayerId,
                                selectionCfgLayer.options, selectionCfgLayer.selectionColor, selectionCfgLayer.fieldsDef, res, cqlFilter));
                        }

                    }
                    if (selectionCfgLayer.serviceType == "geoserverGroupLayer") {
                        selectionCfgLayer.subLayers.forEach((subLayer) => {
                            tasks.push(self.createClickFilter("", subLayer.url,
                                evtGeom,
                                cfg.layerId, subLayer.id,
                                subLayer.options, selectionCfgLayer.selectionColor, subLayer.fieldsDef, res));
                        });
                    }
                    if (selectionCfgLayer.serviceType == "esri") {
                        //{"layerId":22,"filter":"{\"1\":\"OBJECTID=2041\",\"2\":\"BPEJ='0001001' and OBJECTID>500\"}"}
                        var layerDefs = self.getLayerFilter(cfg.layer);
                        var lUrl = selectionCfgLayer.url;
                        src = cfg.layer.getSource();
                        tasks.push(self.createClickFilter(src, lUrl, evtGeom,
                            cfg.layerId, selectionCfgLayer.subLayerId,
                            "", selectionCfgLayer.selectionColor, selectionCfgLayer.fieldsDef, res,layerDefs));

                    }
                });
            }
        });
        Promise.all(tasks).then(function (resp) {
            //console.info('SelectionFromLayer: selected count:'+resp.length);
            resp.forEach(features=>{
                //self.displayFeatures(features);
                self.addToSelectionsSet(features,true);
               // console.info('SelectionFromLayer: feature added to selectSet');
            });

            var selection = self.getSelectedFeatures();
            //console.info('SelectionFromLayer: selected count:'+ selection.length );
            self.dispatchEvent(new SelectionEvent('selectFromLayer', selection));
           // console.info('SelectionFromLayer: event dispatched' );

        });
    }

    selectByAttributes(data,callback) {
        this.setSelectableLayers();
        var sLayers = this.selectableLayers;
        var tasks = [];
        var self = this;
        self.fitExtent=null;

        if (this.selectionMethod==="new") {
            this.clearSelection();
        }

        sLayers.forEach(cfg => {
            data.forEach(feature=> {
                if(feature.layerId===cfg.layerId) {
                    if (cfg.selectionCfg.serviceType == "geoserverGroupLayer") {
                       // src = cfg.layer.getSource();
                        cfg.selectionCfg.subLayers.forEach((subLayer)=>{
                           if (subLayer.id===feature.subLayerId) {
                               tasks.push(self.createAttributeFilter(feature.features, subLayer.url,
                                   cfg.layerId, subLayer.id,
                                   subLayer.options, cfg.selectionCfg.selectionColor, subLayer.fieldsDef));
                           }
                        });
                    }
                    else {
                        cfg.selectionCfg.layers.forEach(lyrCfg => {
                        if(feature.subLayerId===lyrCfg.subLayerId || !feature.hasOwnProperty("subLayerId") ) {
                            if (lyrCfg.serviceType == "WFS") {
                                tasks.push(self.createAttributeFilter(feature.features, lyrCfg));
                            }
                            if (lyrCfg.serviceType == "esri") {

                                tasks.push(self.createEsriAttributeFilter(feature.features, lyrCfg,cfg.layer));
                            }
                        }
                        });
                    }
                }
            });
        });
        Promise.all(tasks).then(function (resp) {
            resp.forEach(features=>{
                let displayFeatures=false;
                if(!callback) displayFeatures=true; //self.displayFeatures(features);
                self.addToSelectionsSet(features,displayFeatures);
            });
            var selection = self.getSelectedFeatures();
            if (self.fitExtent) {

               if(callback) {
                   callback(self.fitExtent);
               }
               else {
                   self.getMap().getView().fit(self.fitExtent);
               }
            }
            else {
                if(callback) callback(false);
            }
           // self.dispatchEvent(new SelectionEvent('selectFromLayer', selection));

        });
    }

    addToSelectionsSet(features,displayFeatures){
        const self=this;

        features.forEach(feat=> {
            var ext = feat.getGeometry().getExtent();
            self.fitExtent = self.fitExtent ? extendExtent(self.fitExtent, ext) : ext;
            const attrs=feat.getProperties();
            let newFeat={};
            let primaryKey;
            const primaryKeyAlias=feat.fieldsDef.primaryKeyAlias;
            if (feat.fieldsDef && feat.fieldsDef.fields) {
                feat.fieldsDef.fields.forEach(fld=>{
                    newFeat[fld.name]=attrs[fld.alias];
                    if (fld.alias===feat.fieldsDef.primaryKeyAlias) {
                        primaryKey=fld.name;
                    }
                });
            }
            else {
                delete attrs[feat.getGeometryName()];
                newFeat={...attrs};
            }
            newFeat['layerId']=feat.layerId;
            newFeat['subLayerId']=feat.subLayerId;
            if (self.selectionMethod==="new" || !primaryKey) {
                if(displayFeatures) {
                    self.selectedFeatures.push(newFeat);
                    self.selectionLayer.getSource().addFeatures([feat]);
                }
            }
            if (this.selectionMethod==="add" && primaryKey) {
                //feat.fieldsDef.primaryKeyAlias findIndex
                const filtered=self.selectedFeatures.filter((x)=>
                    x[primaryKey]===attrs[primaryKeyAlias]
                );
                if (filtered.length>0) {
                    if (displayFeatures) {
                        const feats=self.selectionLayer.getSource().getFeatures();
                        const newFeatures=[];
                        feats.forEach((x) => {
                                if (x.getProperties()[primaryKeyAlias] !== filtered[0][primaryKey]) {
                                    newFeatures.push(x);
                                }
                            }
                        );
                         self.selectionLayer.getSource().clear();
                         self.selectionLayer.getSource().addFeatures(newFeatures);

                    }
                    //remove from selected
                    const filteredIdx=self.selectedFeatures.findIndex((x)=>
                        x[primaryKey]===attrs[primaryKeyAlias]
                    );
                    if (displayFeatures)  self.selectedFeatures.splice(filteredIdx,1);
                }
                else {
                    if (displayFeatures) {
                        self.selectedFeatures.push(newFeat);
                        self.selectionLayer.getSource().addFeatures([feat]);
                    }
                }
            }
        });
    }

    clearSelection(isEvent) {
        this.selectedFeatures=[];
        this.selectionLayer.getSource().clear();
        if(isEvent) {
            this.dispatchEvent(new SelectionEvent('clearSelectionFromLayer', []));
        }
    }
    displayFeatures(features){
        this.selectionLayer.getSource().addFeatures(features);
    }

    getSelectedFeatures() {

        return this.selectedFeatures;
    }
    getExtentFromSelection(data,callback){
        this.selectByAttributes(data,callback);
    }
}
