// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {listen} from "ol/events";
import EventType from "ol/events/EventType";
import {KML, GeoJSON,GPX} from 'ol/format';
import {Vector as VectorLayer} from "ol/layer";
import {Vector as VectorSource} from "ol/source";
import JSZip from 'jszip';
import shp from 'shpjs';

import {extend} from 'ol/extent';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from "ol/style";
import MultiPoint from "ol/geom/MultiPoint";
import LayerGroup from "ol/layer/Group";

var styles = {

    "default": [
        new Style({
            stroke: new Stroke({
                color: 'green',
                width: 3
            }),
            fill: new Fill({
                color: 'rgba(0, 255, 0, 0.1)'
            }),
            text: new Text({
                font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                placement: 'point',
                offsetX :0,
                offsetY:15,
                overflow: true,
                fill: new Fill({
                    color: 'green'
                })
            })

        }),
        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'green'
                }),
                text: new Text({
                    font: 'bold 16px "Open Sans", "Arial Unicode MS", "sans-serif"',
                    placement: 'point',
                    offsetX :0,
                    offsetY:15,
                    fill: new Fill({
                        color: 'blue'
                    })
                })
            })
        })]

};

export class FileImportControl extends Control {
    constructor(options) {

        super({
            element: document.createElement('div'),
            target: options.target
        });

        this.parentControl = options.parentControl;
        this.options = options;
        this.isActive = false;
        this.selectableLayers = [];

        const className = options.className !== undefined ? options.className : 'file-import-control';
        const button = document.createElement('button');
        //const label = "I";
        const tipLabel = 'Import externej vrstvy';
        button.setAttribute('type', 'button');
        button.title = tipLabel;
       /* button.appendChild(
            typeof label === 'string' ? document.createTextNode(label) : label
        );*/

        listen(button, EventType.CLICK, this.toolBtnClicked, this);
        const cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;
        const element = this.element;
        element.className = cssClasses;
        element.appendChild(button);
        this.inputDiv = document.createElement('div');
        this.inputDiv.className = 'fileimportcontrol-inputdiv hidden';

        var closeBtn = document.createElement('span');
        //closeBtn.setAttribute('type', 'button');
        closeBtn.title = 'Zatvoriť';
        listen(closeBtn, EventType.CLICK, this.closeDiv, this);
        closeBtn.appendChild(document.createTextNode(''));
        closeBtn.className='fileimportcontrol-inputdiv-close';
        this.inputDiv.appendChild(closeBtn);
        var desc = document.createElement('div');
        desc.className='fileimportcontrol-inputdiv-desc';
        desc.innerHTML='Pre zobrazenie externej vrstvy vyberte prosím vstupný súbor. Podporované formáty: kml,kmz, gpx, shp (ZIP).';
        this.inputDiv.appendChild(desc);
        var fileInput = document.createElement('input');
        fileInput.setAttribute('type', 'file');
        fileInput.setAttribute('accept',".kml,.kmz,.gpx,.zip");
        fileInput.className ='fileimportcontrol-input';
        listen(fileInput, EventType.CHANGE, this.fileSelectedHandler, this);
        this.inputDiv.appendChild(fileInput);
        element.appendChild(this.inputDiv);
    }

    fileSelectedHandler(evt) {

        var files = Array.from(evt.target.files);
        var self = this;
        files.forEach(f => {
            // f.name f.type f.size  f.lastModified
            var reader = new FileReader();
            var fName = f.name;
            var fileExt = fName.split('.').pop();
            /*var fType=f.type;
            'application/vnd.google-earth.kml+xml'
            application/vnd.google-earth.kmz'
            application/x-zip-compressed
             */
            if(fileExt) fileExt=fileExt.toLowerCase();

            switch (fileExt) {
                case 'kml':
                    reader.onload = function () {
                        var data = reader.result;
                        self.displayKMLData(data, fName);
                    };
                    reader.readAsText(f);
                    break;

                case 'kmz':

                    var jsZip = new JSZip();
                    jsZip.loadAsync(f).then(function (zip) {
                        Object.keys(zip.files).forEach(function (filename) {
                            var ext = filename.split('.').pop();
                            if (ext == "kml") {
                                zip.files[filename].async('string').then(function (data) {
                                    self.displayKMLData(data, fName);
                                });
                            }
                        });

                    });
                    break;

                case 'zip':
                    reader.onload = function () {
                        var data = reader.result;
                        self.displaySHPData(data, fName);
                    };
                    reader.readAsArrayBuffer(f);
                    break;
                    //"application/gpx+xml" ??
                case 'gpx':
                        reader.onload = function () {
                            var data = reader.result;
                            self.displayGPXData(data, fName);
                        };
                        reader.readAsText(f);
                    break;
                default:

            }
        });
    }
    displayGPXData(data, filename) {

        var projection = this.getMap().getView().getProjection();
        var format = new GPX();
        var features = format.readFeatures(data, {
            dataProjection: null,
            featureProjection: projection
        });
        this.addFeatures(features, filename);
    }
    displaySHPData(data, filename) {

        var map = this.getMap();
        var projection = map.getView().getProjection();
        var self = this;
        shp(data).then(function (resp) {
            if (!Array.isArray(resp)) {
                resp = [resp];
            }
            resp.forEach(gjson => {
                var format = new GeoJSON();
                var features = format.readFeatures(gjson, {
                    dataProjection: null,
                    featureProjection: projection
                });
                self.addFeatures(features, filename);
            });
        });
    }

    displayKMLData(data, filename) {


        var map = this.getMap();
        var projection = map.getView().getProjection();
        var format = new KML();
        var features = format.readFeatures(data, {
            dataProjection: null,
            featureProjection: projection
        });
        this.addFeatures(features, filename);

    }

    addFeatures(features, layerName) {
        var isPrint=!!this.options.print;
        var importFileLayer;

        if(features.length>this.options.maxFeaturesCount) {
            alert("Import nebol úspešný. Prekročili ste maximálny počet objektov ("+this.options.maxFeaturesCount+"), ktoré je možné importovať.");
            return;
        }
        features.forEach(f=>{
            f.styleFunction_=null;
            f.style_=null;
            var props=f.getProperties();
            if (props && props.description) {
                f.setProperties({'description': ""});
            }
        });
        if (this.options.importToNotesLayer) {
           var lyrs=this.getMap().getLayers();
            lyrs.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    var grp = lyr.getLayers();
                    grp.forEach(lyr2 => {
                        if (lyr2.getProperties().name=='notesDrawLayer') {
                            importFileLayer=lyr2;
                        }
                    });
                }
                else {
                    if (lyr.getProperties().name=='notesDrawLayer') {
                        importFileLayer=lyr;
                    }
                }
            });
        }
        else {
             importFileLayer = new VectorLayer({
                print:isPrint,
                title: layerName,
                type: "",
                 style:  styles["default"],
                source: new VectorSource({
                    wrapX: false}
                )
            });
             if(!importFileLayer) return;
            var grpLyrs = this.overlayGroup.getLayers();
            grpLyrs.push(importFileLayer);
            this.overlayGroup.setLayers(grpLyrs);
            // map.addLayer(importFileLayer);
        }





        var src = importFileLayer.getSource();
        src.addFeatures(features);


        this.closeDiv();
        var ext;
        features.forEach(f => {

            if (!ext) {
                ext = f.getGeometry().getExtent();
            }
            else {
                ext = extend(ext, f.getGeometry().getExtent());
            }

        });


        if (ext) {
            this.getMap().getView().fit(ext);
        }
    }
    closeDiv() {
        this.deactivate();
    }
    toolBtnClicked(evt) {

        this.isActive = !this.isActive;

        if (this.isActive) {
            this.activate();
       }
        else {
            this.deactivate();
       }
    }

    setOverlayGroup(grp) {
        this.overlayGroup = grp;
    }

    activate() {
        this.inputDiv.className = 'fileimportcontrol-inputdiv';
        if (this.parentControl) this.parentControl.setActiveControl(this);
    }

    deactivate() {
        this.isActive=false;
        this.inputDiv.className = 'fileimportcontrol-inputdiv hidden';

        if (this.parentControl) this.parentControl.unsetActiveControl();

    }
}