// @ts-nocheck
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import '../styles/MapPlugin.css';
import {NavigationBarControl} from "../modules/NavigationBarControl";
import {MapPluginBase} from './MapPluginBase';
import {ZoomUtils} from "../modules/ZoomUtils";
import {ExportMap} from "../modules/ExportMap";
import {unByKey} from "ol/Observable";
import {FeatureInfoFromCoordinateControl} from "../modules/FeatureInfoFromCoordinateControl";
import {SnapControl} from "../modules/SnapControl";
import {SearchControl} from "../modules/SearchControl";
import {GeoLocationControl} from "../modules/GeoLocationControl";
import {LayerUtils} from "../modules/LayerUtils";

export class MapPluginSHMU extends MapPluginBase {

    constructor(options) {
        super(options);
    }

    addSpecificControls() {
        var self = this;
        if (self.options.snappingOptions) {
            var snapControl=new SnapControl(self.map,self.options.snappingOptions);
            window.snapControl=snapControl;
        }
        if (self.options.geoLocationControlOptions && self.options.geoLocationControlOptions.isButtonVisible) {
            var geoLocationControl=new  GeoLocationControl({},self.map);
            self.map.addControl(geoLocationControl);
            //geoLocationControl.activate(self.map);
        }

        if (self.options.navigationBarOptions) {
            var navigationBarControl = new NavigationBarControl(self.options.navigationBarOptions);
            self.map.addControl(navigationBarControl);
            navigationBarControl.activate(self.map);
        }
        if (self.options.searchOptions) {
            var search = new SearchControl(self.options.searchOptions);
            self.map.addControl(search.searchControl);
        }
        if (self.layerConfig.hasSelectableLayers) {
            var selectionFromLayerControl = self.toolbar.addControl('SelectionFromLayerControl',
                { selectionMethod: 'click', config: self.options });
            if ((self.options.defaultControl &&
                self.options.defaultControl === "selectFromLayer")
            ) {
                if (!self.options.selectionFromLayerControl ||
                    (self.options.selectionFromLayerControl && self.options.selectionFromLayerControl.isButtonVisible !== false)) {
                    selectionFromLayerControl.activate();
                }
            }
            //callbacks

            self.getSelectedFeaturesFromLayer = selectionFromLayerControl.getSelectedFeatures.bind(selectionFromLayerControl);
            self.onSelectionFromLayer = function (callback) {
                //console.info('MapPluginEAM: onSelectionFromLayer registered');
                selectionFromLayerControl.eventKey = selectionFromLayerControl.on('selectFromLayer', function (evt) {
                //    console.info('MapPluginEAM: event dispatched');
                    callback(evt.selected);
                })
            }
            self.onClearSelectionFromLayer=
                function(callback) {
                selectionFromLayerControl.eventKey2 = selectionFromLayerControl.on('clearSelectionFromLayer', function (evt) {
                    callback(evt.selected);
                })
            }
            self.unregisterOnSelectionFromLayer = function () {
                unByKey(selectionFromLayerControl.eventKey)
            };
            self.unregisterOnClearSelectionFromLayer= function () {
                unByKey(selectionFromLayerControl.eventKey2)
            };
            self.selectFromLayer = selectionFromLayerControl.selectByAttributes.bind(selectionFromLayerControl);

            self.clearSelectionFromLayer = selectionFromLayerControl.clearSelection.bind(selectionFromLayerControl);
            self.enableSelectionFromLayer = selectionFromLayerControl.setVisibility.bind(selectionFromLayerControl);
            self.getExtentFromSelection = selectionFromLayerControl.getExtentFromSelection.bind(selectionFromLayerControl);

        }
        if (self.options.editor && self.options.editor.editTypes && self.options.editor.editTypes.length) {
            var editorControl = self.toolbar.addControl('EditorControl', self.options);
            self.startEditing = editorControl.startEditing.bind(editorControl);
            self.stopEditing = editorControl.stopEditing.bind(editorControl);

        }

        if (self.options.measure && self.options.measure.measureTypes && self.options.measure.measureTypes.length) {
            var measureControl = self.toolbar.addControl(
                'MeasureControl', self.options);
        }
        if (self.options.featureInfoControl && self.options.featureInfoControl.isButtonVisible) {
            var featureInfoControl = self.toolbar.addControl(
                'FeatureInfoControl', self.options);

        }

        var featureInfoFromCoordinateControl =new FeatureInfoFromCoordinateControl(self.map,self.options);
        self.getFeatureInfo=featureInfoFromCoordinateControl.getFeatureInfo.bind(featureInfoFromCoordinateControl);

        var mapExport = new ExportMap(self.map, self.options.print);
        self.exportMapAsFile = mapExport.exportMapAsFile.bind(mapExport);
        self.exportMap = mapExport.exportMap.bind(mapExport);
        self.exportMapAsZip = mapExport.exportMapAsZip.bind(mapExport);
        if (self.options.print && self.options.print.isButtonVisible) {
            var printControl = self.toolbar.addControl(
                'PrintControl', self.options);
            printControl.setExportControl(mapExport);
        }
        const zoomUtils = new ZoomUtils(self.map, {});
        self.sendToMap = zoomUtils.sendToMap.bind(zoomUtils);

        self.transformCoordinate=zoomUtils.transformCoordinate.bind(zoomUtils);
        if (self.options.drawPOIControl) {
            var drawPOIControl = self.toolbar.addControl(
                'DrawPOIControl', self.options);
            self.initDraw = function (data, callback) {
                drawPOIControl.init(data, callback);
            }
            self.stopDrawPOI=function() {
                unByKey(drawPOIControl.eventKey);
                const stopDraw=drawPOIControl.stopDraw.bind(drawPOIControl);
                stopDraw();
            };
            self.onDrawMapClicked =function(callback) {
                drawPOIControl.eventKey= drawPOIControl.on('drawPOIclicked',function(evt) {
                    if(callback) callback(evt);
                })
            }
        }
        const layerUtils=new LayerUtils(self.map,{});
        self.filterWMSLayer=layerUtils.filterWMSLayer.bind(layerUtils);
        self.filterLayer=layerUtils.filterLayer.bind(layerUtils);
    }
}
