// @ts-nocheck

import './ol-geocoder/ol-geocoder.css';

import Geocoder from './ol-geocoder/ol-geocoder-yms';

import {WfsSearchProvider} from "./WfsSearchProvider";
import {EsriSearchProvider} from "./EsriSearchProvider";

export class SearchControl {

    constructor(options){

       /* var wfsOptions=  {
            srsName :'EPSG:5514',
            featureNS:'http://spf',
            featurePrefix : 'SPF',
            featureTypes:['KU'],
            outputFormat:'application/json',
            displayPattern:'${NKU} (okr.${Okres})'
         };*/
        if (options.provider=="wfs") {
            var provider = new WfsSearchProvider({
                //url: 'http://s54001.ymss.sk:8080/geoserver/wfs?service=wfs&version=1.1.0&request=GetFeature',
                // queryFields: ['NKU', 'Obec'],
                url:options.url,
                wfsOptions: options.wfsOptions,
                limit: options.limit
            });
            options.provider = provider;
        }
        if (options.provider=="esri") {
            var provider = new EsriSearchProvider({
                //url: 'http://s54001.ymss.sk:8080/geoserver/wfs?service=wfs&version=1.1.0&request=GetFeature',
                // queryFields: ['NKU', 'Obec'],
                url:options.url,
                esriOptions: options.esriOptions,
                limit: options.limit
            });
            options.provider = provider;
        }
        this.searchControl=new Geocoder('nominatim', options);

    }

}