// @ts-nocheck
import {defaults as defaultControls, Control} from 'ol/control';
import {SelectionEvent} from './SelectionEvent';
import EsriJSON from 'ol/format/EsriJSON';

import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import ImageWMS from 'ol/source/ImageWMS';

import GeoJSON from 'ol/format/GeoJSON';
import LayerGroup from 'ol/layer/Group';
import {Image as ImageLayer, Vector as VectorLayer} from "ol/layer";
import {extend as extendExtent} from 'ol/extent';
import {unByKey} from "ol/Observable";
import WKT from "ol/format/WKT";
//@ts-ignore
import jsts from "jsts/dist/jsts.min.js";
import {transform} from "ol/proj";
import {Vector as VectorSource} from "ol/source";
import {Fill, Stroke, Style} from "ol/style";
require('es6-promise').polyfill();

export class CadastralInfoControl extends Control {


    constructor(options,map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });

        this.filterKodKu="Code eq ${inputVal}&$select=Id";
        this.filterIdKu="Id eq ${inputVal}&$select=Code";
        this.parentControl = options.parentControl;
        this.cadastralInfoCfg=options.cadastralInfoControl;
        this.element.setAttribute("style","display:none;");
        this.selectionLayer = new VectorLayer({
            name:"cadastralInfoLayer",
            //always print
            print:true,
            source: new VectorSource({wrapX: false,crossOrigin: 'Anonymous'}),
            style:  new Style({
                stroke: new Stroke({
                    color: 'cyan',
                    width: 3
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 255, 0)'
                })
            })
        });
        map.addLayer(this.selectionLayer);
    }


    initialize() {
        if (this.initialized) return;
        var map = this.getMap();
        this.setSelectableLayers();
        this.initialized = true;
    }


    handleClick(event) {

        event.preventDefault();
        this.isActive = !this.isActive;

        if (this.isActive) {
            this.activate();
        }
        else {
            this.deactivate();
        }

    }

    tryActivate() {

        if (!this.isActive) {
            this.activate();
        }

    }

    setVisibility(visible) {
        if (this.isButtonVisible) return;
        if (visible) {
            if (!this.isActive) {
                this.activate();
            }
            this.element.style.display = "";
        }
        else {
            if (this.isActive) {
                this.deactivate();
            }
            this.element.style.display = "none";
        }
    }
    getParcelInfoByClick(data) {
        this.activate();
        var map = this.getMap();
        this.selectionLayer.getSource().clear();
        var self = this;
        this.mapClickHandlerKey = map.on('singleclick', function (evt) {
            self.mapClickHandler(evt, data)
        });
    }
    activate() {
        this.isActive = true;
        //this.initialize();
        var map = this.getMap();
        var self = this;

        //this.element.classList.add("active");
        if (this.parentControl) this.parentControl.setActiveControl(this);

    }

    deactivate() {
        this.isActive = false;
        //this.element.classList.remove("active");
        if (this.parentControl) this.parentControl.unsetActiveControl();

        unByKey(this.mapClickHandlerKey);
        this.fitExtent =null;

    }
    mapClickHandler(evt,data) {
        const view = this.getMap().getView();
        this.kuIdCodePairs={};
        var outSR = {wkid: view.getProjection().getCode().replace("EPSG:", "")};
        let parcType="parcelC";
        if(data.register && data.register==='E') parcType="parcelE";
        const cfg=this.cadastralInfoCfg[parcType];

        const point={x:evt.coordinate[0],y:evt.coordinate[1]};
        var queryUrl=cfg.url + "&inSR=" + JSON.stringify(outSR)
            +"&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometry="
            + JSON.stringify(point)+ "&outSR=" + JSON.stringify(outSR);
        const self=this;
         fetch(queryUrl).then(function (response) {
                return response.text();
            }).then(function (response) {
                self.parseEsriResponse(response,data);
            });
         this.deactivate();


    }
    findByPoint(data){
        var projection = this.getMap().getView().getProjection();
        var p = transform(data.pointGPS,"EPSG:4326", projection.getCode() );
        this.mapClickHandler({coordinate:p},data);
    }
    createWhere(data,cfg) {
        let where;
        const self=this;
        const promise=new Promise (resolve=> {
            if (data.hasOwnProperty("cadastralUnitCode")) {
                self.getKuId(data.cadastralUnitCode,self.cadastralInfoCfg.kodKUUrl).then(res=>{
                           let kuId=0;
                           if (res.value && res.value.length) {
                                 kuId = res.value[0].Id;
                            }
                    where = "" + cfg.parcelNumberField + "='" + data.parcelNumber + "' and ";
                    where += cfg.cadastralUnitIdField + "= " + kuId;
                    self.kuIdCodePairs[kuId]=data.cadastralUnitCode;
                    resolve(where);
                });
            }
            else {
                where = "" + cfg.parcelNumberField + "='" + data.parcelNumber + "' and ";
                where += cfg.cadastralUnitIdField + "= " + data.cadastralUnitId;
                resolve(where);
            }
        });
        return promise;
    }
    getParcelInfo(data,errCallback) {

        this.kuIdCodePairs={};
        if(data.pointGPS) {
            this.findByPoint(data);
            return;
        }
        const view = this.getMap().getView();
        this.selectionLayer.getSource().clear();

        var outSR = {wkid: view.getProjection().getCode().replace("EPSG:", "")};
        let parcType="parcelC";
        if(data.register && data.register==='E') parcType="parcelE";
        const cfg=this.cadastralInfoCfg[parcType];
        this.createWhere(data,cfg).then(where=> {
            var queryUrl = cfg.url + "&where=" + where + "&outSR=" + JSON.stringify(outSR);
            const self = this;
            try {
                fetch(queryUrl).then(function (response) {
                    return response.text();
                }).then(function (response) {
                    self.parseEsriResponse(response, data, errCallback);
                });

            }
            catch (err) {
                if (errCallback) errCallback({status: "OtherError"});
            }
        });

    }
    parseEsriResponse(response,data,errCallback) {
        this.fitExtent =null;
        var json = JSON.parse(response);
        var esriFormat = new EsriJSON()
        var features = esriFormat.readFeatures(json);
        const view = this.getMap().getView();
        const self=this;
        const src=this.selectionLayer.getSource();
        src.clear();
        if (features.length == 0) {
            if (errCallback) errCallback({status: "NotFoundError"});
        }
        if (features.length > 0) {
            //self.dispatchEvent(new SelectionEvent('select', selection));
            let wktData=[];
            features.forEach(f=> {
                if (data.zoomTo){
                    var ext = f.getGeometry().getExtent();
                    self.fitExtent = self.fitExtent ? extendExtent(self.fitExtent, ext) : ext;
                }
                src.addFeature(f);
                wktData.push(self.getResponseData(f,data.returnTypes));
            });

            if (data.zoomTo && self.fitExtent) view.fit(self.fitExtent);
            self.assignKuCode(wktData).then(newWktData=> {
                self.dispatchEvent(new SelectionEvent('select',newWktData ));
            });
        }
    }
    getResponseData(feature,returnTypes){
        const wkt = new WKT();
        var jstsReader = new jsts.io.WKTReader();
        var wktFeature = wkt.writeFeature(feature);
        let attributes={};
        let strWkt;
        var geom = jstsReader.read(wktFeature);
        var jstsWriter = new jsts.io.WKTWriter();
        var projection = this.getMap().getView().getProjection();
        let feat={};
        returnTypes.forEach(returnType=>{
        switch (returnType) {
            case "geometry":
                feat.geometry= wktFeature;
                break;
            case "centroid":
                feat.centroid=jstsWriter.write(geom.getCentroid());
                break;
            case "centroidGPS":
                var c=geom.getCentroid();
                var p = transform([c.getX(),c.getY()], projection.getCode(), "EPSG:4326");
                feat.centroidGPS="POINT("+p[0]+" "+p[1]+")";
                break;
            case "attributes":
                attributes=feature.getProperties();
                delete attributes.geometry;
                feat.attributes=attributes;
                break;
            case "lvUrl":
                attributes=feature.getProperties();
                feat.lvUrl=this.cadastralInfoCfg.lvUrl.replace("${prfId}",attributes["FOLIO_ID"]);
                break;

        }
        });
        return feat;
    }


    clearAll() {
        if (!this.selectableLayers) return;
        this.selectableLayers.forEach(lyr => {
            this.clearSelection(lyr);
        });
    }

    clearSelection(selectionConfig) {
        var deletes = [];
        if (!selectionConfig || !selectionConfig.data) return;
        selectionConfig.data.forEach(function (oldFeature) {
            deletes.push(oldFeature);
        });

        this.updateSelection([], deletes, selectionConfig);
    }
    getKuId(val,urlKU,isReverse) {
        let filter=this.filterKodKu.replace("${inputVal}",val);
        if (isReverse) filter=this.filterIdKu.replace("${inputVal}",val);
        const url=urlKU+ filter;
        return fetch(url, {
            method: 'GET'
        }).then(response => response.json());
    }
    assignKuCode(wktData) {
        const self=this;

        const promise=new Promise (resolve=> {
            if(wktData.length) {
                if(!wktData[0].hasOwnProperty("attributes")) resolve(wktData);
                if (this.kuIdCodePairs && Object.keys(this.kuIdCodePairs).length) {
                    wktData.forEach(f => {
                        const idKu=f.attributes["CADASTRAL_UNIT_ID"];
                        f.attributes["CADASTRAL_UNIT_CODE"]=self.kuIdCodePairs[idKu];
                    });
                    resolve(wktData);
                }
                else {
                    const fetchs=[];
                    const kuIds=[];
                    wktData.forEach(f => {
                        const idKu=f.attributes["CADASTRAL_UNIT_ID"];
                        if(!kuIds.includes(idKu)) {
                            fetchs.push(self.getKuId(idKu, self.cadastralInfoCfg.kodKUUrl, true));
                            kuIds.push(idKu);
                        }

                    });
                    const kuIdCodePairs={};
                    Promise.all(fetchs).then((res)=>{
                        res.forEach((codes, i) => {
                            if (codes.value && codes.value.length) {
                                const code = codes.value[0].Code;
                                kuIdCodePairs[kuIds[i]]=code;
                            }
                        });

                        wktData.forEach(f => {
                            const idKu=f.attributes["CADASTRAL_UNIT_ID"];
                            f.attributes["CADASTRAL_UNIT_CODE"]=kuIdCodePairs[idKu];
                        });
                        resolve(wktData);
                    });

                }
            }
            else {
                resolve(wktData);
            }
        });
        return promise;
    }

}
