import Event from 'ol/events/Event';

export class MapClickEvent extends Event {

    constructor(type, coordinate) {

        super(type);
        this.coordinate = coordinate;
    }

}