// @ts-nocheck

import {defaults as defaultControls, Control} from 'ol/control';
import {defaults as defaultInteractions, Draw, Modify, Snap, Select} from 'ol/interaction';
import Overlay from 'ol/Overlay';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';
import {LineString, Polygon,Point} from 'ol/geom';
import {getArea, getLength} from 'ol/sphere';
import WKT from 'ol/format/WKT';
import {extend, getCenter} from 'ol/extent';
import {unByKey} from "ol/Observable";
import {transform} from "ol/proj";
import {projectionsDefs} from "../config/Projections";

var style =  new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
        color: '#ffcc33',
        width: 2,
    }),
    image: new CircleStyle({
        radius: 7,
        fill: new Fill({
            color: '#ffcc33',
        }),
    })
});
var sketchStyle= new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.2)',
    }),
    stroke: new Stroke({
        color: 'rgba(0, 0, 0, 0.5)',
        lineDash: [10, 10],
        width: 2,
    }),
    image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.7)',
        }),
        fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
        }),
    })
});
export class MeasureControl extends Control {

    constructor(options,map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });
        this.parentControl = options.parentControl;

        this.drawTypeMappings = {'point': 'Point', 'line': 'LineString', 'polygon': 'Polygon'};
        this.measureTooltips=[];

        this.drawLayer = new VectorLayer({
            name:"measureDrawLayer",
            //always print
            print:true,
            source: new VectorSource({wrapX: false,crossOrigin: 'Anonymous'}),
            style: style
        });
        map.addLayer(this.drawLayer);
        this.element.className = 'measure-control';
        this.toolDivs={};
        var self = this;


       // var measureTypes = ['point', 'line','polygon','delete'];
        this.lengthUnitLimit=5000;
        if(options.measure && options.measure.lengthUnitLimit) {
            this.lengthUnitLimit=options.measure.lengthUnitLimit;
        }
        if (options.measure && options.measure.measureTypes && options.measure.measureTypes.length) {
            var measureTypes = options.measure.measureTypes.concat(['delete']);
            measureTypes.forEach(nt => {
                switch (nt) {
                    case 'point':
                        this.createTool(nt, 'Merať bod');
                        break;
                    case 'line':
                        this.createTool(nt, 'Merať dĺžku');
                        break;
                    case 'polygon':
                        this.createTool(nt, 'Merať plochu');
                        break;
                    case 'delete':
                        this.createTool(nt, 'Vymazať merania', 'D');
                        break;
                }
            }, this);
        }
    }

    createTool(toolType, tooltip) {

        var div = document.createElement('div');
        this.toolDivs[toolType]=div;
        var toolClass = 'measure-control-tool';
        var className = toolClass + ' ' + 'measure-control-' + toolType;
        var button = document.createElement('button');

        var tipLabel = tooltip;
        button.setAttribute('type', 'button');
        button.title = tipLabel;


        listen(button, EventType.CLICK, function (evt) {
                this.btnToolClicked(evt, toolType);
            }, this
        );
        var cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;

        div.className = cssClasses;
        div.appendChild(button);
        this.element.appendChild(div);
    }

    btnToolClicked(evt, toolType) {


        if(this.activeTool==toolType){
            this.deactivate();
            return;
        }
        this.deactivateTool();

        if(toolType==='delete') {
            this.clearMeasureGeoms();
            this.deactivate();
            return;
        }
        var src = this.drawLayer.getSource();

        this.removeMapInterections();

        this.activeTool=toolType;
        this.activate();
        this.addMapInteraction(toolType, src);
        if (window.snapControl) {
            window.snapControl.activate();
        }

    }

    addMapInteraction(toolType, source) {

        var map = this.getMap();

        var self = this;
        var drawType = this.drawTypeMappings[toolType];
        if (drawType) {
            this.draw = new Draw({
                source: source,
                type: drawType,
                style:sketchStyle
            });


            map.addInteraction(this.draw);

            this.createMeasureTooltip();


            var listener;
            var sketch;
            this.draw.on('drawstart', function (evt) {
                sketch = evt.feature;
                var tooltipCoord = evt.coordinate;

                listener = sketch.getGeometry().on('change', function (evt) {
                    var geom = evt.target;
                    var output;
                    if (geom instanceof Polygon) {
                        var l=self.formatLength(geom);
                        output = 'Plocha: '+self.formatArea(geom,l.unit);
                        output +='<br>Obvod:'+l.output;
                        tooltipCoord = geom.getInteriorPoint().getCoordinates();
                    } else if (geom instanceof LineString) {
                        output = self.formatLength(geom).output;
                        tooltipCoord = geom.getLastCoordinate();
                    }
                    self.measureTooltipElement.innerHTML = output;
                    self.measureTooltip.setPosition(tooltipCoord);
                });
            });

            this.draw.on('drawend', function (evt) {

                var geom = evt.feature.getGeometry();
                var offset=[0,-7];
                if (geom instanceof Point) {
                    self.measureTooltipElement.innerHTML = self.formatCoordinates(geom);
                    self.measureTooltip.setPosition(geom.getCoordinates());
                    offset=[0,-12];
                }
                self.measureTooltipElement.className = 'ol-tooltip ol-tooltip-static';
                self.measureTooltip.setOffset(offset);
                // unset sketch
                sketch = null;
                // unset tooltip so that a new one can be created
                self.measureTooltipElement = null;
                self.createMeasureTooltip();
                unByKey(listener);
            });
        }



    }

    removeMapInterections() {

        var map = this.getMap();
        if (this.draw) {
            map.removeInteraction(this.draw);
            this.draw = null;
        }

    }


    activate() {
        if (this.parentControl) {
            if (!(this.parentControl.activeControl instanceof MeasureControl)) {
                this.parentControl.setActiveControl(this);
            }
        }

        var div=this.toolDivs[this.activeTool];

        if (div) {
            div.classList.add("active");
        }


    }

    deactivate() {

        this.removeMapInterections();
        if (this.addFeatureEvtHndKey) {
            unByKey(this.addFeatureEvtHndKey);
            this.addFeatureEvtHndKey=null;
        }
        if (this.popup) this.popup.hide();
        this.deactivateTool();
        if (window.snapControl) {
            window.snapControl.deactivate();
        }
        if (this.parentControl)
            if ((this.parentControl.activeControl instanceof MeasureControl)) {
                this.parentControl.unsetActiveControl();
            }

    }
    deactivateTool() {
       // this.destroyTooltips();
        var div=this.toolDivs[this.activeTool];

        if (div) {
            div.classList.remove("active");
        }
        this.activeTool=null;
    }
    destroyTooltips() {
        if (this.measureTooltipElement && this.measureTooltipElement.parentNode) {
            this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
        }
        var map = this.getMap();
        this.measureTooltips.forEach(o=>map.removeOverlay(o));


    }
    getWKTFeatures() {

        var wkt = new WKT();
        var wktFeatures = {
            points: [],
            polylines: [],
            polygons: [],
            extent:null
        };
        var ext;



        if (ext) {
            this.getMap().getView().fit(ext);
        }
        var ext;
        var features = this.drawLayer.getSource().getFeatures();
        features.forEach(f => {
            //extent
            if (!ext) {
                ext = f.getGeometry().getExtent();
            }
            else {
                ext = extend(ext, f.getGeometry().getExtent());
            }

            var gType = f.getGeometry().getType();
            var desc = f.get("description");
            desc = desc ? desc : "";
            var wktF = {description: desc, wkt: wkt.writeFeature(f)};

            switch (gType) {

                case "Point":
                    wktFeatures.points.push(wktF);
                    break;
                case "LineString":
                    wktFeatures.polylines.push(wktF);
                    break;
                case "Polygon":
                    wktFeatures.polygons.push(wktF);
                    break;
            }
        });
        wktFeatures.extent=ext;

        return wktFeatures;
    }

    formatLength(geom) {
        var length = getLength(geom);
        var output;
        var unit='';

        if (length > this.lengthUnitLimit) {
            output = Math.round((length / 1000) * 100) / 100 + ' ' + 'km';
            unit='km'
        } else {
            output = Math.round(length * 100) / 100 + ' ' + 'm';
            unit='m';
        }
        return {output:output,unit:unit};
    }


  formatArea(polygon,forceUnit) {


        var area = getArea(polygon);
        var output;
        var useKm=false;
        if (area > this.lengthUnitLimit*this.lengthUnitLimit ) {
            useKm=true;
        }
        if(forceUnit==='km') useKm=true;
        if(forceUnit==='m') useKm=false;
        if(useKm){
            output = Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
        } else {
            output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
        }
        return output;
    }
    formatCoordinates(pnt){
        var output='';
        var map = this.getMap();
        var projection = this.getMap().getView().getProjection();
        var projDef=projectionsDefs[projection.getCode()];
        var projName;
        if(projDef) projName=projDef.displayName;
        try {
            output += projName+': ' + Math.round(pnt.getCoordinates()[0] * 1000) / 1000 + ';  ' + Math.round(pnt.getCoordinates()[1] * 1000) / 1000
            var wgsPoint = transform(pnt.getCoordinates(), projection.getCode(), "EPSG:4326");
            output += '<br>GPS: ' + Math.round(wgsPoint[0] * 100000) / 100000 + ';  ' + Math.round(wgsPoint[1] * 10000) / 10000
        }
        catch(e){
            console.log("MeasureControl: error transform point.");
        }
        return output;
    }
    createMeasureTooltip() {
        if (this.measureTooltipElement && this.measureTooltipElement.parentNode) {
            this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
        }
        this.measureTooltipElement = document.createElement('div');
        this.measureTooltipElement.className = 'ol-tooltip ol-tooltip-measure';
        this.measureTooltip = new Overlay({
            element: this.measureTooltipElement,
            offset: [0, -15],
            positioning: 'bottom-center',
        });
        this.measureTooltips.push(this.measureTooltip);
        var map = this.getMap();

        map.addOverlay(this.measureTooltip);
    }

    clearMeasureGeoms() {
        this.destroyTooltips();
        this.drawLayer.getSource().clear();
    }
}