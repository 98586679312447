// @ts-nocheck
import '../styles/MapFonts.css';
import 'ol/ol.css';
import 'ol-layerswitcher/src/ol-layerswitcher.css';
import '../styles/MapPlugin.css';
import { Map, View } from 'ol';
import { getCenter } from 'ol/extent';
import LayerSwitcher from 'ol-layerswitcher';
import { ScaleLine, defaults as defaultControls, ZoomToExtent, FullScreen } from 'ol/control.js';
import { LayerConfig } from "../config/LayerConfig";
import { Projections } from "../config/Projections.js";
import { Toolbar } from "../modules/Toolbar";
import Promise from 'promise-polyfill';
import LayerGroup from "ol/layer/Group";

import { Image as Image, Tile as Tile } from 'ol/layer';
import OSM from 'ol/source/OSM';
//import Stamen from 'ol/source/Stamen';

import ImageArcGISRest from 'ol/source/ImageArcGISRest';
import {LegendControl} from "../modules/LegendControl";
import {LayerUtils} from "../modules/LayerUtils";

const onresize = (dom_elem, callback) => {
    const resizeObserver = new ResizeObserver(() => callback());
    resizeObserver.observe(dom_elem);
};

export class MapPluginBase {

    constructor(options) {


        var projections = new Projections();
        this.mapViewProjection = projections.getProjectionByEpsg(options.epsg);
        this.options = options;
    }
    //"abstract" methods
    addSpecificControls() { }
    getNotes() { return null; }
    selectFeatures(data) { }
    setSpecificSelection(data, errCallback) { }
    enableSelection(enabled) { };
    getSelectedFeatures() { return null; }
    removeFromSelection() { }
    clearAll() { }
    onSelection() { }
    onDrawEnd() { }
    initDraw() { }
    exportMap() { return new Promise(resolve => { }); }
    exportMapAsFile(extent, dpi, format, georeferenced) {return new Promise(resolve => { }); }
    initSentinelTilesControl() { };
    updateSentinelTilesSelection() { };
    getSelectedSentinelTiles() { };
    getAllSentinelTiles() { };


    startEditing(data, callback) { };
    stopEditing(callback) { };
    getParcelInfo(data, callback) { };
    getParcelInfoByClick(data) { };
    getParcelInfoFromWFS(data, callback) { };
    getParcelInfoByClickFromWFS(data) { };
    sendToMap(data) { };
    onSelectionFromLayer(callback) { };
    onClearSelectionFromLayer(callback) { };
    getSelectedFeaturesFromLayer() { };
    clearSelectionFromLayer() { };
    unregisterOnSelectionFromLayer() { };
    selectFromLayer(data) { };
    transformCoordinate(coordinate,fromEpsg,toEpsg) {};
    getFeatureInfo(data,callback) {};
    filterWMSLayer(data) {};
    filterLayer(data) {};

    setSecurityTokenCallback(callback) {
        if (callback instanceof Function) {
            //this.securityTokenCallback = callback;
            this.map.securityTokenCallback = callback;

        }
        else {
            console.error('setSecurityTokenCallback: argument is not function!');
        }
    };

    initialize() {
        var self = this;

        var initialized = new Promise(function (resolve) {


            var viewExtent = self.options.extent;
            if (!viewExtent) viewExtent = self.mapViewProjection.getExtent();
            self.options['mapProjection'] = self.mapViewProjection;
            var layerConfig = new LayerConfig(self.options);
            var mapDiv = self.options.mapDiv;
            var mapDivEl = document.getElementById(mapDiv);
            if (!mapDivEl) {
                console.error("Mapdiv: '" + mapDiv + "' not exists!");
                resolve(false);
            }
            var layerPromise = layerConfig.getLayers();
            var center = [0, 0];

            if (viewExtent) center = getCenter(viewExtent);
            var mapControls = defaultControls(
                {
                    attributionOptions: { collapsible: false },
                    zoomOptions: {
                        zoomInLabel: '', zoomInTipLabel: 'Priblížiť',
                        zoomOutLabel: '', zoomOutTipLabel: 'Oddialiť'
                    }
                }
            ).extend([
                new ZoomToExtent({
                    extent: viewExtent,
                    label: '',
                    tipLabel: 'Zamerať celý rozsah'
                }),
                new FullScreen({ tipLabel: ' Zväčšiť na celú obrazovku' })
            ]);
            var mpu = self.mapViewProjection.getMetersPerUnit();
            var calculateResolution = function (scale) {
                return scale / (mpu * 39.3700787 * 96);
            }
            var maxRes = calculateResolution(self.options.maxScale);
            var minRes = calculateResolution(self.options.minScale);

            layerPromise.then(function (resp) {
                //TODO
                //self.setAutoRefresh(resp.overlays);
                self.layerConfig = resp;
                self.map = new Map({
                    controls: mapControls,
                    target: mapDiv,
                    // layers: [resp.basemaps, resp.overlays],
                    layers: resp.layerGroups,
                    view: new View({
                        projection: self.mapViewProjection,
                        center: center,
                        extent: viewExtent,
                        maxResolution: maxRes,
                        minResolution: minRes,
                      //  constrainResolution:true,
                        zoom: 8
                    })
                });



                self.map.getView().fit(viewExtent);

                self.toolbar = new Toolbar();
                self.map.addControl(self.toolbar);

                self.layerUtils = new LayerUtils(self.map,{});

                var layerSwitcher = new LayerSwitcher({
                     activationMode: 'click',
                     tipLabel: 'Mapové vrstvy',
                     collapseTipLabel: 'Zavrieť',
                    //startActive: true,
                    // groupSelectStyle: 'children' // Can be 'children' [default], 'group' or 'none'
                });

                layerSwitcher.button.className = "layers-toggle-button";

                self.map.addControl(layerSwitcher);
                self.map.getView().on('change:resolution', (evt)=>{
                    layerSwitcher.renderPanel();
                });
                //layerSwitcher.element
                const legendControl=new LegendControl(self.map, {element:layerSwitcher.element});

                layerSwitcher.on('show', _evt => {
                    if (legendControl.legendDiVisible) {
                        legendControl.toggleLegendPanel(false);
                        legendControl.togglePanelClass(false);
                        layerSwitcher.hidePanel();
                    } else {
                        layerSwitcher.button.className = "layers-toggle-button shown";
                        legendControl.render();
                        legendControl.toggleLegendButton(true);
                        legendControl.showSwitchLayersTitle();
                    }
                 });

                layerSwitcher.on('hide', _evt => {
                    layerSwitcher.button.className = "layers-toggle-button";
                    legendControl.legendDiVisible = false;
                    legendControl.toggleLegendButton(false);
                    legendControl.toggleLegendPanel(false);
                    legendControl.hideTitles();
                    legendControl.clearLegend();
                });

                self.map.addControl(new ScaleLine());

                self.addSpecificControls();
                //init done

                self.resizing = null;
                onresize(mapDivEl, function () {
                    if (self.resizing) {
                        clearTimeout(self.resizing);
                    }
                    self.resizing = setTimeout(() => {
                        self.map.updateSize();
                        self.resizing = null;
                    }, 200);
                });
                resolve(true);

            });
        });
        return initialized;
    }
    setAutoRefresh(layerGroup) {
        layerGroup.getLayers().forEach(lyr => {
            const refr = lyr.getProperties().refreshInterval;
            if (refr) {
                if (isNaN(refr)) {
                    console.error("Layer refresh interval is not number!");
                    return;
                }
                const callRefresh = () => setTimeout(() => {
                    const src = lyr.getSource();
                    if (src.getParams) {
                        let params = src.getParams();
                        params.time = new Date().getTime();
                        src.updateParams(params);
                    }
                    if (src.clear) {
                        src.clear();
                    }
                    src.refresh();
                    callRefresh();
                }, refr * 1000);
                callRefresh();
            }
        });
    }
    zoomToExtent(newExtent) {
        if (this.map) {
            this.map.getView().fit(newExtent);
        }
    }
    refreshMap() {
        if (this.map) {
            const e=this.map.getView().calculateExtent();
            const nE=[e[0],e[1]-0.1,e[2],e[3]-0.1];

            const refreshLayer = (lyr) => {
                const src = lyr.getSource();
                if (src && src.refresh) {
                    if (src instanceof ImageArcGISRest ) {
                        this.map.getView().fit(nE);
                    }
                    else
                    {
                        src.refresh();
                    }
                }
            };

            let getLayers = function(layers) {
                layers.forEach(lyr => {
                    if (lyr instanceof LayerGroup) {
                        getLayers(lyr.getLayers());
                    }
                    else {
                        if(lyr.isVisible) {
                            refreshLayer(lyr);
                        }
                    }
                });
            }
            const lyrs = this.map.getLayers();
            getLayers(lyrs);


        }
    }
    setLayerVisibility(layerId, visible) {
        this.layerUtils.setLayerVisibility(layerId, visible);
    }
    setLayersVisibility(layerIds, visible) {
        this.layerUtils.setLayersVisibility(layerIds, visible);
    }





}

