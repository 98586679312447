// @ts-nocheck
import {defaults as defaultControls, Control} from 'ol/control';
import {SelectionEvent} from './SelectionEvent';
import EsriJSON from 'ol/format/EsriJSON';

import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import ImageWMS from 'ol/source/ImageWMS';

import GeoJSON from 'ol/format/GeoJSON';
import LayerGroup from 'ol/layer/Group';
import Point from 'ol/geom/Point';
import {Image as ImageLayer, Vector as VectorLayer} from "ol/layer";
import {extend as extendExtent} from 'ol/extent';
import {unByKey} from "ol/Observable";
import WKT from "ol/format/WKT";
import WFS from "ol/format/WFS";
//@ts-ignore
import jsts from "jsts/dist/jsts.min.js";
import {transform} from "ol/proj";
import {Vector as VectorSource} from "ol/source";
import {Fill, Stroke, Style} from "ol/style";
import GML32 from 'ol/format/GML32';
import GML3 from 'ol/format/GML3';
import {
    and as andFilter,
    equalTo as equalToFilter
} from 'ol/format/filter';
import Intersects from 'ol/format/filter/Intersects';

require('es6-promise').polyfill();

export class CadastralInfoWFSControl extends Control {


    constructor(options,map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });


        this.parentControl = options.parentControl;
        this.cadastralInfoCfg=options.cadastralInfoWFSControl;
        this.element.setAttribute("style","display:none;");
        this.selectionLayer = new VectorLayer({
            name:"cadastralInfoWFSLayer",
            //always print
            print:true,
            source: new VectorSource({wrapX: false,crossOrigin: 'Anonymous'}),
            style:  new Style({
                stroke: new Stroke({
                    color: 'cyan',
                    width: 3
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 255, 0)'
                })
            })
        });
        this.filterKodKu="Code eq ${inputVal}&$select=Id";
        this.filterIdKu="Id eq ${inputVal}&$select=Code";
        map.addLayer(this.selectionLayer);
    }


    initialize() {
        if (this.initialized) return;
        var map = this.getMap();
        this.setSelectableLayers();
        this.initialized = true;
    }


    handleClick(event) {

        event.preventDefault();
        this.isActive = !this.isActive;

        if (this.isActive) {
            this.activate();
        }
        else {
            this.deactivate();
        }

    }

    tryActivate() {

        if (!this.isActive) {
            this.activate();
        }

    }

    setVisibility(visible) {
        if (this.isButtonVisible) return;
        if (visible) {
            if (!this.isActive) {
                this.activate();
            }
            this.element.style.display = "";
        }
        else {
            if (this.isActive) {
                this.deactivate();
            }
            this.element.style.display = "none";
        }
    }
    getParcelInfoByClickFromWFS(data) {
        this.activate();
        var map = this.getMap();
        this.selectionLayer.getSource().clear();
        var self = this;
        this.mapClickHandlerKey = map.on('singleclick', function (evt) {
            self.mapClickHandler(evt, data,true);
        });
    }
    activate() {
        this.isActive = true;
        //this.initialize();
        var map = this.getMap();
        var self = this;



        //this.element.classList.add("active");
        if (this.parentControl) this.parentControl.setActiveControl(this);

    }

    deactivate() {
        this.isActive = false;
        //this.element.classList.remove("active");
        if (this.parentControl) this.parentControl.unsetActiveControl();

        unByKey(this.mapClickHandlerKey);
        this.fitExtent =null;

    }
    mapClickHandler(evt,data,isSingleClick) {

        const view = this.getMap().getView();
        this.kuIdCodePairs={};
        var outSR = {wkid: view.getProjection().getCode().replace("EPSG:", "")};
        let parcType = "parcelC";
        if (data.register && data.register === 'E') parcType = "parcelE";
        const cfg = this.cadastralInfoCfg[parcType];

        const point = {x: evt.coordinate[0], y: evt.coordinate[1]};
        //var queryUrl=cfg.url + "&inSR=" + JSON.stringify(outSR)+"&geometryType=esriGeometryPoint&spatialRel=esriSpatialRelIntersects&geometry="+ JSON.stringify(point)+ "&outSR=" + JSON.stringify(outSR);
        const queryUrl = cfg.url + this.createFilterForClick(evt.coordinate, cfg);
        const self = this;
        if (isSingleClick) {
            //"enableSecurity":true,
            let options={
                method: 'GET'
            };
            options=self.enableSecurity(options);
            fetch(queryUrl,options).then(function (response) {
                return response.text();
            }).then(function (response) {

                self.parseWFSResponse([response], data, cfg);
            });

        this.deactivate();
        return true;
       }
       else {
            //"enableSecurity":true,
            let options={
                method: 'GET'
            };
            options=self.enableSecurity(options);
            return fetch(queryUrl,options).then(function (response) {
                return response.text();
            })
        }


    }
    findByPoint(data,errCallback){
        let parcType = "parcelC";
        if (data.register && data.register === 'E') parcType = "parcelE";
        const cfg = this.cadastralInfoCfg[parcType];
        var projection = this.getMap().getView().getProjection();
        const self=this;
        if(data.pointsGPS && data.pointsGPS.length) {
            const pointsFetch=[];
            data.pointsGPS.forEach(pnt=>{
                var p = transform(pnt, "EPSG:4326", projection.getCode());
                pointsFetch.push(this.mapClickHandler({coordinate: p}, data,false));
            });
            Promise.all(pointsFetch).then(responses=>{
                self.parseWFSResponse(responses, data, cfg, errCallback);
            });
        }
    }

    createFilterForClick(pnt,cfg) {
        const p=new Point(pnt);
        const filter= new Intersects(cfg.geometryField,p,
        this.getMap().getView().getProjection().getCode());
        const wfsRequest = new WFS().writeGetFeature({
            featureTypes: [cfg.typeName],
            filter: filter
        });
        //quick fix Task 32592:Chyba volania getParcelInfoFromWFS - chýba čiarka (DEV)
        //stara versia arcgis wfs
        const pos=wfsRequest.getElementsByTagName("pos")[0];
        pos.innerHTML=pos.innerHTML.replace(" ",",");

        const wfsFilter = new XMLSerializer().serializeToString(
            wfsRequest.getElementsByTagName("Filter")[0]);
        return wfsFilter.replaceAll("pos","coordinates",);
    }
    createFilter(parC,kuId,cfg) {
    //<ogc:Filter><ogc:FeatureId fid='parcela_registra_c.29511210'/></ogc:Filter>
      const fil= andFilter(
           equalToFilter(cfg.kuFieldName, kuId),
            equalToFilter(cfg.parcCisloFieldName,parC )
        )

        return fil;
    }

    createRequest(parC,kuId,cfg) {

        const featureRequest = new WFS().writeGetFeature({
            featureTypes: [cfg.typeName],
            filter: this.createFilter(parC,kuId,cfg)
        });
        return featureRequest;
    }
    parseInputFeatures(feats) {
        //"features":[{"parcelNumber":"1757/3","cadastralUnitCode":3262}]
        const promise=new Promise (resolve=> {
            const parsed = {};
            const ku = [];

            feats.forEach(f => {
                if (!parsed.hasOwnProperty(f.cadastralUnitCode)) {
                    parsed[f.cadastralUnitCode] = [];
                    ku.push(f.cadastralUnitCode);                }
                parsed[f.cadastralUnitCode].push(f.parcelNumber);
            });
            const self = this;
            const fetchs = ku.map((p) => {
                return self.getKuId(p, self.cadastralInfoCfg.kodKUUrl);
            });

            Promise.all(fetchs).then(res => {
                const newParsed = {};
                res.forEach((ids, i) => {
                        if (ids.value && ids.value.length) {
                            const id = ids.value[0].Id;
                            newParsed[id] = parsed[ku[i]];
                            self.kuIdCodePairs[id]=ku[i];
                        }
                    }
                );
                resolve(newParsed);
            });
        });

        return promise;
    }
    getParcelInfoFromWFS(data,errCallback) {
        const src=this.selectionLayer.getSource();
        src.clear();
        this.kuIdCodePairs={};

        if(data.pointsGPS && data.pointsGPS.length) {
            this.findByPoint(data,errCallback);
            return;
        }

        this.parseInputFeatures(data.features).then(parsedFeatures=> {

            const view = this.getMap().getView();
            this.selectionLayer.getSource().clear();

            var outSR = {wkid: view.getProjection().getCode().replace("EPSG:", "")};
            let parcType = "parcelC";
            if (data.register && data.register === 'E') parcType = "parcelE";
            const cfg = this.cadastralInfoCfg[parcType];
            const self = this;
            try {
                const fetchs=[];
                for (let key in parsedFeatures) {
                    if (parsedFeatures.hasOwnProperty(key)) {
                        let kuId = 0;
                            const kuFeats = parsedFeatures[key];
                            kuFeats.forEach(curFeat => {
                                const wfsRequest = this.createRequest(curFeat, key, cfg);
                                const wfsFilter = new XMLSerializer().serializeToString(
                                    wfsRequest.getElementsByTagName("Filter")[0]);
                                const wfsUrl = cfg.url + wfsFilter;
                                //"enableSecurity":true,
                                let options={
                                    method: 'GET'
                                };
                                options=self.enableSecurity(options);
                                fetchs.push(fetch(wfsUrl, options).then(function (response) {
                                    return response.text();
                                }));
                            });
                    }
                }
                Promise.all(fetchs).then(responses=>{
                    self.parseWFSResponse(responses, data, cfg, errCallback);
                });

            }
            catch (err) {
                if (errCallback) errCallback({status: "OtherError"});
            }
        });

    }
    parseWFSResponse(responses,data,cfg,errCallback) {


        this.fitExtent =null;
        const self = this;
        const view = this.getMap().getView();
        const src = this.selectionLayer.getSource();
        let wktData = [];
        responses.forEach((response)=> {
            var gmlFormat = new GML3({
                hasZ: false, multiSurface: false,
                "featureNS": cfg.featureNS,
                "featureType": cfg.typeName
            });
            var features = gmlFormat.readFeatures(response);

            if (features.length == 0) {
                if (errCallback) errCallback({status: "NotFoundError"});
            }
            if (features.length > 0) {
                features.forEach(f => {
                    if (data.zoomTo) {
                        var ext = f.getGeometry().getExtent();
                        self.fitExtent = self.fitExtent ? extendExtent(self.fitExtent, ext) : ext;
                    }
                    src.addFeature(f);
                    const returnTypes=(data && data.returnTypes)?returnTypes:["attributes"];
                    wktData.push(self.getResponseData(f, returnTypes));
                });
            }
        });

        if (data.zoomTo && self.fitExtent) view.fit(self.fitExtent);
        self.assignKuCode(wktData).then(newWktData=> {
            self.dispatchEvent(new SelectionEvent('select',newWktData ));
        });
    }
    getResponseData(feature,returnTypes){


       const g=  feature.getGeometry();
        let coords = g.getCoordinates();
        coords.forEach(c=>{
            c.forEach(cc=> {
            cc.forEach(z=> {
                if (z.length === 3) {
                    z.pop();
                }
            });
            });
        });
        g.setCoordinates(coords);

        const wkt = new WKT();
        var jstsReader = new jsts.io.WKTReader();
        var wktFeature = wkt.writeFeature(feature);
        let attributes={};
        let strWkt;
        var geom = jstsReader.read(wktFeature);
        var jstsWriter = new jsts.io.WKTWriter();
        var projection = this.getMap().getView().getProjection();
        let feat={};

        returnTypes.forEach(returnType=>{
            switch (returnType) {
            case "geometry":
                feat.geometry= wktFeature;
                break;
            case "centroid":
                feat.centroid=jstsWriter.write(geom.getCentroid());
                break;
            case "centroidGPS":
                var c=geom.getCentroid();
                var p = transform([c.getX(),c.getY()], projection.getCode(), "EPSG:4326");
                feat.centroidGPS="POINT("+p[0]+" "+p[1]+")";
                break;
            case "attributes":
                attributes=feature.getProperties();
                delete attributes.geometry;
                delete attributes.geometria;
                feat.attributes=attributes;
                break;
            case "lvUrl":
                attributes=feature.getProperties();
                feat.lvUrl=this.cadastralInfoCfg.lvUrl.replace("${prfId}",attributes["FOLIO_ID"]);
                break;

            }
        });
        return feat;
    }

    clearAll() {
        if (!this.selectableLayers) return;
        this.selectableLayers.forEach(lyr => {
            this.clearSelection(lyr);
    });
    }

    clearSelection(selectionConfig) {
        var deletes = [];
        if (!selectionConfig || !selectionConfig.data) return;
        selectionConfig.data.forEach(function (oldFeature) {
            deletes.push(oldFeature);
        });

        this.updateSelection([], deletes, selectionConfig);
    }
    enableSecurity(opt) {
        if(this.cadastralInfoCfg.enableSecurity) {
            //token
            let token = null;
            const map=this.getMap();
            if (map.securityTokenCallback) {
                token = map.securityTokenCallback();
            }
            const options=  {
                ...opt,
                mode: 'cors',
                headers: {
                    'Authorization':'Bearer '+token
                }
            }
            return options
        }
        else {
            return opt;
        }
    }
    getKuId(val,urlKU,isReverse) {
        let filter=this.filterKodKu.replace("${inputVal}",val);
        if (isReverse) filter=this.filterIdKu.replace("${inputVal}",val);
       const url=urlKU+ filter;
       //"enableSecurity":true,
          let options={
              method: 'GET'
          };
          options=this.enableSecurity(options);
          return fetch(url, options).then(response => response.json());

    }
    assignKuCode(wktData) {
        const self=this;

        const promise=new Promise (resolve=> {
            if(wktData.length) {
                if(!wktData[0].hasOwnProperty("attributes")) resolve(wktData);
                if (this.kuIdCodePairs && Object.keys(this.kuIdCodePairs).length) {
                    wktData.forEach(f => {
                        const idKu=f.attributes["CADASTRAL_UNIT_ID"];
                        f.attributes["CADASTRAL_UNIT_CODE"]=self.kuIdCodePairs[idKu];
                    });
                    resolve(wktData);
                }
                else {
                    const fetchs=[];
                    const kuIds=[];
                    wktData.forEach(f => {
                        const idKu=f.attributes["CADASTRAL_UNIT_ID"];
                        if(!kuIds.includes(idKu)) {
                            fetchs.push(self.getKuId(idKu, self.cadastralInfoCfg.kodKUUrl, true));
                            kuIds.push(idKu);
                        }

                    });
                    const kuIdCodePairs={};
                    Promise.all(fetchs).then((res)=>{
                        res.forEach((codes, i) => {
                                if (codes.value && codes.value.length) {
                                    const code = codes.value[0].Code;
                                    kuIdCodePairs[kuIds[i]]=code;
                                }
                            });

                        wktData.forEach(f => {
                            const idKu=f.attributes["CADASTRAL_UNIT_ID"];
                            f.attributes["CADASTRAL_UNIT_CODE"]=kuIdCodePairs[idKu];
                        });
                        resolve(wktData);
                    });

                }
            }
            else {
                resolve(wktData);
            }
        });
        return promise;
    }


}

/*<sld:StyledLayerDescriptor xmlns:sld="http://www.opengis.net/sld" version="1.0.0" xsi:schemaLocation="http://www.opengis.net/sld http://schemas.opengis.net/sld/1.0.0/StyledLayerDescriptor.xsd" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:ogc="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml"><sld:NamedLayer><sld:Name>5</sld:Name><sld:NamedStyle><sld:Name/></sld:NamedStyle><sld:UserStyle><sld:Name>style_sld_body</sld:Name><sld:FeatureTypeStyle><sld:Rule><ogc:Filter xmlns:ogc="http://www.opengis.net/ogc"><ogc:PropertyIsEqualTo><ogc:PropertyName>ID</ogc:PropertyName><ogc:Literal>39614817</ogc:Literal></ogc:PropertyIsEqualTo></ogc:Filter><sld:PolygonSymbolizer><sld:Stroke><sld:CssParameter name="stroke">#00FFFF</sld:CssParameter><sld:CssParameter name="stroke-opacity">1</sld:CssParameter><sld:CssParameter name="stroke-width">3</sld:CssParameter></sld:Stroke></sld:PolygonSymbolizer></sld:Rule></sld:FeatureTypeStyle></sld:UserStyle></sld:NamedLayer></sld:StyledLayerDescriptor>*/
//sld