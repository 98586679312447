// @ts-nocheck
import {defaults as defaultControls, Control} from 'ol/control';
import {defaults as defaultInteractions, Draw, Modify, Snap, Select} from 'ol/interaction';

import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {listen} from 'ol/events';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {click} from 'ol/events/condition';
import {Circle as CircleStyle,Icon, Fill, Stroke, Style, Text} from 'ol/style';
import Feature from 'ol/Feature';
import {extend, getCenter} from 'ol/extent';
import Point from "ol/geom/Point";
import {unByKey} from "ol/Observable";
import {MapClickEvent} from "./MapClickEvent";
import Intersects from 'ol/format/filter/Intersects';
import {WFS, GeoJSON} from 'ol/format.js';
import {transform} from 'ol/proj';

var iconData="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pgo8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDIwMDEwOTA0Ly9FTiIKICJodHRwOi8vd3d3LnczLm9yZy9UUi8yMDAxL1JFQy1TVkctMjAwMTA5MDQvRFREL3N2ZzEwLmR0ZCI+CjxzdmcgdmVyc2lvbj0iMS4wIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiB3aWR0aD0iMTYuMDAwMDAwcHQiIGhlaWdodD0iMjQuMDAwMDAwcHQiIHZpZXdCb3g9IjAgMCA3OTQuMDAwMDAwIDExNzYuMDAwMDAwIgogcHJlc2VydmVBc3BlY3RSYXRpbz0ieE1pZFlNaWQgbWVldCI+CjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLDExNzYuMDAwMDAwKSBzY2FsZSgwLjEsLTAuMSkiCmZpbGw9IiNGRkE1MDAiIHN0cm9rZT0ibm9uZSI+CjxwYXRoIGQ9Ik0zNzMwIDEwMzQzIGMtMTc5IC0xMyAtNDU3IC02NyAtNjQ1IC0xMjUgLTcyMiAtMjIxIC0xMzI1IC03MTgKLTE2OTUgLTEzOTggLTE1MSAtMjc2IC0yNTQgLTU5OCAtMzA3IC05NTUgLTI1IC0xNzEgLTI1IC01OTUgMCAtNzUzIDI4IC0xNzUKNjQgLTMyNSAxMTQgLTQ3MiA4MCAtMjM2IDEzNCAtMzQ4IDM4NCAtNzk3IDE3OCAtMzIwIDMwOSAtNTMzIDU2OSAtOTMzIDcwMAotMTA3NCA5MzggLTE0ODAgMTIxOSAtMjA3NiAyNTIgLTUzNSA0ODAgLTExNTEgNTkxIC0xNTk0IDEyIC00NyAyMyAtODYgMjUKLTg4IDEgLTEgMTAgMjggMTkgNjUgOTMgMzc4IDI4NiA5MjkgNDgxIDEzNjggMjQ3IDU1NiA2MTggMTIyMyAxMDQwIDE4NzAgNTI4CjgwOCA2MTIgOTQwIDc0NCAxMTcwIDEwMCAxNzQgMzc3IDcyMSA0MzEgODUwIDEyMCAyOTAgMTg1IDU4OSAxOTcgOTEwIDIxIDU4MwotMTM1IDExNTYgLTQ0MyAxNjI1IC0yMzQgMzU4IC00ODEgNjE0IC03OTkgODI5IC00MTcgMjgyIC04OTAgNDQ3IC0xNDM0IDUwMQotOTUgOSAtMzg1IDExIC00OTEgM3ogbTU0NSAtMTU5OCBjNzEyIC0xODQgMTAyOCAtMTAwOCA2MjAgLTE2MjAgLTMyMSAtNDgzCi05ODYgLTYxNSAtMTQ2OCAtMjkyIC0yMzEgMTU1IC0zOTMgMzkzIC00NTMgNjY1IC0yNiAxMTYgLTI0IDM0MSA0IDQ1NSA3OAozMTggMjc2IDU2OCA1NjQgNzEzIDkyIDQ2IDIyMSA4OCAzMjMgMTA0IDEwNCAxNiAyOTcgNCA0MTAgLTI1eiIvPgo8L2c+Cjwvc3ZnPgo=";
var styles = {

    "default": [

        new Style({
            image: new Icon({
                crossOrigin: 'anonymous',
                src: iconData,
                displacement: [0, 10],
                fill: new Fill({
                    color: 'orange'
                })
            })

        })],
    "selected": [

        new Style({
            image: new CircleStyle({
                radius: 5,
                fill: new Fill({
                    color: 'orange'
                })
            }),
            geometry: function (feature) {

                var g = feature.getGeometry();

                if (g.getType() == 'Point') {
                    return g;
                }
            }
        })]
};

export class DrawPOIControl extends Control {

    constructor(options,map) {

        super({
            element: document.createElement('div'),
            target: options.target
        });
        this.parentControl = options.parentControl;
        this.map=map;
        this.outputType='ROTZ';

        this.drawTypeMappings = {'point': 'Point'};
        this.wfsOptions=options.drawPOIControl.wfsOptions;


        this.drawLayer = new VectorLayer({
            name:"drawPOILayer",
            //always print
            print:true,
            source: new VectorSource({wrapX: false,crossOrigin: 'Anonymous'}),
            style: styles["default"]

        });
        this.drawPointStyle={'circle-radius': 5,
            'circle-fill-color': 'orange'}

        map.addLayer(this.drawLayer);


    }
    renderTool() {
        if(this.rendered) {
            this.setToolsVisibility(true);
            return;
        }
        this.element.className = 'draw-poi-control';
        this.toolDivs={};
        var self = this;

        var noteTypes = ['point','modify', 'delete'];
        noteTypes.forEach(nt => {
            switch (nt) {
                case 'point':
                    this.createTool(nt, 'Nakresliť bod');
                    break;
                case 'delete':
                    this.createTool(nt, 'Vymazať', 'D');
                    break;
            }
        }, this);
        this.rendered=true;
    }
    setToolsVisibility(visible){
       this.element.style.display=visible?"block":"none";

    }
    stopDraw() {
        if (this.draw) {
            this.map.removeInteraction(this.draw);
            this.draw = null;
        }
        if (this.snap) {
            this.map.removeInteraction(this.snap);
        }
        if(this.drawStartHndKey ) {
            unByKey(this.drawStartHndKey );
            this.drawStartHndKey=null;
        }
        if(this.addFeatureEvtHndKey) {
            unByKey(this.addFeatureEvtHndKey );
            this.addFeatureEvtHndKey=null;
        }
        if (window.snapControl) {
            window.snapControl.deactivate();
        }
        this.reset();
    }
    reset() {
        this.drawLayer.getSource().clear();

        if(this.rendered) {
            this.deactivate();
            this.setToolsVisibility(false);
        }

    }
    init(data,callback){
        this.callback=callback;
        this.reset();
        if(data){
            this.outputType=data.output?data.output:'ROTZ';

            var pnt=data.point;
            if(pnt) {
                var jtskPnt=transform(pnt,"EPSG:4326","EPSG:5514");
                var p=new Point(jtskPnt);
                var feat=new Feature(                    {
                    geometry:p
                    });

                this.drawLayer.getSource().addFeature(feat);
                var view=this.getMap().getView();
                view.setCenter(p.getCoordinates());
                view.setZoom(9);
            }
            if(data.initType==="create") {
                this.renderTool();
                this.btnToolClicked({}, "point");
            }
            if(data.initType==="modify") {
                this.renderTool();
            }
            if(data.initType==="display") {

            }
            if(data.initType==="click") {
                this.activate();
                this.initClickTool();
            }
            if(data.initType==="mapClick") {
                this.activeTool="mapClick";
                this.activate();
                this.initMapClickTool();
            }
        }
    }
    initMapClickTool() {
        const self=this;
      /*  if(this.mapClickHandlerKey ) unByKey(this.mapClickHandlerKey );
        this.mapClickHandlerKey = this.map.on('click', function (evt) {
            self.drawLayer.getSource().clear();
            const pnt=new Feature();
            pnt.setGeometry(evt.coordinate ? new Point(evt.coordinate) : null);

            self.drawLayer.getSource().addFeature(pnt);
            self.dispatchEvent(new MapClickEvent('drawPOIclicked',evt.coordinate));
        });*/
        this.draw = new Draw({
            source:  self.drawLayer.getSource(),
            type: 'Point',
            style:self.drawPointStyle
        });
        this.drawStartHndKey =this.draw.on('drawstart', function (evt) {
            self.drawLayer.getSource().clear();
        });
        self.map.addInteraction(this.draw);
        if (window.snapControl) {
            window.snapControl.activate();
        }
        this.addFeatureEvent();
    }
    initClickTool() {
        const self=this;
        if(this.mapClickHandlerKey ) unByKey(this.mapClickHandlerKey );
        this.mapClickHandlerKey = this.map.on('singleclick', function (evt) {
            self.mapClickHandler(evt)
        });
    }
    mapClickHandler(evt) {

        var outPnt=transform(evt.coordinate,"EPSG:5514","EPSG:4326");
        var outData={point:outPnt,KU:null};
        if (this.outputType==="VIS") {
            outData={longitude: outPnt[0], latitude: outPnt[1]};
        }
        this.callback( outData);

    }
    createTool(toolType, tooltip) {

        var div = document.createElement('div');
        this.toolDivs[toolType]=div;
        var toolClass = 'draw-poi-control-tool';
        var className = toolClass + ' ' + 'draw-poi-control-' + toolType;
        var button = document.createElement('button');

        var tipLabel = tooltip;
        button.setAttribute('type', 'button');
        button.title = tipLabel;
        /*button.appendChild(
            typeof label === 'string' ? document.createTextNode(label) : label
        );*/

        listen(button, EventType.CLICK, function (evt) {
                this.btnToolClicked(evt, toolType);
            }, this
        );
        var cssClasses = className + ' ' + CLASS_UNSELECTABLE + ' ' + CLASS_CONTROL;

        div.className = cssClasses;
        div.appendChild(button);
        this.element.appendChild(div);
    }

    btnToolClicked(evt, toolType) {


        if(this.activeTool==toolType){
            this.deactivate();
            return;
        }

        this.deactivateTool();
        if (toolType == 'delete') {
            this.drawLayer.getSource().clear();
            this.callback({point:null,KU:null});
            this.btnToolClicked({}, "point");
            return;
        }
        var map = this.getMap();
        var self = this;


        this.addFeatureEvent();
        var src = this.drawLayer.getSource();


        this.removeMapInterections();

        this.activeTool=toolType;
        this.activate();
        this.addMapInteraction(toolType, src);


    }
    addFeatureEvent() {
        const self=this;
        if(!this.addFeatureEvtHndKey) {

            this.addFeatureEvtHndKey = this.drawLayer.getSource().on('addfeature', function (evt) {
                self.featureCreated(evt);
            });
            /*  this.drawLayer.getSource().on('changefeature',function(evt) {
                 // self.featureChanged(evt);
              });*/
        }
    }
    addMapInteraction(toolType, source) {

        var map = this.getMap();

        var self = this;
        var drawType = this.drawTypeMappings[toolType];
        if (drawType) {
            this.draw = new Draw({
                source: source,
                type: drawType,
                style:self.drawPointStyle
            });
            this.drawStartHndKey =this.draw.on('drawstart', function (evt) {
                self.drawLayer.getSource().clear();
            });

            map.addInteraction(this.draw);
        }

    }

    removeMapInterections() {

        var map = this.getMap();
        if (this.draw) {
            map.removeInteraction(this.draw);
            this.draw = null;
        }
        if( this.drawStartHndKey ) {
            unByKey(this.drawStartHndKey );
            this.drawStartHndKey=null;
        }
        if(this.drawSnapControlHndKey){
            unByKey(this.drawSnapControlHndKey);
            this.drawSnapControlHndKey=null;
        }
        if (this.select) {
            map.removeInteraction(this.select);
            this.select = null;
        }


    }


    featureCreated(evt) {

        if (this.activeTool="mapClick") {
            this.dispatchEvent(new MapClickEvent('drawPOIclicked',evt.feature.getGeometry()));
            return;
        }
        var self = this;

        var geom=evt.feature.getGeometry();
        this.deactivate();
        if(this.wfsOptions) {
            this.getKU(geom);
        }
        else {
            var outPnt=transform(geom.getCoordinates(),"EPSG:5514","EPSG:4326");

            self.callback({point:outPnt,KU:null});
        }

    }

    getKU(geometry) {
        var self=this;
        var fieldName=this.wfsOptions.fieldName;
        var qFilter=new Intersects(this.wfsOptions.geometryName, geometry, this.wfsOptions.srsName)
        var featureRequest = new WFS().writeGetFeature({
            srsName: this.wfsOptions.srsName,
            featureNS:this.wfsOptions.featureNS,
            featurePrefix:this.wfsOptions.featurePrefix,
            featureTypes: this.wfsOptions.featureTypes,
            outputFormat: this.wfsOptions.outputFormat,
            filter: qFilter
        });
        var outPnt=transform(geometry.getCoordinates(),"EPSG:5514","EPSG:4326");

        var body= new XMLSerializer().serializeToString(featureRequest);

        fetch(this.wfsOptions.url, {
            method: 'POST',
            body: body
        }).then(response=> {
            if (!response.ok) {
                throw new Error('');
            }
            return response.json();
        }).then(json=> {

            var features = new GeoJSON().readFeatures(json);
            if(features.length) {
                var feature = features[0];
                var props=feature.getProperties();
                var fieldValue="";
                for (var p in props) {
                    if(p===fieldName) {
                        var fieldValue=props[p];

                    }
                }
               self.callback({point:outPnt,KU:fieldValue});

            }
        }).catch((error) => {
            console.info("DrawPOIControl: KU not found.");
            self.callback({point:outPnt,KU:null});

        });
    }
    activate() {
        if (this.parentControl) {
            if (!(this.parentControl.activeControl instanceof DrawPOIControl)) {
                this.parentControl.setActiveControl(this);
            }
        }
        if(this.toolDivs) {
            var div = this.toolDivs[this.activeTool];

            if (div) {
                div.classList.add("active");
            }
        }


    }

    deactivate() {

        if(this.mapClickHandlerKey ) {unByKey(this.mapClickHandlerKey );}
        this.removeMapInterections();
        if (this.addFeatureEvtHndKey) {
            unByKey(this.addFeatureEvtHndKey);
            this.addFeatureEvtHndKey=null;
        }
        if (this.popup) this.popup.hide();
        this.deactivateTool();
        if (this.parentControl)
            if ((this.parentControl.activeControl instanceof DrawPOIControl)) {
                this.parentControl.unsetActiveControl();
            }

    }
    deactivateTool() {
       if(this.toolDivs) {
           var div = this.toolDivs[this.activeTool];

           if (div) {
               div.classList.remove("active");
           }
       }
        this.activeTool=null;
    }

}