// @ts-nocheck



import proj4 from 'proj4';
import {register} from 'ol/proj/proj4';
import {get as getProjection} from 'ol/proj';
export const projectionsDefs={
    'EPSG:3857':{displayName:'3857',wkt:'PROJCS["WGS_1984_Web_Mercator_Auxiliary_Sphere",GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137.0,298.257223563]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.017453292519943295]],PROJECTION["Mercator_Auxiliary_Sphere"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Central_Meridian",0.0],PARAMETER["Standard_Parallel_1",0.0],PARAMETER["Auxiliary_Sphere_Type",0.0],UNIT["Meter",1.0]]'},
    'EPSG:4326':{displayName:'WGS84',wkt:'GEOGCS["GCS_WGS_1984",DATUM["D_WGS_1984",SPHEROID["WGS_1984",6378137,298.257223563]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]]'},
    'EPSG:5514':{displayName:'S-JTSK',wkt:'PROJCS["S_JTSK_Krovak_East_North",GEOGCS["GCS_S_JTSK",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0.0],UNIT["Degree",0.0174532925199433]],PROJECTION["Krovak"],PARAMETER["False_Easting",0.0],PARAMETER["False_Northing",0.0],PARAMETER["Pseudo_Standard_Parallel_1",78.5],PARAMETER["Scale_Factor",0.9999],PARAMETER["Azimuth",30.28813975277778],PARAMETER["Longitude_Of_Center",24.83333333333333],PARAMETER["Latitude_Of_Center",49.5],PARAMETER["X_Scale",-1.0],PARAMETER["Y_Scale",1.0],PARAMETER["XY_Plane_Rotation",90.0],UNIT["Meter",1.0]]'},
    'EPSG:5513':{displayName:'JTSK',wkt:'PROJCS["S_JTSK_Krovak",GEOGCS["GCS_S-JTSK",DATUM["D_S_JTSK",SPHEROID["Bessel_1841",6377397.155,299.1528128]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]],PROJECTION["Krovak"],PARAMETER["latitude_of_center",49.5],PARAMETER["longitude_of_center",24.83333333333333],PARAMETER["azimuth",30.28813972222222],PARAMETER["pseudo_standard_parallel_1",78.5],PARAMETER["scale_factor",0.9999],PARAMETER["false_easting",0],PARAMETER["false_northing",0],UNIT["Meter",1]]'},
    'EPSG:4258':{displayName:'4258',wkt:'GEOGCS["ETRS89",DATUM["D_ETRS_1989",SPHEROID["GRS_1980",6378137,298.257222101]],PRIMEM["Greenwich",0],UNIT["Degree",0.017453292519943295]]'}
};
export  class Projections {



    constructor() {

        this.defaultProjection=getProjection('EPSG:3857');
       //SJTSK
        var prj=getProjection('EPSG:5514');
        var projStr;
        if(!prj ) {
            projStr = '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +pm=greenwich +units=m +no_defs +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56'
            proj4.defs('EPSG:5514', projStr)
            register(proj4);
        }
        prj=getProjection('EPSG:5513');
        if(!prj ) {
            projStr = '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs';
            proj4.defs('EPSG:5513', projStr)
            register(proj4);
        }

        prj=getProjection('EPSG:4258');
        if(!prj ) {
            projStr="+proj=longlat +ellps=GRS80 +no_defs +type=crs";
            //projStr = '+proj=sterea +lat_0 = 52.15616055555555 +lon_0=5.38763888888889 +k = 0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +nadgrids=rdtrans2008.gsb +geoidgrids=naptrans2008.gtx +units=m +no_defs +type=crs';
            proj4.defs('EPSG:4258', projStr)
            register(proj4);
        }
        prj=getProjection('EPSG:3035');
        if(!prj ) {
            projStr = "+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs";
            proj4.defs('EPSG:3035', projStr)
            register(proj4);
        }

    }
    getProjectionByEpsg(epsg) {
        //if (epsg==='10267') epsg='5514';
        var prj=getProjection('EPSG:'+epsg);
        if (!prj) prj=this.defaultProjection;

        return  prj;

    }

}