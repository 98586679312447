// @ts-nocheck

import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import WKT from 'ol/format/WKT';
import {fromExtent} from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import {transform} from "ol/proj";
import {extend as extendExtent} from "ol/extent";
import {toStringHDMS} from 'ol/coordinate';

export class ZoomUtils {
    constructor(map, options) {
        this.map = map;
        this.options = options;
        this.fitExtent = null;
        this.zoomUtilsLayer = new VectorLayer({
            name: "zoomUtilsLayer",
            //always print
            print: true,
            source: new VectorSource({wrapX: false, crossOrigin: 'Anonymous'}),
            style: new Style({
                stroke: new Stroke({
                    color: 'cyan',
                    width: 3
                }),
                fill: new Fill({
                    color: 'rgba(0, 0, 255, 0)'
                }),
                image: new CircleStyle({
                    radius: 7,
                    fill: new Fill({
                        color: 'cyan',
                    }),
                })
            })
        });
        map.addLayer(this.zoomUtilsLayer);
    }

    sendToMap(data) {
        this.fitExtent = null;
        this.zoomUtilsLayer.getSource().clear();
        if (!data.value) return;
        if (!Array.isArray(data.value)) {
            data.value = [data.value];
        }
        var self = this;
        data.value.forEach(val => {
            const feat = this.parseGeometry(val, data.type);
            var ext = feat.getGeometry().getExtent();
            self.fitExtent = self.fitExtent ? extendExtent(self.fitExtent, ext) : ext;
            if (data.srid) {
                var projection = self.map.getView().getProjection();
                const geom = feat.getGeometry();
                const newGeom = geom.transform("EPSG:" + data.srid, projection.getCode());
                feat.setGeometry(newGeom);
            }
            if (data.showInMap !== false) {
                self.zoomUtilsLayer.getSource().addFeature(feat);
            }
        });
        if (data.zoomTo !== false) {
            self.zoomToExtent(self.fitExtent);
        }
    }

    parseGeometry(dataVal, dataType) {

        let feat;
        switch (dataType) {
            case "WKT":
                const wkt = new WKT();
                feat = wkt.readFeature(dataVal);
                break;
            case "Extent":
                const geom = fromExtent(dataVal);
                feat = new Feature({
                    geometry: geom
                });
                break;
        }
        return feat;
    }

    zoomToExtent(newExtent) {
        if (this.map) {
            this.map.getView().fit(newExtent);
        }
    }

    transformCoordinate(data) {
        console.log('transformCoordinate()', data);
        /* S-JTSK (EPSG: 5514): v metroch na 3 desatinné miesta
- WGS84 (EPSG: 4326): stupňe - minúty - sekundy
- ETRS89 (EPSG: 3035): v metroch na 3 desatinné miesta*/
        let coordinate = data.coordinate;
        const fromEpsg = data.fromEpsg;
        const toEpsg = data.toEpsg;

        if (coordinate && fromEpsg && toEpsg) {
            switch (fromEpsg) {
                case "4326":
                case "4258":
                    coordinate = [coordinate[1], coordinate[0]];
                    break;

            }
            let outCoord = transform(coordinate, "EPSG:" + fromEpsg, "EPSG:" + toEpsg);
            let formatted;
            switch (toEpsg) {
                case "4326":
                case "4258":
                    formatted = {
                        "outputValue": [Number.parseFloat(outCoord[1].toFixed(5)), Number.parseFloat(outCoord[0].toFixed(5))],
                        "formated": toStringHDMS(outCoord)
                    };
                    break;
                default:
                    formatted = {"outputValue": [Number.parseFloat(outCoord[0].toFixed(3)), Number.parseFloat(outCoord[1].toFixed(3))]};

            }
            return {"succes": true, "coordinate": formatted};
        }
        return {"succes": false, "errMsg": "Missing input"};

    }
}