// @ts-nocheck

import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {listen} from 'ol/events';
import Event from 'ol/events/Event';
import EventType from 'ol/events/EventType';
import {CLASS_CONTROL, CLASS_UNSELECTABLE} from 'ol/css';
import {click} from 'ol/events/condition';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style';
import MultiPoint from 'ol/geom/MultiPoint';
import WKT from 'ol/format/WKT';
import {fromExtent} from 'ol/geom/Polygon';
import Feature from 'ol/Feature';
import {transform, transformExtent} from "ol/proj";
import {extend as extendExtent} from "ol/extent";
import LayerGroup from "ol/layer/Group";
import ImageWMS from "ol/source/ImageWMS";
import ImageArcGISRest from "ol/source/ImageArcGISRest";
import {buffer} from 'ol/extent';

export class LayerUtils {

    constructor(map, options) {
        this.map = map;
        this.options = options;

    }
    doFilterWMS(wmsLayerSrc,data) {
        if(!data.filter) {
            var params=wmsLayerSrc.getParams();
            if (params.hasOwnProperty("cql_filter")) {
                delete params.cql_filter;

            }
            wmsLayerSrc.updateParams(params);
            return;
        }
        wmsLayerSrc.updateParams({"cql_filter":data.filter});
        if (data.extent) {
            this.map.getView().fit(data.extent);
        }
    }
    doFilterEsriRest (layerSrc,data) {
        if(!data.filter) {
            var params=layerSrc.getParams();
            if (params.hasOwnProperty("layerDefs")) {
                delete params.layerDefs;
            }
            layerSrc.updateParams(params);
            this.forceRefresh();
            return;
        }
        layerSrc.updateParams({"layerDefs":data.filter});
        if (data.extent) {
            this.map.getView().fit(data.extent);
        }
        else {
             this.forceRefresh();
            //layerSrc.refresh();
        }
    }
    forceRefresh(){
        //workaround pre nefunkcny refresh v OL 8.1
        const e=this.map.getView().calculateExtent();
        const nE=[e[0],e[1]-0.1,e[2],e[3]-0.1];
        this.map.getView().fit(nE)
    }
    filterEsriRestLayer(data) {
        const self = this;
      //  const lyrs = this.map.getLayers();
        const filteredLayer = self.getLayerById(data.layerId);
        if (filteredLayer instanceof ImageArcGISRest) {
            self.doFilterEsriRest(src, data);
        }
    }
    filterLayer(data) {
        //{"layerId":11,"filter":"ContractId eq 32"}
        const self = this;

        const filteredLayer = self.getLayerById(data.layerId);
        if(!filteredLayer)  {
            console.log("Layer id: "+data.layerId +" not found!");
            return;
        }
        const src = filteredLayer.getSource();
        if (src instanceof ImageArcGISRest) {
            self.doFilterEsriRest(src, data);
        }
        if (src instanceof ImageWMS) {
            self.doFilterWMS(src, data);
        }

    }
    filterWMSLayer(data) {
        const self=this;
        const lyrs = this.map.getLayers();
        const filteredLayer=self.getLayerById(data.layerId);
        if (!filteredLayer) return;
        const src = filteredLayer.getSource();
        if (src instanceof ImageWMS) {
            self.doFilterWMS(src, data);
        }
        /*
        let filterLayer = function (lyr) {
            if(lyr.getProperties().layerId===data.layerId) {
                const src = lyr.getSource();
                if (src instanceof ImageWMS) {
                    self.doFilterWMS(src, data);
                }
            }
        }
        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    filterLayer(lyr);
                }
            });
        }
        getLayers(lyrs);
        */
    }
    getVectoreLayerByIds(layerIds) {
        const self=this;
        const lyrs = self.map.getLayers();
        let filteredLayers=[];
        let filterLayer = function (lyr) {
            if(layerIds && layerIds.indexOf &&  layerIds.indexOf(lyr.getProperties().layerId)!=-1) {
                const src = lyr.getSource();
                if (src instanceof VectorSource) {
                    filteredLayers.push(lyr);
                }
            }
        }
        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    filterLayer(lyr);
                }
            });
        }
        getLayers(lyrs);
        return filteredLayers;
    }
    getLayerById(layerId) {
        const self=this;
        const lyrs = self.map.getLayers();
        let filteredLayer;
        let filterLayer = function (lyr) {
            if(lyr.getProperties().layerId==layerId) {
                filteredLayer=lyr;
            }
        }
        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    filterLayer(lyr);
                }
            });
        }
        getLayers(lyrs);
        return filteredLayer;
    }
    addLayerById(layerId){
        console.log("addLayerById: "+layerId);
    }

    setLayerVisibility(layerId, visible){
        const layer = this.getLayerById(layerId);
        if(layer && layer.getVisible() !== visible){
            layer.setVisible(visible);
            this.map.getView().dispatchEvent('change:resolution');
        }
    }

    setLayersVisibility(layerIds, visible){
        layerIds.forEach(layerId => {
            const layer = this.getLayerById(layerId);
            if(layer && layer.getVisible() !== visible){
                layer.setVisible(visible);
            }
        });
        this.map.getView().dispatchEvent('change:resolution');
    }

    getAllLayers() {
        const self=this;
        const lyrs = self.map.getLayers();
        let allLyrs=[];

        let getLayers = function(layers) {
            layers.forEach(lyr => {
                if (lyr instanceof LayerGroup) {
                    getLayers(lyr.getLayers());
                }
                else {
                    allLyrs.push(lyr);
                }
            });
        }
        getLayers(lyrs);
        return allLyrs;
    }

}