// @ts-nocheck

import Geocoder from './ol-geocoder/ol-geocoder-yms';
import {
    like as likeFilter,
    or as orFilter
} from 'ol/format/filter.js';
import {WFS, GeoJSON} from 'ol/format.js';
import {getCenter} from 'ol/extent';
import EsriJSON from "ol/format/EsriJSON";

export class EsriSearchProvider {

    constructor(options) {
        this.url = options.url;
        this.esriOptions=options.esriOptions;
        this.resultRecordCount=options.limit;
    }

    createFilter(phrase) {
        var conditions=[];
        this.esriOptions.queryFields.forEach(fld=>{

            conditions.push("lower(" +fld +") like '%"+phrase +"%'");
        });
        if (conditions.length==0) {
            return null;
        }
        if (conditions.length==1) {
            return conditions[0];
        }
        else {
            return conditions.join(' or ');
        }


    }
    getQueryRequest(phrase) {

        var where=this.createFilter(phrase);
        const urlParams= {
            where:where,
            returnGeometry:true,
            outFields:'*',
            f:'json',
            resultRecordCount: this.resultRecordCount
        }

        return urlParams;


    }
    getParameters(opt) {
        const urlParams=this.getQueryRequest(opt.query);
        return {
            url:this.url,
            method:'GET',
            callbackName: 'callback',
            params:urlParams
        };
    }



    handleResponse(results) {

        var displayPattern= this.esriOptions.displayPattern;
        var features = new EsriJSON ().readFeatures(results);
        var srs=this.esriOptions.srsName;

        var self=this;
        if (results && features && features.length) {
            var newFeatures=[];
            features.forEach(function(feature){
                var displayStr=displayPattern;
                var props=feature.getProperties();
                for (var p in props) {
                    var val=props[p];
                    displayStr=displayStr.replace('${'+p+'}',val);
                }
                var ext=feature.getGeometry().getExtent();
                var p=getCenter(ext);
                var findedStr=props[self.esriOptions.queryFields[0]];
                newFeatures.push({
                    lat:p[1],
                    lon:p[0],
                    srsName: srs,
                    bbox: ext,
                    address: {
                        name: displayStr,
                        findedStr:findedStr

                    }
                });

            });
            return newFeatures;
        } else {
            return;
        }
    }


}