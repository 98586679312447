// @ts-nocheck

import ImageArcGISRest from 'ol/source/ImageArcGISRest.js';
import {Vector as VectorLayer} from "ol/layer";
import {Circle as CircleStyle, Fill, Stroke, Style} from "ol/style";
import {Vector as VectorSource} from "ol/source";
import {LayerUtils} from "./LayerUtils";
import { Snap } from 'ol/interaction';
import Collection from 'ol/Collection.js';
import {Control} from "ol/control";
import Event from 'ol/events/Event';


export class SnapControl extends Control{
    constructor(map, options) {
        super({
            element: null,
            target: options.target
        });
        this.map = map;
        this.options = options;
        this.layersHnds=[];

        this.init();
        const self=this;
        document.onkeydown= function(event) {
            if (event.key == "s") {
                window.ctrlPressed = true;
                self.activate();
            }
        };
        document.onkeyup= function(event) {
            if (event.key == "s") {
                window.ctrlPressed = false;
                self.deactivate();
            }
        };

    }

    init() {
        this.layerUtils=new LayerUtils(this.map,{});
        this.setLayersEvt();
        this.snap = new Snap({
            pixelTolerance: this.options.pixelTolerance,
            features: new Collection([])
        });
    }
    activate() {
        if (!window.ctrlPressed) {
            return
        }

        this.refresh();
    }

    refresh() {
        const self=this;
        if (self.active) {
            self.map.removeInteraction(self.snap);
        }
        self.snap=self.getSnapControl();
        self.map.addInteraction(self.snap);

        self.active=true;
    }
    deactivate() {
        const self=this;
        if (self.active) {
            self.map.removeInteraction(self.snap);
        }
        self.snap=null;
        self.active=false;
    }
    setFeatures() {
        const features=new Collection (this.getFeatures());
        this.snap.features_=features;
    }
    getSnapControl() {
        const features=new Collection (this.getFeatures());
        this.snap = new Snap({
                pixelTolerance: this.options.pixelTolerance,
                features: features
            });

        return this.snap;
    }
    getFeatures() {
        let lyrs=this.layerUtils.getVectoreLayerByIds( this.options.layers);
        const self=this;
        let features=[];
        lyrs.forEach(lyr=> {
            //minResolution   + maxResolution

            const res = self.map.getView().getResolution();
            const minR = lyr.getProperties().minResolution;
            const maxR = lyr.getProperties().maxResolution;


            if(lyr.getProperties().visible && (res < maxR && res > minR)) {
                features = features.concat(lyr.getSource().getFeatures());
            }
        });
        return features;
    }
    setLayersEvt() {
        //console.log(JSON.stringify(this.options.layers));
        let lyrs=this.layerUtils.getVectoreLayerByIds( this.options.layers);
        const self=this;
        lyrs.forEach(lyr=> {

            self.layersHnds.push( lyr.getSource().on('featuresloadend', function(){
                     self.refresh();
                    //self.dispatchEvent(new Event("changed"));
            })
            );
            self.layersHnds.push( lyr.on('change:visible', function(evt){
                   self.refresh();
                   // self.dispatchEvent(new Event("changed"));

                })
            );
        });

        if( self.options.layersMapping) {
            for (const parentLyrId  in self.options.layersMapping) {
                const childLyrId=self.options.layersMapping[parentLyrId];
                const parentLyr = self.layerUtils.getLayerById(parentLyrId);
                const childLyr = self.layerUtils.getLayerById(childLyrId);

                self.layersHnds.push(parentLyr.getSource().on('change', function (evt) {

                    const src = evt.target;
                    let layerDefs;
                    if (src && src instanceof ImageArcGISRest) {
                        var params = src.getParams();
                        if (params.hasOwnProperty("layerDefs")) {
                            layerDefs = params.layerDefs;
                        }
                        if(childLyr) {
                            if (layerDefs) {
                                const filter = JSON.parse(layerDefs);
                                const subLayerId = childLyr.getProperties().baseUrl ? childLyr.getProperties().baseUrl.split("/").pop() : -1;
                                const childSrc = childLyr.getSource();
                                if (childSrc) {
                                    const where = filter[subLayerId];
                                    childSrc.where = encodeURIComponent(where);
                                    childSrc.refresh();
                                }
                            }
                            else {
                                const childSrc = childLyr.getSource();
                                if (childSrc) {
                                    childSrc.where = "";
                                    childSrc.refresh();
                                }

                            }
                        }
                    }
                }));

                self.layersHnds.push(parentLyr.on('change:visible', function (evt) {

                       if(childLyr) {

                           childLyr.setOpacity(0);
                           childLyr.setVisible(!evt.oldValue);
                       }
                })
                );
            }


        }
    }
}